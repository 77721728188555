import colors from "APP/colors";
import CommonStyles from "APP/common";
import { dimension, fontSize } from "APP/theme";
import { color } from "d3";
import styled from "styled-components";

const StyledSummaryCard = styled(CommonStyles)`
  width: 100%;
  display: flex;
  justify-content: center;

  .summary-text {
    color: ${colors.black[500]};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 33.6px */
  }

  .summary-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: ${colors.primary[50]};
    min-height: 150px;
    border-radius: 4px;
    width: 270px;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }

  .stat-ctn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 24px 16px 24px 16px;
  }

  .value {
    color: ${colors.black[500]};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 33.6px */
  }

  .report-cards-ctn {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .percentage-change {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: ${colors.white[50]};
    border-radius: 3px;
  }

  .green {
    background: ${colors.green[500]};
  }

  .red {
    background: ${colors.danger[500]};
  }

  @media (max-width: ${dimension.desktopSmall.width}) {
  }

  @media (max-width: ${dimension.mobile.width}) {
  }
`;

export default StyledSummaryCard;
