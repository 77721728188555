import styled from "styled-components";
import CommonStyles from "../../../../../APP/common";
import colors from "../../../../../APP/colors";

const StyledCampaign = styled(CommonStyles)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .audience-ctn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 24px;
        align-items: center;
        background: ${colors.primary[50] || '#EAF1FF'};
        border-radius: 5px;
    }
    
    .audience-ctn-item {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }
    
    .f-18 {
        font-size: 18px;
    }
    
    .w-700 {
        font-weight: 700;
    }

    .campaign-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 24px;
        align-items: center;
        border-bottom: 1px solid ${colors.black[50] || '#E8E8E8'};
        border-radius: 5px;
    }
    
    .campaign-header {
        border-radius: 8px 8px 0 0;
    }
    
    .btn-ctn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export default StyledCampaign;
