import React, { useState } from "react";
import StyledHomeReports from "../styled";
import {
  TAdGroupProp,
  TAdNetworkTypeProp,
  TGeoInsightsProp,
} from "interface/types";
import ReportSummaryCard from "../shared/ReportSummaryCard";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import colors from "APP/colors";
import ReportTable from "../shared/ReportTable";

const MetaAdReports: React.FunctionComponent = () => {
  const adGroupColumns: (keyof TAdGroupProp)[] = [
    "Campaign Name",
    "Ad Group Name",
    "Ad Name",
    "Keyword",
    "Placement",
    "Impressions",
    "CTR",
  ];

  const geoInsightsColumns: (keyof TGeoInsightsProp)[] = [
    "Country/City",
    "Impressions",
    "CTR",
    "Conversions",
  ];

  const adNetworkColumns: (keyof TAdNetworkTypeProp)[] = [
    "Ad Network Type",
    "Impressions",
    "Clicks",
    "CTR",
    "Conversions",
  ];

  const [adNetworkData, setAdNetworkData] = useState<TAdNetworkTypeProp[]>([
    {
      "Ad Network Type": "Google Ads",
      Impressions: 1500,
      Clicks: 120,
      CTR: 8,
      Conversions: 10,
    },
    {
      "Ad Network Type": "Meta Ads",
      Impressions: 2000,
      Clicks: 180,
      CTR: 9,
      Conversions: 15,
    },
  ]);

  const [geoInsightsData, setGeoInsightsData] = useState<TGeoInsightsProp[]>([
    { "Country/City": "USA", Impressions: 1000, CTR: 5, Conversions: 20 },
    { "Country/City": "Canada", Impressions: 1200, CTR: 6, Conversions: 29 },
  ]);
  const [adGroupData, setAdGroupData] = useState<TAdGroupProp[]>([
    {
      "Campaign Name": "Campaign 1",
      "Ad Group Name": "Group 1",
      "Ad Name": "Ad A",
      Keyword: "Keyword 1",
      Placement: "Top",
      Impressions: 1000,
      CTR: 5,
    },
    {
      "Campaign Name": "Campaign 2",
      "Ad Group Name": "Group 2",
      "Ad Name": "Ad B",
      Keyword: "Keyword 2",
      Placement: "Sidebar",
      Impressions: 2000,
      CTR: 7,
    },
  ]);

  return (
    <StyledHomeReports>
      <div className="report-cards-ctn mt-40">
        <ReportSummaryCard
          title="Impressions"
          type="count"
          value={93708}
          percentageChange={12.18}
          progressPercent={35}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Clicks"
          type="count"
          value={93708}
          percentageChange={-9.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Click-through Rate (CTR)"
          type="percent"
          value={15.15}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Cost per Click (CPC)"
          type="cost"
          value={30.9}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Conversions"
          type="count"
          value={93708}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Conversion Rate (CVR)"
          type="percent"
          value={31.2}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="Cost per Conversion"
          type="cost"
          value={93}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="All Conversions"
          type="count"
          value={93708}
          percentageChange={12.18}
        ></ReportSummaryCard>

        <ReportSummaryCard
          title="All Conversions Value"
          type="cost"
          value={23}
          percentageChange={12.18}
        ></ReportSummaryCard>
      </div>
      <div className="hor-line"></div>

      <div className="insight-ctn">
        <div className="filter-ctn">
          <div>Campaigns</div>
          <div>
            <select className="filter-input">
              <option selected>Big C</option>
            </select>
          </div>
        </div>

        <div className="report-chart"></div>

        <div className="chart-options-ctn"></div>
      </div>
      <div className="hor-line"></div>
      <ReportTable
        columns={adGroupColumns}
        data={adGroupData}
        title="Ad Group Name"
      />
      <div className="hor-line"></div>
      <ReportTable
        columns={geoInsightsColumns}
        data={geoInsightsData}
        title="Geo Insights"
      />
      <div className="hor-line"></div>
      <div className="demographics-ctn">
        <div className="summary-text">Demographics</div>
        <div className="demographic-main-ctn mt-40">
          <div className="device-type">
            <div>Device Type</div>
            <div>
              <select>
                <option>This Month</option>
              </select>
            </div>
          </div>
          <div className="demographic-circle-ctn">
            <CircularProgressbar
              className="demographic-circle"
              value={80}
              styles={buildStyles({
                pathColor: colors.green[400],
                trailColor: colors.primary[700],
                strokeLinecap: "butt",
              })}
            />

            <div className="demographic-legend-ctn">
              <div>Mobile</div> <div>Desktop</div>
            </div>
          </div>
        </div>
      </div>
      <div className="hor-line"></div>
      <ReportTable
        columns={adNetworkColumns}
        data={adNetworkData}
        title="Ad Network Type"
      />
    </StyledHomeReports>
  );
};

export default MetaAdReports;
