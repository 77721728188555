import styled from "styled-components";

const StyledPrivacy = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 20px;
    
    .header {
    display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: #000;
    }
    
    .privacy-text {
        font-size: 30px;
        color: #FFF;
        font-weight: bold;
        font-famile: Inter;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: #000;
        gap: 10px;
        color: #FFF;
        font-family: Inter;
    }
    
    .action-ctn {
        display: flex;
        gap: 20px;
    }
    
    .action {
        font-size: 16px;
    }
    
    .action:hover {
        cursor: pointer;
        color: #4485F4;
    }
    .text {
        font-size: 16px;
        font-family: Inter;
        line-height: 24px;
        color: #000;
    }
    
    .text-bold {
        font-size: 16px;
        font-weight: bold;
        font-family: Inter;
        line-height: 30px;
    }
    
    .text-bold-24 {
        font-size: 16px;
        font-weight: bold;
        font-family: Inter;
        line-height: 24px;
    }
    
    .l-30 {
        line-height: 30px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 10px;
        margin-block-end: 1em;
        padding-inline-start: 40px;
    }
    
    `;

export default StyledPrivacy;