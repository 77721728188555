import React from "react";

import StyledEmailVerification from "./styled";
import messageIcon from 'assets/auth/Message_alt_fill-2.png';
import leftArrow from "assets/auth/Arrow_left.png";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import useEmailVerification from "hooks/useEmailVerification";
import {signup} from "services/selector";

const EmailVerification = () => {
    const navigate = useNavigate();
    const data = useSelector(signup);

    const {getVerificationLink, loading, error } = useEmailVerification();

    return (
        <StyledEmailVerification>
            <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <button className="header-login-btn" onClick={() => navigate("/auth/login")}>
                        Login
                    </button>
                </div>
            </div>
            <div className="ctn ctn-override">
                <div className="inner-ctn">
                    <div className="msg-ctn">
                        <img src={messageIcon} alt='' className="msg"/>
                    </div>
                    <div className="heading m-top">Email Verification</div>
                    <div className="txt m-top">We have sent an email to “{data.verify_email}” for verification.</div>
                    <div className="login-btn m-top" onClick={loading ? ()  => {}: getVerificationLink}>
                        {loading ? "Sending...": "Didn’t receive email yet?"}
                    </div>
                    <div className="back m-top">
                        <img src={leftArrow} alt='' className="google-icon"/><span className="forget"
                                                                                   onClick={() => navigate("/auth/login")}>Back to Login</span>
                    </div>
                </div>
            </div>
        </StyledEmailVerification>
    );
}

export default EmailVerification;