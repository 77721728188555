import styled from "styled-components";

const StyledCreateEstimate = styled.div`
  .create-container {
    display: grid;
    // grid-template-columns: 1fr 3fr;
  }
`;

export default StyledCreateEstimate;
