import React from "react";
import StyledCampaign from "./styled";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";

import {audience} from "services/selector";
import useCampaign from "../../../../../hooks/useCampaign";
import Button from "../../../../../shared/components/Button";
import colors from "../../../../../APP/colors";

const Campaign = () => {
  const {
    campaigns,
    curr_audience_id: audience_id,
    aud_name: audienceName,
    selected_google_ad_acc: selectedGoogleAdAcc,
    selected_meta_ad_acc: selectedMetaAdAcc,
    selected_x_ad_acc: selectedXAdAcc,
    selected_tiktok_ad_acc: selectedTiktokAdAcc,
  } = useSelector(audience);
  const {selectAllCampaigns, toggleCampaignSelection, handleNext} = useCampaign();
  const navigate = useNavigate();

  return (
      <StyledCampaign>
        <div className="heading">Campaign Selection</div>
        <div className="label-16">Connect and choose platform for your created audience.</div>
        <div className="audience-ctn">
          <div className="audience-ctn-item">
            <span className="label-16 f-18 w-700">Created Audience</span>
            <span className="label-16 f-18">{audienceName}</span>
          </div>

          <div className="audience-ctn-item">
            <span className="label-16 f-18 w-700">Select Campaigns</span>
            <span className="label-16 f-18">{
              campaigns.filter(
                  (i) =>
                      i.checked &&
                      (selectedGoogleAdAcc[i.acc_id] ||
                          selectedMetaAdAcc[i.acc_id] ||
                          selectedXAdAcc[i.acc_id] ||
                          selectedTiktokAdAcc[i.acc_id]
                      )
              ).length
            }</span>
          </div>
        </div>

        <div>
          <div className="audience-ctn campaign-header">
            <div className="audience-ctn-item">
              <input type="checkbox" className="icon-img" onClick={() => {
                selectAllCampaigns();
              }}/>
              <span className="label-16 f-18 w-700">Name</span>
            </div>
            <div className="audience-ctn-item">
              <span className="label-16 f-18 w-700">Platform</span>
            </div>
          </div>

          {campaigns
                .filter(
                    (i) =>
                        selectedGoogleAdAcc[i.acc_id] ||
                        selectedMetaAdAcc[i.acc_id] ||
                        selectedXAdAcc[i.acc_id] ||
                        selectedTiktokAdAcc[i.acc_id]
                )
                .map((item, index) => (
                    <div
                        className="campaign-row"
                        key={index}
                    >
                      <div className="audience-ctn-item">
                        <input
                            type="checkbox"
                            onClick={() => {
                              toggleCampaignSelection(item.id);
                            }}
                            checked={item.checked}
                            className="icon-img"
                        />
                        <div className="label-16">{item.name}</div>
                      </div>
                      <div className="label-16">{item.platforms}</div>
                    </div>
                ))
          }
        </div>

        <div className="btn-ctn">
          <Button
              text={"Previous Step"}
              action={() => {
                navigate("/dashboard/audience/create/platforms");
              }}
              background={`${colors.white[50] || "FFF"}`} color={colors.primary[600] || "#256CE8"}
              border={`1px solid ${colors.primary[600] || "#256CE8"}`}
          />
          <Button
              text={"Next Step"}
              action={handleNext}
          />
        </div>
      </StyledCampaign>
  );
};

export default Campaign;
