import styled from "styled-components";
import colors from "APP/colors";

interface IFirstLogin {
  moveUp?: boolean;
}

const FirstLoginStyled = styled.div<IFirstLogin>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: "Roboto", sans-serif;
  padding-bottom: 1rem;
  background: ${(props) => (props.moveUp ? "#fff;" : "#2977ff;")};
  transition: background 5s ease-in-out; /* Animation for background color */

  .fl-ctn {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .aud-name-input {
    color: #000;
    height: 45px;
    padding: 0px 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: none;
    outline: none;
    width: 400px;
    border: solid #dcdcdc 1px;
    border-radius: 5px;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .main-intro {
    transition: all 10s ease-in-out;
  }

  .m1 {
    margin-top: 15%;
  }

  .inner-ctn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .welcome {
    font-size: 48px;
    color: ${colors.primary[500]};
    font-weight: 600;
  }

  .second-desc {
    font-size: 20px;
  }

  .third-desc {
    font-size: 16px;
    color: ${colors.primary[500]};
  }

  .move-up {
    margin-top: 0;
  }

  .white-font {
    color: white;
  }

  .bg-white {
  }

  .create-methods-ctn {
    display: flex;
    height: 60%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .method-icon-ctn {
    display: flex;
    justify-content: center;
  }

  .method-ctn {
    background-color: ${colors.primary[50]};
    height: 450px;
    max-width: 367px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
  }

  .method-title {
    font-size: 48px;
    text-align: center;
  }

  .method-desc {
    padding: 1rem;
  }

  .method-icon {
    width: 128px;
  }
`;

export default FirstLoginStyled;
