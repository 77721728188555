import React, { useEffect, useState } from "react";
import StyledPlatform from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set } from "services/reducer/audience";
import {TGoogleAdsCred, TMetaAdsCred, TTiktokAdsCred, TXAdsCred} from "interface/types";
import {audience, home, platform} from "services/selector";
import usePlatform from "../../../../../hooks/usePlatform";
import {IHeading} from "interface/components";

import googleIcon from "assets/platform/google.png";
import tiktokIcon from "assets/platform/tik-tok.png";
import xIcon from "assets/platform/x.png";
import metaIcon from "assets/platform/meta.png";
import Button from "../../../../../shared/components/Button";
import colors from "../../../../../APP/colors";

const Platform: React.FunctionComponent<IHeading> = ({setHeading}) => {
  const {} = usePlatform('/dashboard/audience/create/platforms');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    metaAuthUrl,
    googleAuthUrl,
    xAuthUrl,
    tiktokAuthUrl,
    selected_google_ad_acc: selectedGoogleAdAcc,
    selected_meta_ad_acc: selectedMetaAdAcc,
    selected_x_ad_acc: selectedXAdAcc,
    selected_tiktok_ad_acc: selectedTiktokAdAcc,
  } = useSelector(audience);

  const {country} = useSelector(home);
  const {google, meta, x, tiktok} = useSelector(platform);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(set({ activeTab: 0 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Platform"]);
  }, []);

  const handleGoogleAdAccSel = (e: any) => {
    let temp = { ...selectedGoogleAdAcc };
    for (const key in temp) {
      temp[key] = false;
    }
    const val = parseInt(e.target.value);
    if (val)
      temp[val] = true;
    dispatch(set({ selected_google_ad_acc: temp }));
  };

  const handleMetaAdAccSel = (e: any) => {
    let temp = { ...selectedMetaAdAcc };
    for (const key in temp) {
      temp[key] = false;
    }
    const val = e.target.value;
    if (val)
      temp[val] = true;
    dispatch(set({ selected_meta_ad_acc: temp }));
  };

  const handleXAdAccSel = (e: any) => {
    let temp = { ...selectedXAdAcc };
    for (const key in temp) {
      temp[key] = false;
    }

    const val = e.target.value;
    if (val)
      temp[val] = true;
    dispatch(set({ selected_x_ad_acc: temp }));
  };

  const handleTiktokAdAccSel = (e: any) => {
    let temp = { ...selectedTiktokAdAcc };
    for (const key in temp) {
      temp[key] = false;
    }
    const val = e.target.value;
    if (val)
      temp[val] = true;
    dispatch(set({ selected_tiktok_ad_acc: temp }));
  };

  const handleConnect = (url: string) => {
      const temp = localStorage.getItem("new_audience") || "{}";
      const obj = JSON.parse(temp);

      const new_obj = { ...obj, active_tab: 2 };
      localStorage.setItem(
          "new_audience",
          JSON.stringify(new_obj)
      );
    window.location.href = url;
  }


  return (
    <StyledPlatform>
        <div className="heading">Select Social Media</div>
        <div className="label-16 mt-16">
          Select the accounts you wanna start campaigns to.
        </div>

      <div className="container">
        <div className="row">
          <input type="checkbox" className="checkbox"/>
          <img src={googleIcon} alt="Google" className="img"/>
          <div className="label-16 platform-name">Google Ads*</div>
          <div className="d-flex">
            <Button text={"+ Connect another account"} action={() => handleConnect(googleAuthUrl)}
                    background={`${colors.white[50] || "FFF"}`} color={colors.primary[600] || "#256CE8"}
                    border={`1px solid ${colors.primary[600] || "#256CE8"}`}/>
          </div>
          <div className="d-flex">
            <select className="select" onChange={handleGoogleAdAccSel}>
              <option value = ''>Select account</option>
              {
                google.map((val: TGoogleAdsCred, ind: number) => (
                    <option key={ind} value={val.id}>
                      {val.name}
                    </option>
                ))
              }
            </select>
          </div>
        </div>

        <div className="row">
          <input type="checkbox" className="checkbox"/>
          <img src={tiktokIcon} alt="TikTok" className="img"/>
          <div className="label-16 platform-name">TikTok Ads</div>
          <div className="d-flex">
            <Button text={"+ Connect another account"} action={() => handleConnect(tiktokAuthUrl)}
                    background={`${colors.white[50] || "FFF"}`} color={colors.primary[600] || "#256CE8"}
                    border={`1px solid ${colors.primary[600] || "#256CE8"}`}/>
          </div>
          <div className="d-flex">
            <select className="select" onChange={handleTiktokAdAccSel}>
              <option value="">Select account</option>
              {
                tiktok.map((val: TTiktokAdsCred, ind: number) => (
                    <option key={ind} value={val.id}>
                      {val.name}
                    </option>
                ))
              }
            </select>
          </div>
        </div>

        <div className="row">
          <input type="checkbox" className="checkbox"/>
          <img src={metaIcon} alt="Meta" className="img"/>
          <div className="label-16 platform-name">Meta Ads</div>
          <div className="d-flex">
            <Button text={"+ Connect another account"} action={() => handleConnect(metaAuthUrl)}
                    background={`${colors.white[50] || "FFF"}`} color={colors.primary[600] || "#256CE8"}
                    border={`1px solid ${colors.primary[600] || "#256CE8"}`}/>
          </div>
          <div className="d-flex">
            <select className="select" onChange={handleMetaAdAccSel}>
              <option value="">Select account</option>
              {
                meta.map((val: TMetaAdsCred, ind: number) => (
                    <option key={ind} value={val.id}>
                      {val.name}
                    </option>
                ))
              }
            </select>
          </div>
        </div>

        <div className="row">
          <input type="checkbox" className="checkbox"/>
          <img src={xIcon} alt="X" className="img x"/>
          <div className="label-16 platform-name">X(Previously Twitter) Ads</div>
          <div className="d-flex">
            <Button text={"+ Connect another account"} action={() => handleConnect(xAuthUrl)}
                    background={`${colors.white[50] || "FFF"}`} color={colors.primary[600] || "#256CE8"}
                    border={`1px solid ${colors.primary[600] || "#256CE8"}`}/>
          </div>
          <div className="d-flex">
            <select className="select" onChange={handleXAdAccSel}>
              <option value="">Select account</option>
              {
                x.map((val: TXAdsCred, ind: number) => (
                    <option key={ind} value={val.id}>
                      {val.name}
                    </option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
      {error && <div className="error-msg">{error}</div>}

      <div className="label-16">
        *When you provide consent through connecting your google account our platform will use it to obtain list of campaigns and then upload a user-list to your google ads platform and then attach it to the campaign of your choice.
      </div>
      <div className="label-16 mt-16">
        *If you don’t have a Google Ads account, request you to create one and then return here to connect it.
      </div>

      <div className="btn-ctn">
        <Button text={"Next Step"} action={() => {
          if (
              google.filter(i => selectedGoogleAdAcc[i.id]).length +
              meta.filter(i => selectedMetaAdAcc[i.id]).length +
              x.filter(i => selectedXAdAcc[i.id]).length +
              tiktok.filter(i => selectedTiktokAdAcc[i.id]).length
              === 0) {
            setError("You must connect atleast one Ad account");
            return;
          }
          navigate("/dashboard/audience/create/campaign");
        }} />
      </div>
    </StyledPlatform>
  );
};

export default Platform;
