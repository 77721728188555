import styled from "styled-components";
import colors from "../../../APP/colors";
import theme, {dimension, fontSize} from "../../../APP/theme";
import {IStyledDropDownProps} from "interface/styled";

const StyledDropDown = styled.div<IStyledDropDownProps>`
    width: ${(props) => props.width || '463px'};

    @media (max-width: ${dimension.mobile.width}) {
        width: ${(props) => props.width || '100%'};
    }
    
    .dropdown-container {
        position: relative;
        width: 100%;
    }

    .dropdown-header {
        height: 28px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        border-radius: 4px;
        border: 1px solid ${colors.black[300]};
        background: ${colors.white[50]};

        overflow: hidden;
        color: ${colors.black[700]};
        text-overflow: ellipsis;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    .dropdown-arrow {
        margin-left: 10px;
        height: 24px;
        width: 24px;
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border: 1px solid ${colors.white[600]};
        border-radius: 4px;
        background: ${colors.white[50]};
        margin-top: 1px;
        list-style: none;
        padding: 0;
        max-height: 150px;
        overflow-y: auto;
        z-index: 100;
        box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
    }

    .dropdown-list li {
        padding: 8px 16px;
        cursor: pointer;
        color: #000;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    .dropdown-list li:hover {
        background: ${colors.primary[50]};
    }
`;

export default StyledDropDown;