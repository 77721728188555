import axios from "axios";
import { toastPopup } from "./util";

export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_BACKEND_BASE_URL =
  process.env.REACT_APP_BACKEND_BASE_URL;

const REACT_APP_GOOGLE_SIGNUP_SCOPE = process.env.REACT_APP_GOOGLE_SIGNUP_SCOPE;
const REACT_APP_GOOGLE_SIGNUP_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_SIGNUP_CLIENT_ID;
const REACT_APP_GOOGLE_REDIRECT_SIGNUP_URI =
  process.env.REACT_APP_GOOGLE_REDIRECT_SIGNUP_URI;
const REACT_APP_GOOGLE_REDIRECT_LOGIN_URI =
  process.env.REACT_APP_GOOGLE_REDIRECT_LOGIN_URI;
const REACT_APP_GOOGLE_REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
const REACT_APP_META_REDIRECT_URL = process.env.REACT_APP_META_REDIRECT_URL;
const REACT_APP_META_STATE = process.env.REACT_APP_META_STATE;
export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;
export const REACT_APP_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

export const vaticAxiosGet = async (endPoint: string, params: any = {}) => {
  const jwtToken = localStorage.getItem("access_token") || "";
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
  };

  try {
    const res = await axios.get(REACT_APP_BACKEND_BASE_URL + endPoint, {
      params: params,
      headers: headers,
    });
    return res.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const vaticAxiosPost = async (endPoint: string, data: any) => {
  const jwtToken = localStorage.getItem("access_token") || "";
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
  };

  try {
    const res = await axios.post(REACT_APP_BACKEND_BASE_URL + endPoint, data, {
      headers: headers,
    });
    return res.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const axiosGet = async (url: string, params: any = {}) => {
  const jwtToken = localStorage.getItem("access_token") || "";
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
  };

  try {
    const res = await axios.get(url, {
      params: params,
      headers: headers,
    });
    return res.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const axiosPost = async (url: string, data: any) => {
  const jwtToken = localStorage.getItem("access_token") || "";
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
    "Content-Type": "application/json",
  };

  try {
    const res = await axios.post(url, data, { headers: headers });
    return res.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const googleSignin = () => {
  const redirectUrl =
    (REACT_APP_BASE_URL || "") + (REACT_APP_GOOGLE_REDIRECT_LOGIN_URI || "");
  const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${REACT_APP_GOOGLE_SIGNUP_SCOPE}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${REACT_APP_GOOGLE_SIGNUP_CLIENT_ID}`;
  window.open(url, "_self");
};

export const googleSignup = () => {
  const redirectUrl =
    (REACT_APP_BASE_URL || "") + (REACT_APP_GOOGLE_REDIRECT_SIGNUP_URI || "");
  const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${REACT_APP_GOOGLE_SIGNUP_SCOPE}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${REACT_APP_GOOGLE_SIGNUP_CLIENT_ID}`;
  window.open(url, "_self");
};

export const getGoogleUserInfo = async (access_token: string) => {
  const url = `https://www.googleapis.com/oauth2/v3/userinfo`;
  const params = {
    access_token: access_token,
  };
  const data = await axiosGet(url, params);
  return data;
};

interface predefinedInterface {
  id: number;
  is_custom: number;
  is_intent: number;
  name: string;
  total_maid: number;
}

interface KeywordsInterface {
  related_candi_keyword: string;
  related_score: number;
  isSelected: boolean;
  included_candi_keyword: string;
  included_score: number;
}

export const getMetaAuthUrl = async (redirectEndPoint: string) => {
  const reactAppBaseUrl = process.env.REACT_APP_BASE_URL || "";
  const redirectUrl = reactAppBaseUrl + redirectEndPoint;
  // const url = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${REACT_APP_META_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${REACT_APP_META_SCOPE}&state=${REACT_APP_META_STATE}`
  const res = await vaticAxiosGet("/api/v1/ads/meta/auth-url", {
    redirect_url: redirectUrl,
    state: REACT_APP_META_STATE,
  });
  return res.data;
};

export const getGoogleAuthUrl = async (redirectEndPoint: string) => {
  const reactAppBaseUrl = process.env.REACT_APP_BASE_URL || "";
  const redirectUrl = reactAppBaseUrl + redirectEndPoint;
  const res = await vaticAxiosGet(
    "/api/v1/ads/google/auth-url?redirect_url=" + redirectUrl
  );
  return res.data;
};

export const getMetaAccessToken = async (
  code: string,
  db: string,
  redirect_uri: string
) => {
  const redirectUrl = (REACT_APP_BASE_URL || "") + redirect_uri;
  const response = await vaticAxiosGet("/api/v1/ads/meta/access-token", {
    redirect_url: redirectUrl,
    code: code,
    db: db,
  });
  if (response && response.statusCode == '200') {
      toastPopup.success("Meta Ad Account Added!");
  }
  if (response.statusCode === 200 && response.access_token) {
    return response.access_token;
  }
};

export const getXAuthUrl = async (redirectEndPoint: string) => {
  const reactAppBaseUrl = process.env.REACT_APP_BASE_URL || "";
  const redirectUrl = reactAppBaseUrl + redirectEndPoint;
  const res = await vaticAxiosGet(
    "/api/v1/ads/x/auth-url?redirect_url=" + redirectUrl
  );
  return res.data;
};

export const getTikTokAuthUrl = async (redirectEndPoint: string) => {
  const reactAppBaseUrl = process.env.REACT_APP_BASE_URL || "";
  const redirectUrl = reactAppBaseUrl + redirectEndPoint;
  const res = await vaticAxiosGet(
    "/api/v1/ads/tiktok/auth-url?redirect_url=" + redirectUrl
  );
  return res.data;
};
