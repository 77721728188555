import colors from "APP/colors";
import StyledAudienceDetail from "components/Home/Audience/AudienceDetail/styled";
import styled from "styled-components";

const StyledEstimateDetails = styled(StyledAudienceDetail)`
  .head {
    margin: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
    
    .heading-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

  .predicted-size {
    padding: 16px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .plus {
    width: 12px;
    height: 12px;
  }

  .plus-text {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .drop-down-ctn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    display: inline-flex;
    padding: 7.5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .drop-down-opt {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .drop-down-arrow {
    width: 16px;
    height: 16px;
  }

  .hor-line {
    height: 1px;
    background: #e3e5e9;
  }

  .t-container {
    height: 100%;
  }

  .et-head {
    height: 64px;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1.5fr;
    background-color: rgba(68, 133, 244, 0.15);
    align-items: center;
  }

  .et-row-country {
    padding: 16px;
  }

  .t-head-font {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .t-body {
    height: 90%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .et-row {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1.5fr;
    height: 48px;
    align-items: center;
  }

  .et-row-action {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .et-row-action-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .et-row-edit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .et-row-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }

  .t-row-odd {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
    height: 64px;
    align-items: center;
  }

  .delete-img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .t-data {
    display: flex;
    gap: 5px;
  }

  .t-data1 {
    color: #3a76dc;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }

  .t-data2 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .t-data3 {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .extend {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .t-body-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
  }

  .empty-img {
    width: 140px;
    height: 140px;
  }

  .empty-h1 {
    margin-top: 16px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .empty-text {
    margin-top: 8px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    width: 254px;
  }

  .aud-type {
    display: flex;
    gap: 20px;
  }

  .aud-type-item1 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    border-bottom: 3px solid #4485f4;
    padding-bottom: 10px;
  }

  .aud-type-item2 {
    color: #82868f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
  }

  .loaded-summary-box {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .f-spbt {
    display: flex;
  }

  .div-w {
    width: 100%;
  }

  .help {
    fill: #b5b6b9;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .speedo-meter-p {
    display: flex;
    gap: 10px;
  }

  .speedo-meter-ctn {
    position: relative;
    height: 77px;
    width: 77px;
  }

  .speedo-meter {
    position: absolute;
  }

  .needle {
    position: absolute;
  }

  .hor-line-neg {
    margin-top: -30px;
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .keyword-25 {
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .t4 {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .t5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* modalStyles.css */

  /* Overlay styles */
  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  }

  /* Content styles */
  .custom-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* White background */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    width: 70%; /* Set the width of the modal */
    max-width: 800px; /* Maximum width of the modal */
    height: 300px; /* Maximum height of the modal */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds modal height */
  }

  /* Close button styles */
  .close-button {
    min-width: 100px;
    border-radius: 8px;
    font-size: 15px;
    border: 1px solid rgba(220, 227, 227, 0);
    background: #dcdcdc;
    display: inline-flex;
    padding: 2.5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #000;
  }

  .close-button:hover {
    opacity: 0.8;
  }

  .campaign-row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .campaign-name {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .campaign-platform {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .campaign-head {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .platform-icon {
    width: 30px;
    height: 30px;
  }

  .progress-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }

  .progress-text {
    color: #000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .progress-text-error {
    color: red;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .progress-bar-ctn {
    position: relative;
    width: 80px;
    height: 4px;
  }

  .progress-base {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #4485f4;
    border-radius: 64px;
  }

  .progress {
    position: absolute;
    height: 100%;
    opacity: 1;
    background: #4485f4;
    border-radius: 64px;
  }

  .progress-error {
    position: absolute;
    height: 100%;
    opacity: 1;
    background: red;
    border-radius: 64px;
    width: 100%;
  }

  .campaign-table-body {
    height: 90%;
    overflow-y: auto;
  }

  .campaign-table-row-even {
    padding: 0px 13px;
    min-height: 56px;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  .little-dark-row {
    background-color: #f7f7f7;
  }

  .campaign-checkbox {
    display: flex;
  }

  .campaign-name {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .campaign-client {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .campaign-platform {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .d-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }

  .campaign-table-h {
    margin: 8px 13px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .campaign-table-title {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    color: #5c5e62;
    text-align: left;
    display: inline-block;
  }

  .t6 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .audience-name {
    width: 300px;
    padding: 5px;
    margin: 5px 10px;
    border: 1px solid #e3e5e9;
    outline: none;
    box-shadow: none;
    font-size: 14px;
    border-radius: 5px;
  }

  .create-audience-btn-ctn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .create-btn-ctn:hover {
    opacity: 0.8;
  }

  .progress-bar-deatail-ctn {
    width: 200px;
  }

  .progress-bar-detail {
    width: 200px;
  }

  .notify-msg-ctn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 6px;
    width: 200px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  }

  .notify-red-line {
    height: 4px;
    background-color: red;
    width: 100%;
  }

  .calculating-ctn {
    background-color: ${colors.warning[100]};
    color: ${colors.warning[900]};
    height: 44px;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
  }

  .ready-ctn {
    background-color: ${colors.success[100]};
    color: ${colors.success[700]};
    height: 44px;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
  }

  @media (max-width: 720px) {
    .et-head {
      display: none;
    }
  }
`;

export default StyledEstimateDetails;
