import validator from "validator";

export const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
}

export const calculatePasswordStrength = (password: string) => {
    let strength = 0;

    if (password.length > 5) strength += 1;  // Basic length requirement
    if (password.length > 8) strength += 1;  // Good length
    if (/[A-Z]/.test(password)) strength += 1;  // Contains uppercase letter
    if (/[0-9]/.test(password)) strength += 1;  // Contains a digit
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;  // Contains special character

    return strength;
};

export const validatePassword = (value: string) => {
    const minLength = validator.isLength(value, {min: 8});
    const hasNumbers = validator.matches(value, /\d/);
    const hasUppercase = validator.matches(value, /[A-Z]/);
    const hasSpecialChars = validator.matches(value, /[^A-Za-z0-9]/);

    return !(!minLength || !hasNumbers || !hasUppercase || !hasSpecialChars);
}
