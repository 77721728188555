import styled from "styled-components";
import colors from "../../APP/colors";
import CommonStyles from "../../APP/common";

const StyledSignalCategory = styled(CommonStyles)`
  padding-left: 10px;
  padding-bottom: 5px;
    display: grid;
    grid-template-columns: 1px 98fr;
    
    .vertical-line {
        width: 1px;
        height: auto;
        background: ${colors.primary[100] || "#BDD5FF"};
    }

    .small-horizontal-line {
        width: auto;
        height: 1px;
        background: ${colors.primary[100] || "#BDD5FF"};
        margin-top: 30px;
    }

    .smaller-horizontal-line {
        width: auto;
        height: 1px;
        background: ${colors.primary[100] || "#BDD5FF"};
        margin-top: 20px;
    }
    
    .child-ctn {
        display: grid;
        grid-template-columns: 2fr 98fr;
    }

  .category {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      background: ${colors.white[200] || "#FAFAFA"};
      cursor: pointer;
      margin: 8px 0 0 0;
  }
    
    .row {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
    }
    
    .category-active {
        background: ${colors.primary[400] || "#5492FF"};
    }
    
    .label-14-active {
        color: ${colors.white[50] || "#FFF"};
        font-weight: 700;
    }
    
    .horizontal-line {
        width: 100%;
        height: 1px;
        background: ${colors.black[200] || "#949494;"};
    }
    
    .segment {
        margin-top: 10px;
        margin-left: 8px;
        cursor: pointer;
    }
`;

export default StyledSignalCategory;
