import React from 'react';
import StyledToggleButton from "./styled";
import {IToggleButton} from "../../../interface/components";

const ToggleButton: React.FunctionComponent<IToggleButton> = ({defaultChecked, action}) => {

    return (
        <StyledToggleButton>
            <input type="checkbox" onChange={action} defaultChecked={defaultChecked} />
            <span className="slider"></span>
        </StyledToggleButton>
    );
}

export default ToggleButton;