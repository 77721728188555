import React from 'react';
import StyledHeader from "./styled";
import leftArror from "assets/dropdown/Expand_left.png";
import {IHeader} from "../../../interface/components";

const Header: React.FunctionComponent<IHeader> = ({text, action}) => {
    return (
        <StyledHeader>
            <div className="c1" onClick={action}>
                <img src={leftArror} alt='' className="arrow"/>
                <div className="text">{text}</div>
            </div>
        </StyledHeader>
    );
}

export default Header;