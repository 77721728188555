import React, { useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const DateRangePickerReport: React.FC<{ setDateRange: (value: any) => void , dateRange: any}> = ({ setDateRange, dateRange}) => {
  const handleChange = (value: [Date, Date] | null) => {
    if (value == null) return
    setDateRange(value);
  };

  return (
    <div>
      <div>Select Date Range:</div>
      <DateRangePicker
        value={dateRange}
        onChange={handleChange}
        placeholder="Select date range"
        style={{ width: 280 }}
        showOneCalendar
        format="yyyy-MM-dd"
      />
    </div>
  );
};

export default DateRangePickerReport;
