import {TRootState} from "./store";
import {
    TAdSetclick,
    TAudienceState,
    TCalibrateState, TCampaignClick,
    TEstimateState, THomeCampaign, THomeState,
    TLoginState,
    TPlatformState, TSignupState
} from "interface/types";

export const audience = (state: TRootState): TAudienceState => ({
    method: state.audience.method,
    activeTab:  state.audience.activeTab,
    googleAuthUrl: state.audience.googleAuthUrl,
    metaAuthUrl: state.audience.metaAuthUrl,
    campaigns: state.audience.campaigns,
    aud_name: state.audience.aud_name,
    custom_keywords: state.audience.custom_keywords,
    t_region: state.audience.t_region,
    awareness: state.audience.awareness,
    promoting: state.audience.promoting,
    t_audience: state.audience.t_audience,
    updating: state.audience.updating,
    curr_audience_id: state.audience.curr_audience_id,
    prompt_id: state.audience.prompt_id,
    keywords: state.audience.keywords,
    keywords_fetched: state.audience.keywords_fetched,
    language: state.audience.language,
    urls: state.audience.urls,
    platform_type: state.audience.platform_type,
    selected_google_ad_acc: state.audience.selected_google_ad_acc,
    selected_meta_ad_acc: state.audience.selected_meta_ad_acc,
    size: state.audience.size,
    xAuthUrl: state.audience.xAuthUrl,
    selected_x_ad_acc: state.audience.selected_x_ad_acc,
    tiktokAuthUrl: state.audience.tiktokAuthUrl,
    selected_tiktok_ad_acc: state.audience.selected_tiktok_ad_acc,
});

export const calibrate = (state: TRootState): TCalibrateState => ({
    estimate_id: state.callibrate.estimate_id,
    estimate_name: state.callibrate.estimate_name,
    is_vatic: state.callibrate.is_vatic,
    qscore: state.callibrate.qscore,
    recency: state.callibrate.recency,
    frequency: state.callibrate.frequency,
    similarity: state.callibrate.similarity,
    intent: state.callibrate.intent,
    initAbsoluteDiff: state.callibrate.initAbsoluteDiff,
    initRelativeDiff: state.callibrate.initRelativeDiff,
});

export const estimate = (state: TRootState): TEstimateState => ({
    method: state.estimate.method,
    activeTab: state.estimate.activeTab,
    estimateName: state.estimate.estimateName,
    customKeywords: state.estimate.customKeywords,
    region: state.estimate.region,
    awareness: state.estimate.awareness,
    promoting: state.estimate.promoting,
    audience: state.estimate.audience,
    updating: state.estimate.updating,
    currEstimateId: state.estimate.currEstimateId,
    promptId: state.estimate.promptId,
    keywords: state.estimate.keywords,
    language: state.estimate.language,
    urls: state.estimate.urls,
    keywordsFetched: state.estimate.keywordsFetched,
});

export const login = (state: TRootState): TLoginState => ({
    user_id: state.login.user_id,
    first_name: state.login.first_name,
    access_token: state.login.access_token,
    is_authenticated: state.login.is_authenticated,
    img_url: state.login.img_url,
    organization: state.login.organization,
    first_login: state.login.first_login
});

export const platform = (state: TRootState): TPlatformState => ({
    google: state.platform.google,
    meta: state.platform.meta,
    x: state.platform.x,
    campaigns: state.platform.campaigns,
    tiktok: state.platform.tiktok,
});

export const signup = (state: TRootState): TSignupState => ({
    company_name: state.signup.company_name,
    first_name: state.signup.first_name,
    last_name: state.signup.last_name,
    email: state.signup.email,
    password: state.signup.password,
    verificationType: state.signup.verificationType,
    verify_email: state.signup.verify_email,
});

export const home = (state: TRootState): THomeState => ({
    campaigns: state.home.campaigns,
    campaignsClick: state.home.campaignsClick,
    adSetsClick: state.home.adSetsClick,
    countryDb: state.home.countryDb,
    country: state.home.country,
});

