import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID } from "../../../utils/axios_helper";
import StyledEstimate from "./styled";
import EstimateDetail from "./EstimateDetail";
import CreateEstimate from "./CreateEstimate";
import {home} from "services/selector";

interface BodyInterface {
  setHeading: (val: String[]) => void;
}

const Estimate: React.FunctionComponent<BodyInterface> = ({ setHeading }) => {
  const [showEstimateDetailsPage, setShowEstimateDetailsPage] = useState(false);
  const {country} = useSelector(home);
  useEffect(() => {
    setHeading(["Create", country.charAt(0) + country.substring(1).toLowerCase()]);
  }, []);
  return (
    <StyledEstimate>
      <Routes>
        <Route
          path="*"
          element={
            <EstimateDetail
              setShowEstimateDetailsPage={setShowEstimateDetailsPage}
            />
          }
        />
        <Route
          path="create/*"
          element={<CreateEstimate setHeading={setHeading} />}
        />
      </Routes>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledEstimate>
  );
};

export default Estimate;
