import React, {useState} from "react";
import authService from "../api/authService";
import {setVerify} from "../services/reducer/signup";
import {validateEmail} from "../utils/auth";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {toastPopup} from "utils/util";

const useForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value );
        setErrorMsg('');
    };

    const handleSubmit = async () => {
        if (!email || !validateEmail(email)) {
            setErrorMsg('Please check if your auth.ts address is correct.');
            return;
        }
        try {
            setLoading(true);
            const rawRes = await authService.emailVerification({email});
            const res = rawRes.data;

            if (res.statusCode == 200) {
                toastPopup.success(res.message);
                dispatch(setVerify({verificationType: 2, verify_email: email}));
                navigate("/auth/verification");
            } else {
                toastPopup.error(res.message);
            }
        } catch (error) {
            setError(`${error}`);
            toastPopup.error(`${error}`)
        } finally {
            setLoading(false);
        }
    };

    return {email, errorMsg, loading, error , handleChange, handleSubmit};
}

export default useForgetPassword;