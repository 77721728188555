import React from 'react';
import {IButton} from "../../../interface/components";
import StyledButton from "./styled";

const Button: React.FunctionComponent<IButton> = ({text, action, children, ...props}) => {
    return (
        <StyledButton {...props} onClick={action}>
            {props.icon ? (
                <img src={props.icon}></img>
            ): ("")}
            <span>{text}</span>
            {children}
        </StyledButton>
    )
}

export default Button;