import google_logo from "assets/auth/google-icon.png";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import {
  FULLSTORY_ORG_ID,
} from "utils/axios_helper";
import StyledLogin from "./styled";
import FullStory from "react-fullstory";
import messageIcon from "assets/auth/Message.png";
import lockIcon from "assets/auth/lock.png";
import hideEyeLight from "assets/auth/hide_eye_light.png";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import useLogin from "hooks/useLogin";
import {googleSite} from "utils/google";

interface LoginInterface {}

const Login: React.FunctionComponent<LoginInterface> = ({}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {email, setEmail, password, setPassword, errors, authenticate} = useLogin();

  return (
    <StyledLogin>
      <div className="header">
        <div className="header-inner-ctn">
          <div>
            <img className="header-logo" src={vatic_logo}></img>
          </div>
          <button className="header-signup-btn" onClick={() => navigate("/auth/signup")}>Sign Up</button>
        </div>
      </div>
      <div className="ctn">
        <div className="inner-ctn">
          <div className="heading">Welcome Back!</div>
          <div className="desc">
            Please login with your Email Address and Password or use SSO.
          </div>
          <div className="label m-top">Email Address</div>
          <div className="input-ctn">
            <img src={messageIcon} alt={""} />
            <input
              className="email-input"
              type="email"
              placeholder="Enter email address"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errors.email && <div className="error">{errors.email}</div>}

          <div className="label m-top">Password</div>
          <div className="input-ctn">
            <img src={lockIcon} alt={""} />
            <input
              className="pass-input"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              src={hideEyeLight}
              alt={""}
              onClick={() => setShowPassword((prev) => !prev)}
              className="hide-icon"
            />
          </div>
          {errors.password && <div className="error">{errors.password}</div>}

          <div className="action m-top">
            <div className="checkbox-ctn">
              <input type="checkbox" className="checkbox" />
              <div>Remember me</div>
            </div>
            <div
              className="forget"
              onClick={() => navigate("/auth/forgot-password")}
            >
              Forgot Password
            </div>
          </div>

          <div className="login-btn m-top" onClick={authenticate}>
            Login
          </div>

          <div className="google-signin m-top" onClick={() => {googleSite('/auth/login')}}>
            <img className="google-icon" alt="google-login" src={google_logo} />
            <div className="google-login-text">Sign in with Google</div>
          </div>

          <div className="signup-text m-top">
            Don’t have an account?{" "}
            <span className="forget" onClick={() => navigate("/auth/signup")}>
              Sign Up
            </span>
          </div>
        </div>
        <div className="m-top hide-icon">
          <span className="privacy-text" onClick={() => navigate("/privacy")}>
            PRIVACY POLICY
          </span>
        </div>
      </div>

      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledLogin>
  );
};

export default Login;
