import styled from "styled-components";
import {dimension, fontSize} from 'APP/theme';
import bgTablet from "assets/background-theme/background-welcome-tablet.svg";
import bgMobile from "assets/background-theme/background-welcome-mobile.svg";
import bgAuthDesktop from "assets/background-theme/background-welcome-desktop.svg";
import colors from "APP/colors";
import theme from "APP/theme";

const StyledLanding = styled.div`
    min-width: 100%;
    min-height: 100%;
    background-image: url(${bgAuthDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: ${theme.fontFamily};

    .header-btn-ctn {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        background-image: url(${bgTablet});
    }

    @media (max-width: ${dimension.mobile.width}){
        background-image: url(${bgMobile});
    }
    
    .heading {
        color: ${colors.black[900]};
        text-align: center;
        font-size: ${fontSize.h1};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .desc {
        color: ${colors.black[900]};
        text-align: center;
        font-size: ${fontSize.h5};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .video-ctn {
        overflow: hidden;
        display: flex;
        width: 1120px;
        height: 456px;
        justify-content: center;
        align-items: center;
        margin-top:1rem;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .video-ctn {
            width: 742px;
            height: 244px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .video-ctn {
            width: 672px;
            height: 274px;
        }
    }
    
    .video-ctn > img, .video-ctn > video {
        object-fit: cover;
        object-position: center; /* Center the image/video in the container */
    }
    
    .btn {
        display: flex;
        height: 40px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 6px;
        background: ${colors.green[400]};
        color: white;
    }
    
    .opt {
        color: ${colors.black[900]};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .icon {
        width: 24px;
        height: 24px;
    }
    
    .lst-ctn {
        width: 930px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .lst-ctn {
            width: 742px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .lst-ctn {
            width: 442px;
        }
    }
    
    .list-ctn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 754px;
        gap: 32px;
        flex-wrap: wrap;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .list-ctn {
            width: 742px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .list-ctn {
            width: 442px;
            grid-template-columns: 1fr;
        }
    }
    
    .opt-item {
        display: flex;
        gap: 16px;
        flex-direction: row;
        align-items: center;
    }

    .header {
        display: flex;
        width: 1140px;
        height: 40px;
        justify-content: center;
        margin-bottom: 8px;
    }

    @media (max-width: ${dimension.mobile.width}){
        .header {
            width: 672px;
            height: 40px;
        }
    }

  .header-inner-ctn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .header-logo {
    height: 30px;
  }

  .header-login-btn {
    background-color: ${colors.primary["600"]};
    color: ${colors.white["50"]};
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    padding: 8px 24px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
  }

  .header-signup-btn {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background-color: ${colors.white["50"]};
    border: 1px solid ${colors.primary["600"]};
    box-sizing: border-box;
    height: 40px;
    padding: 8px 24px;
    font-size: 16px;
    color: ${colors.primary["600"]};
    cursor:pointer;
  }

  @media (max-width: ${dimension.desktopSmall.width}) {
    .ctn {
      width: 552px;
      height: 609px;
    }
  }

  @media (max-width: ${dimension.mobile.width}) {
    .ctn {
      width: 542px;
      height: 640px;
    }
  }
    
    .header-btn-ctn {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
`

export default StyledLanding;