import React, { useEffect, useState } from "react";
import StyledAudiencePlatforms from "./styled";
import fb_icon from "assets/platform_icons/meta.svg";
import x_icon from "assets/platform_icons/x-icon.svg";
import google_social_icon from "assets/platform_icons/adwords.svg";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";
import { useSelector } from "react-redux";
import { platform, home } from "services/selector";
import { IHeading } from "interface/components";
import Modal from "react-modal";

import {
  getGoogleAuthUrl,
  getMetaAuthUrl,
  getXAuthUrl,
  FULLSTORY_ORG_ID,
  getTikTokAuthUrl,
  vaticAxiosPost,
} from "utils/axios_helper";
import FullStory from "react-fullstory";
import usePlatform from "../../../hooks/usePlatform";
import { toastPopup } from "utils/util";

const AudiencePlatforms: React.FunctionComponent<IHeading> = ({
  setHeading,
}) => {
  const {} = usePlatform("/dashboard/platform");
  const platformInfo = useSelector(platform);
  const { country, countryDb} = useSelector(home);

  const [googleAuthUrl, setGoogleAuthUrl] = useState("");
  const [metaAuthUrl, setMetaAuthUrl] = useState("");
  const [xAuthUrl, setXAuthUrl] = useState("");
  const [tiktokAuthUrl, setTiktokAuthUrl] = useState("");
  const [showConnected, setShowConnected] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState<any[]>([]);
  const [accountPlatform, setAccountPlatform] = useState("");
  const [connectedPopupLeft, setConnectedPopupLeft] = useState(0);
  const [connectedPopupTop, setConnectedPopupTop] = useState(0);

  useEffect(() => {
    setHeading([
      "Connect",
      country.charAt(0) + country.substring(1).toLowerCase(),
    ]);
    const load = async () => {
      const tmp = await getGoogleAuthUrl("/dashboard/platform");
      setGoogleAuthUrl(tmp);
      const temp1 = await getMetaAuthUrl("/dashboard/platform");
      setMetaAuthUrl(temp1);
      const temp2 = await getXAuthUrl("/dashboard/platform");
      setXAuthUrl(temp2.url);
      localStorage.setItem("xAuthSecret", temp2.secret);
      const temp3 = await getTikTokAuthUrl("/dashboard/platform");
      setTiktokAuthUrl(temp3);
    };
    load();
  }, []);
  
  
  const handleRemoveAccount = async (accountId: number, platformName: string) => {
    const res = await vaticAxiosPost('/api/v1/ads/account/remove', {
      account_id: accountId.toString(),
      platform_name: platformName,
      db: countryDb
    });
    
    if (res && res.statusCode == '200') {
      toastPopup.success("Account Removed");
    } else {
      toastPopup.error("Somthing went wrong, Account not removed.")
    }

    setTimeout(() => {  window.location.reload() }, 1000);
  }

  return (
    <StyledAudiencePlatforms>
      <div className="main-ctn">
        <div className="upper-ctn">
          <div className="connect-to-your">Connect to your Ad Platform</div>
          <div className="select-and-sign">
            Select and sign into your preffered ad platform
          </div>
        </div>

        <div className="middle-ctn">
          <div className="platform-ctn">
            <div className="platform-icon-container">
              <div>
                <img className="platform-icon" src={fb_icon}></img>
              </div>
              <div className="platform-title">
                Facebook Ads
              </div>
            </div>
            <div className="connected-main-ctn">
              <div className="connected-acc-ctn"
                onClick={(event) => {
                  setSelectedSocial(platformInfo.meta);
                  setAccountPlatform("meta");
                  setShowConnected(true);
                  setConnectedPopupLeft(event.clientX);
                  setConnectedPopupTop(event.clientY);
                }}>
                {platformInfo.meta.length == 0 ? (
                  <div>No account Connected</div>
                ) : (
                  <div>{platformInfo.meta.length} Accounts(s) Connected</div>
                )}
              </div>
              <div className="platform-connect-container">
                <a className="connect" href={metaAuthUrl}>
                  +Connect another account
                </a>
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div className="platform-icon-container">
              <div>
                <img className="platform-icon" src={google_social_icon}></img>
              </div>
              <div className="platform-title">
                Google Ads <span className="asterisk1">&nbsp;*</span>
              </div>
            </div>
            <div className="connected-main-ctn">
              <div
                className="connected-acc-ctn"
                onClick={(event) => {
                  setSelectedSocial(platformInfo.google);
                  setAccountPlatform("google");
                  setShowConnected(true);
                  setConnectedPopupLeft(event.clientX);
                  setConnectedPopupTop(event.clientY);
                }}
              >
                {platformInfo.google.length == 0 ? (
                  <div>No account Connected</div>
                ) : (
                  <div>{platformInfo.google.length} Account(s) Connected</div>
                )}
              </div>
              <div className="platform-connect-container">
                <a className="connect" href={googleAuthUrl}>
                  +Connect another account
                </a>
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div className="platform-icon-container">
              <div>
                <img className="platform-icon" src={x_icon}></img>
              </div>
              <div className="platform-title">
                Twitter(X) Ads
              </div>
            </div>
            <div className="connected-main-ctn">
              <div className="connected-acc-ctn"
                onClick={(event) => {
                  setSelectedSocial(platformInfo.x);
                  setAccountPlatform("x");
                  setShowConnected(true);
                  setConnectedPopupLeft(event.clientX);
                  setConnectedPopupTop(event.clientY);
                }}>
                <div>
                  {platformInfo.x.length == 0 ? (
                    <div>No account Connected</div>
                  ) : (
                    <div>{platformInfo.x.length} Accounts(s) Connected</div>
                  )}
                </div>
              </div>
              <div className="platform-connect-container">
                <a className="connect" href={xAuthUrl}>
                  +Connect another account
                </a>
              </div>
            </div>
          </div>
          <div className="platform-ctn">
            <div className="platform-icon-container">
              <div>
                <img className="platform-icon" src={tiktok_icon}></img>
              </div>
              <div className="platform-title">
                TikTok Ads
              </div>
            </div>
            <div className="connected-main-ctn">
              <div className="connected-acc-ctn"
                onClick={(event) => {
                  setSelectedSocial(platformInfo.tiktok);
                  setAccountPlatform("tiktok")
                  setShowConnected(true);
                  setConnectedPopupLeft(event.clientX);
                  setConnectedPopupTop(event.clientY);
                }}>
                <div>
                  {platformInfo.tiktok.length == 0 ? (
                    <div>No account Connected</div>
                  ) : (
                    <div>{platformInfo.tiktok.length} Accounts(s) Connected</div>
                  )}
                </div>
              </div>
              <div className="platform-connect-container">
                <a className="connect" href={tiktokAuthUrl}>
                  +Connect another account
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="lower-ctn">
          <div className="when-you-provide">
            <span className="asterisk1"> * </span>
            When you provide consent through connecting your google account our
            platform will use it to obtain list of campaigns and then upload a
            user-list to your google ads platform and then attach it to the
            campaign of your choice.
          </div>
        </div>
      </div>
      <Modal
        isOpen={showConnected}
        onRequestClose={() => setShowConnected(false)}
        className="modal-home"
        overlayClassName="modal-overlay-platform"
        style={{
          content: {
            top: `${connectedPopupTop+100}px`,
            left: `${connectedPopupLeft}px`,
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="modal-content-home">
          {selectedSocial.map((val, ind) => (
            <div className="connected-container" key={ind}>
              <div className="user-name">@{val.name}</div><div className="remove-account" onClick={()=>handleRemoveAccount(val.id, accountPlatform)}>Remove Account</div>
            </div>
          ))}
        </div>
      </Modal>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudiencePlatforms>
  );
};

export default AudiencePlatforms;
