import React, { useState, useEffect, useRef } from 'react';
import StyledDropDown from './styled';
import expand from 'assets/dropdown/Expand_left.png';
import { IDropDown } from 'interface/components';

const DropDown: React.FunctionComponent<IDropDown> = ({
                                                          width,
                                                          options = [],
                                                          setSelectedIndex,
                                                          defaultValue,
                                                          action,
                                                      }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    // Ensure we get the default option correctly
    const getDefaultOption = (value: any) =>
        options.find((option) => option.value === value) || options[0] || { value: '', label: '' };

    const [selectedOption, setSelectedOption] = useState(getDefaultOption(defaultValue));

    const handleSelect = (index: number) => {
        const option = options[index];
        setSelectedOption(option);
        setIsOpen(false);
        setSelectedIndex?.(index);
        action?.(option.value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Update the selected option if `defaultValue` or `options` change
    useEffect(() => {
        const newOption = getDefaultOption(defaultValue);
        setSelectedOption(newOption);
    }, [defaultValue, options]);

    return (
        <StyledDropDown width={width}>
            <div className="dropdown-container" ref={dropdownRef}>
                <div className="dropdown-header" onClick={() => setIsOpen((prev) => !prev)}>
                    {selectedOption.label || 'Select an option'}
                    <img className="dropdown-arrow" src={expand} alt="expand" />
                </div>
                {isOpen && (
                    <ul className="dropdown-list">
                        {options.map((option, index) => (
                            <li key={index} onClick={() => handleSelect(index)}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </StyledDropDown>
    );
};

export default DropDown;