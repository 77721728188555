import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StyledHomeReports from "./styled";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "utils/axios_helper";
import FullStory from "react-fullstory";
import GoogleAdReports from "./GoogleAdReports";
import MetaAdReports from "./MetaAdReports";
import XAdsReports from "./XAdsReports";
import TiktokAdReports from "./SnapAdReports";
import SnapAdReports from "./TiktokAdReports";
import { TCampaignMetrics } from "interface/types";
import { toastPopup } from "utils/util";
import { IHomeReportsProps } from "interface/components";
import DateRangePickerReport from "./shared/DatePicker";

const HomeReports: React.FunctionComponent<IHomeReportsProps> = ({
  setHeading,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>("Google Ads");
  const [campaignData, setCampaignData] = useState<{}[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>("");
  const [data, setData] = useState<TCampaignMetrics[]>([]);
  const currentDate = new Date();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(new Date().setDate(currentDate.getDate() - 7)),
    currentDate, // Current date
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (platform: string) => {
    setSelectedTab(platform);
    
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", encodeURIComponent(platform));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromUrl = searchParams.get("tab");
    if (tabFromUrl) {
      setSelectedTab(decodeURIComponent(tabFromUrl));
    }
    setHeading(["Report"]);
  }, [location.search, setHeading]);

  const renderContent = () => {
    switch (selectedTab) {
      case "Google Ads":
        return <GoogleAdReports data={data} campaignId={selectedCampaignId} campaignData={campaignData}/>;
      case "Meta Ads":
        return <MetaAdReports />;
      case "X Ads":
        return <XAdsReports />;
      case "Tiktok Ads":
        return <TiktokAdReports />;
      case "Snap Ads":
        return <SnapAdReports />;
      default:
        return <GoogleAdReports data={data} campaignId={selectedCampaignId} campaignData={campaignData}/>;
    }
  };
  useEffect(() => {
    setHeading(["Report"]);
  }, []);
  
   useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet('/api/v1/ads/campaign-click-impression', {
        start_date: dateRange[0].toISOString().split('T')[0],
        end_date: dateRange[1].toISOString().split('T')[0]
      });
      if (res && res.statusCode == 200 && res.data) {
        
        const temp: TCampaignMetrics[] = res.data.map((item: any, index: number) => {
          return {
                  campaignId:item.campaign_id,
                  campaignName :item.campaign_name,
                  date: item.date,
                  impressions: item.impressions,
                  clicks: item.clicks,
                  ctr: item.ctr,
                  cpc: item.cpc,
                  conversions: item.conversions,
                  cvr: item.cvr,
                  costPerConversion: item.cost_per_conversion,
                  allConversions: item.all_conversions,
                  allConversionsValue: item.all_conversions_value,
                  device: item.device, 
                  adNetworkType: item.ad_network_type,
                  costMicros:  item.cost_micros
                }
        });
        
        setData(temp);
        
        const getUniqueByProperty = <T, K extends keyof T>(array: T[], property: K): T[] => {
            return array.filter((item, index, arr) =>
                arr.findIndex(obj => obj[property] === item[property]) === index
            );
        };
        
        const uniqueById = getUniqueByProperty(temp, "campaignId");
        setCampaignData(uniqueById.map((item) => {
          return {campaignName: item.campaignName, campaignId: item.campaignId}
        }));
        
        if (uniqueById.length > 0) {
          setSelectedCampaignId(uniqueById[0].campaignId);
        }
      } else {
        toastPopup.error("Error Fetching Google Campaign Data");
      }
    }
  
    load();
  }, [dateRange]);

  
  return (
    <StyledHomeReports>
      <div className="heading-ctn">
        <div className="heading">Reports</div>

        <div className="label-16">
          Access detailed reports to track your campaign performance, audience
          insights, and ad effectiveness—all in one place.
        </div>
      </div>

      <div className="main-ctn">
        <div className="filters-ctn">
          <div className="filter-ctn">
            <div>Campaigns</div>
            <div>
              <select className="filter-input" onChange={(e) => setSelectedCampaignId(e.target.value)}>
                {campaignData.map((item: any) => (<option value={item.campaignId}>{ item.campaignName}</option>))}
              </select>
            </div>
          </div>

          <div className="filter-ctn">
            <div>Sort By</div>
            <div>
              <select className="filter-input">
                <option selected>Date</option>
              </select>
            </div>
          </div>

          <div className="filter-ctn">
            <DateRangePickerReport setDateRange={setDateRange} dateRange={dateRange}></DateRangePickerReport>
          </div>
        </div>
      </div>
      <div className="hor-line"></div>

      <div className="platform-options-ctn">
        <div
          className={`platform-option-ctn ${
            selectedTab === "Google Ads" ? "active-option" : ""
          }`}
          onClick={() => handleTabChange("Google Ads")}
        >
          Google Ads
        </div>
        <div
          className={`platform-option-ctn ${
            selectedTab === "Meta Ads" ? "active-option" : ""
          }`}
          onClick={() => handleTabChange("Meta Ads")}
        >
          Meta Ads
        </div>
        <div
          className={`platform-option-ctn ${
            selectedTab === "X Ads" ? "active-option" : ""
          }`}
          onClick={() => handleTabChange("X Ads")}
        >
          X Ads
        </div>
        <div
          className={`platform-option-ctn ${
            selectedTab === "Tiktok Ads" ? "active-option" : ""
          }`}
          onClick={() => handleTabChange("Tiktok Ads")}
        >
          Tiktok Ads
        </div>
        <div
          className={`platform-option-ctn ${
            selectedTab === "Snap Ads" ? "active-option" : ""
          }`}
          onClick={() => handleTabChange("Snap Ads")}
        >
          Snap Ads
        </div>
      </div>

      <div className="summary-text mt-20">Summary</div>

      <div className="platform-content-ctn">{renderContent()}</div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledHomeReports>
  );
};

export default HomeReports;
