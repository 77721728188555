import React, {useEffect, useState} from "react";

import StyledCalibrate from "./styled";
import userAudienceIcon from "assets/calibrate/userAudience.png";
import vaticAudienceIcon from "assets/calibrate/vaticAudience.png";
import PrivateAudiencePopup from "../PrivateAudiencePopup";
import VaticAudiencePopup from "../VaticAudiencePopup";
import Modal from "react-modal";
import './app.css';
import {IHeading} from "../../interface/components";
import {useLocation} from "react-router-dom";

const Calibrate: React.FunctionComponent<IHeading> = ({setHeading}) => {
    const location = useLocation();
    const tempAudienceType = location.state?.audienceType || 0;
    const [audienctType, setAudienctType] = useState(tempAudienceType);

    useEffect(() => {
        setHeading(["Calibrate"]);
    }, []);

    return (
        <StyledCalibrate>
            <div className="heading-ctn">
                <div className="heading">
                    Calibrate Audience
                </div>

                <div className="label-16">
                    Let’s calibrate audience you want to target. Please choose from our audiences
                </div>

                <div className="ctn">
                    <div className="ctn-child" onClick={() => setAudienctType(1)}>
                        <div className="ctn-child2">
                            <img src={userAudienceIcon} alt="" className="icon"/>
                            <div className="h-label">User-defined audiences</div>
                        </div>
                        <div className="label-16 desc">Click here to refine and use the audiences you have created.</div>
                    </div>

                    <div className="ctn-child" onClick={() => setAudienctType(2)}>
                        <div className="ctn-child2">
                            <img src={vaticAudienceIcon} alt="" className="icon"/>
                            <div className="h-label">VectorMath Audience</div>
                        </div>
                        <div className="label-16 desc">Click here to refine and use VectorMath available audiences</div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={audienctType !== 0}
                onRequestClose={() => {}}
                className="modal-home"
                overlayClassName="modal-overlay-home"
                style={{
                    content: {
                        top: `50%`,
                        left: `50%`,
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '100vh'
                    },
                }}
            >
                <div className="modal-content-home">
                    {audienctType === 1 && <PrivateAudiencePopup setAudienceType={setAudienctType}/>}
                    {audienctType === 2 && <VaticAudiencePopup setAudienceType={setAudienctType}/>}
                </div>
            </Modal>
        </StyledCalibrate>
    )
}

export default Calibrate;
