import styled from "styled-components";
import StyledDefine from "components/Home/Estimate/CreateEstimate/Define/styled";
import colors from "APP/colors";
import { dimension } from "APP/theme";

const StyledAudienceDetail = styled(StyledDefine)`
  .head {
    margin: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .create-btn-ctn {
    border-radius: 8px;
    border: 1px solid rgba(220, 227, 227, 0);
    background: #4485f4;
    display: inline-flex;
    padding: 7.5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .plus {
    width: 12px;
    height: 12px;
  }

  .plus-text {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .drop-down-ctn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    display: inline-flex;
    padding: 7.5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .drop-down-opt {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .drop-down-arrow {
    width: 16px;
    height: 16px;
  }

  .t-container {
    height: 100%;
    width: 100%;
  }

  .t-head {
    height: 64px;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 2fr;
    background-color: rgba(68, 133, 244, 0.15);
    border-radius: 5px;
  }

  .t-head-font {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 16px;
  }

  .t-body {
    overflow-x: auto;
    overflow-y: auto;
  }

  .t-row {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
  }

  .delete-img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .t-data {
    display: flex;
    gap: 5px;
  }

  .t-data1 {
    color: #3a76dc;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px;
  }

  .t-data2 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px;
  }

  .t-data3 {
    color: #000;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px;
  }

  .t-data4 {
    display: flex;
    flex-direction: row;
    gap:1rem;
  }

  .et-row-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .et-row-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .extend {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .t-body-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
  }

  .empty-img {
    width: 140px;
    height: 140px;
  }

  .empty-h1 {
    margin-top: 16px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .empty-text {
    margin-top: 8px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    width: 254px;
  }

  .aud-type {
    display: flex;
    gap: 20px;
  }

  .aud-type-item1 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    border-bottom: 3px solid #4485f4;
    padding-bottom: 10px;
  }

  .aud-type-item2 {
    color: #82868f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
  }

  .loaded-summary-box {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .f-spbt {
    display: flex;
    justify-content: space-between;
  }

  .div-w {
    width: 50%;
  }

  .help {
    fill: #b5b6b9;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .speedo-meter-p {
    display: flex;
    gap: 10px;
  }

  .speedo-meter-ctn {
    position: relative;
    height: 77px;
    width: 77px;
  }

  .speedo-meter {
    position: absolute;
  }

  .needle {
    position: absolute;
  }

  .hor-line-neg {
    margin-top: -30px;
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .keyword-25 {
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .t4 {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .t5 {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* modalStyles.css */

  /* Overlay styles */
  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  }

  /* Content styles */
  .custom-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* White background */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    width: 70%; /* Set the width of the modal */
    max-width: 800px; /* Maximum width of the modal */
    height: 500px; /* Maximum height of the modal */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds modal height */
  }

  /* Close button styles */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-button:hover {
    background-color: #0056b3;
  }

  .campaign-row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .campaign-name {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .campaign-platform {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .campaign-head {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .platform-icon {
    width: 30px;
    height: 30px;
  }

  .progress-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 16px;
  }

  .progress-text {
    color: ${colors.green[500]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .progress-text-error {
    color: ${colors.danger[500]};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .progress-bar-ctn {
    position: relative;
    width: 100%;
    height: 6px;
  }

  .progress-base {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #4485f4;
    border-radius: 64px;
  }

  .progress {
    position: absolute;
    height: 100%;
    opacity: 1;
    background: ${colors.green[400]};
    border-radius: 64px;
  }

  .progress-error {
    position: absolute;
    height: 100%;
    opacity: 1;
    background: red;
    border-radius: 64px;
    width: 100%;
  }
    
    .img-24 {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

  @media (max-width: ${dimension.desktopSmall.width}) {
    .t-head{
      display:none;
    }

    .t-row{
      background-color:${colors.white[500]};
    }

    .t-body{
      display:flex;
      flex-direction: column;
      gap:1rem;
    }
  }

  @media (max-width: ${dimension.mobile.width}) {
    
  }
`;

export default StyledAudienceDetail;
