import styled from "styled-components";
import theme, { dimension } from "APP/theme";
import colors from "APP/colors";
import { boxShadow, fontSize } from "APP/theme";
import CommonStyles from "APP/common";

const StyledDefine = styled(CommonStyles)`
    width: 100%;
    height: 100%;
    font-family: ${theme.fontFamily};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ctn {
        display: flex;
        flex-direction: column;
        width: 1140px;
        justify-content: center;
    }
    
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 48px;
    }
    
    .heading {
        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: ${theme.lineHeight};
    }
    
    .heading-desc {
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .content-box {
        display: flex;
        width: 1043px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
    }
    
    .dropdown-ctn {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .label {
        color: ${colors.black[700]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .row1 {
        width: 100%;
    }
    
    .text-area {
        height: 160px;
        padding: 10px 16px;
        resize: none;
        border-radius: 4px;
        border: 1px solid ${colors.black[300]};
        background: ${colors.white[50]};
        outline: none;
        font-size: ${fontSize.large};
    }
    
    .error {
        color: ${colors.danger[500]};
    }

    @media (max-width: ${dimension.desktopSmall.width}) {
        .ctn {
            width: 1120px;
        }
        
        //.header {
        //    margin-top: 40px;
        //}

        .heading {
            font-size: 40px;
        }

        .content-box {
            width: 1031px;
            gap: 32px;
        }
    }

    @media (max-width: ${dimension.mobile.width}) {
        .ctn {
            width: 672px;
        }

        .header {
            margin-top: 48px;
        }

        .heading {
            font-size: 40px;
        }
        
        .content-box {
            width: 672px;
            gap: 40px;
        }
        
        .row {
            display: flex;
            flex-direction: column;
            gap: 40px;
            justify-content: flex-start;
        }
    }
    
`;

export default StyledDefine;
