import styled from "styled-components";
import theme, {dimension, fontSize} from "../../../../../APP/theme";
import colors from "../../../../../APP/colors";

const StyledKeyword = styled.div`
    width: 100%;
    height: 100%;
    font-family: ${theme.fontFamily};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .ctn {
        display: flex;
        flex-direction: column;
        width: 1140px;
        justify-content: center;
    }
    
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 48px;
    }
    
    .heading {
        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: ${theme.lineHeight};
    }
    
    .heading-desc {
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
        width: 90%;
    }
    
    .content-box {
        display: flex;
        width: 1140px;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
    }
    
    .ctn1 {
        display: flex;
        flex-direction: column;
        width: 40%;
    }
    
    .row1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    
    .keyword-length {
        color: ${colors.primary[700]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h5};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .sort-ctn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .sort-text {
        display: flex;
        flex-direction: row;
        height: 40px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .drop-ctn {
        position: relative;
    }
    
    .drop {
        position: absolute;
        top: 0px;
        z-index: 100;
        
        display: flex;
        width: 173px;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;

        border-radius: 4px;
        border: 1px solid ${colors.white[600]};
        background: ${colors.white[50]};
        box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
    }
    
    .opt {
        color: #000;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
        cursor: pointer;
    }
    
    .row2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-top: 23px;
        margin-bottom: 12px;
        width: 80%;
    }
    
    .k-label {
        color: ${colors.black[700]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .k-input {
        display: flex;
        height: 28px;
        padding: 10px 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid ${colors.black[300]};
        background: ${colors.white[50]};
        outline: none;
    }
    
    .k-input-ctn {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
    
    .k-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .ctn2 {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    
    .keyword-nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 16px;
    }
    
    .keyword-nav-item {
        color: ${colors.black[700]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
        text-align: center;
        padding: 10px 0px;
        cursor: pointer;
    }
    
    .keyword-nav-item-active {
        border-radius: 5px;
        background: ${colors.primary[100]};
        font-weight: 700;
        color: ${colors.primary[600]};
    }
    
    .keyword-table-ctn {
        max-height: 510px;
        border-radius: 5px;
        border: 1px solid ${colors.black[50]};
        background: ${colors.white[50]};
        overflow-y: auto;
    }

    table {
        width: 100%; /* Ensure the table takes up full width */
        border-collapse: collapse;
    }

    thead th {
        text-align: left;
        padding: 8px;
        position: sticky;
        top: 0;
        z-index: 10;
        background: ${colors.primary[50]};
        font-weight: 600;
    }
    
    tbody {
        max-height: 500px;
        overflow-y: auto;
    }

    tbody td {
        text-align: left;
        padding: 8px;
        font-weight: 400;
    }

    .col-checkbox {
        width: 5%; /* First column: 5% width */
    }

    .col-score {
        width: 10%; /* Last column: 10% width */
    }

    .col-keywords {
        width: auto; /* Middle column takes remaining space */
    }
    
    .k-checkbox {
        width: 16px;
        height: 16px;
    }
    
    tr {
        height: 39px;
        padding: 0px 24px;
        border-bottom: 1px solid ${colors.white[400]};
    }
    
    th, td {
        color: ${colors.black[700]};
        text-overflow: ellipsis;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        line-height: ${theme.lineHeight};
    }
    
    .save-ctn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 52px;
    }
    
    @media (max-width: ${dimension.desktopSmall.width}) {
        .ctn {
            width: 1120px;
        }
        
        .header {
            margin-top: 40px;
        }

        .heading {
            font-size: 40px;
        }

        .content-box {
            width: 1120px;
        }
        
        .ctn1 {
            width: 35%;
        }
        
        .ctn2 {
            width: 55%;
        }
    }

    @media (max-width: ${dimension.mobile.width}) {
        .ctn {
            width: 672px;
            align-items: center;
        }

        .header {
            margin-top: 48px;
        }

        .heading {
            font-size: 40px;
        }
        
        .content-box {
            width: 672px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .ctn1 {
            width: 100%;
        }

        .ctn2 {
            width: 100%;
        }

        .row1 {
            grid-template-columns: 2fr 1fr;
            align-items: center;
        }
        
        .sort-text {
            width: 90%;
            justify-content: flex-end;
        }
        
        .row2 {
            width: 100%;
        }

        .k-input-ctn {
            display: flex;
            flex-direction: row;
            gap: 16px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        
        .k-input {
            flex-grow: 1;
        }
    }
  
`;

export default StyledKeyword;
