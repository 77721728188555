import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TPlatformState } from "interface/types";

const initialState: TPlatformState = {
  google: [],
  meta: [],
  x: [],
  campaigns: [],
  tiktok: [],
};

export const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<TPlatformState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { set, unset } = platformSlice.actions;
export default platformSlice.reducer;
