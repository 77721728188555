import styled from "styled-components";
import {IStyledToggleButton} from "../../../interface/styled";
import colors from "../../../APP/colors";

const StyledToggleButton = styled.label<IStyledToggleButton>`
    position: relative;
    display: inline-block;
    width: ${(props) => props.width || "36px"};
    height: ${(props) => props.height || "16px"};

    /* Hide default checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* Slider (toggle knob) */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => props.background || colors.primary[50] || "#EAF1FF"};
        transition: 0.4s;
        border-radius: 34px;
        border: ${(props) => props.border || `1px solid ${colors.primary[900] || '#11326B'} `};
    }

    /* Knob inside the slider */
    .slider:before {
        position: absolute;
        content: "";
        height: ${(props) => props.buttonDiameter || "12px"};
        width: ${(props) => props.buttonDiameter || "12px"};
        left: 1px;
        bottom: 1px;
        background-color: ${(props) => props.background || colors.primary[600] || "#256CE8"};
        transition: 0.4s;
        border-radius: 50%;
    }

    /* Checked state for the slider */
    input:checked + .slider {
        background-color: ${(props) => props.background || colors.primary[50] || "#EAF1FF"};
    }

    /* Position knob to the right when checked */
    input:checked + .slider:before {
        transform: translateX(calc(${(props) => props.width || "36px"} - ${(props) => props.buttonDiameter || "12px"} - 4px));
    }
`;

export default StyledToggleButton;