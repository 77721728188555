import styled from "styled-components";

const StyledHome = styled.div`
  margin: 0px;
  // height: 810px;
  background: #fff;
  display: flex;
  justify-content: center;

  .m-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 72px;
  }

  @media (max-width: 720px) {
    .m-container {
      margin-left: 0px; 
    }
  }
`;

export default StyledHome;
