import styled from "styled-components";
import CommonStyles from "../../APP/common";
import theme, {dimension, fontSize} from "../../APP/theme";
import colors from "../../APP/colors";

const StyledCalibrate = styled(CommonStyles)`
    width: 100%;
    
    .heading-ctn {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    
    .ctn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    
    .ctn-child {
        border-radius: 10px;
        background: ${colors.primary[50] || "#EAF1FF"};

        display: flex;
        flex-direction: column;
        height: 331px;
        padding: 40px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .ctn-child2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
    }
    
    .icon {
        height: 128px;
        width: 128px;
    }
    
    .h-label {
        color: ${colors.black[500] || "#171717"} ;
        text-align: center;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h1};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .desc {
        width: 335px;
        text-align: center;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .ctn {
            gap: 16px;
        }
        
        .desc {
            width: 419px;
        }
        
        .ctn-child {
            height: 268px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .ctn {
            gap: 24px;
            grid-template-columns: 1fr;
        }

        .icon {
            height: 80px;
            width: 80px;
        }
        
        .h-label {
            font-size: ${fontSize.h2};
            text-align: center;
        }
        
        .desc {
            width: 203px;
        }

        .ctn-child {
            flex-direction: row;
            justify-content: space-around;
            width: 80%;
        }
    }
`;

export default StyledCalibrate;