import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import {ICompareBarChart} from "../../../interface/components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const CompareBarChart: React.FC<ICompareBarChart> = ({
  label,
  labels: tmpLabels,
  initVal: initValues,
  currVal: currValues,
  filter
}) => {
  const initVal = initValues ? initValues.filter(item => filter.includes(item.id) ).map(item => item.value): [];
  const currVal = currValues ? currValues.filter(item => filter.includes(item.id) ).map(item => item.value): [];
  const labels = tmpLabels ? tmpLabels.filter(item => filter.includes(item.id)).map(item => item.value): [];

  const combinedData = labels.map((label, index) => ({
    label,
    originalValue: initVal[index],
    comparisonValue: currVal[index],
  }));

  // Sort combinedData by the originalValue in descending order
  combinedData.sort((a, b) => b.originalValue - a.originalValue);

  // Separate the sorted data back into labels, dataVal, and newDataVal
  const sortedLabels = combinedData.map((item) =>
    item.label.length > 30 ? item.label.substring(0, 30) + ".." : item.label
  );
  const sortedDataVal = combinedData.map((item) => item.originalValue);
  const sortedNewDataVal = combinedData.map((item) => item.comparisonValue);

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: `initial value`,
        data: sortedDataVal,
        backgroundColor: "#58BE4E",
        barThickness: 4,
      },
      {
        label: `current value`,
        data: sortedNewDataVal,
        backgroundColor: "#5347FF",
        barThickness: 4,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        offset: true,
        ticks: {
          callback: function (value) {
            return label !== 'Absolute Difference' ? `${((value as number) * 100).toFixed(0)}%`: (value as number).toFixed(2); // Convert the value to percentage
          },
          font: {
            size: 11,
            family: "Roboto",
            weight: 400,
          },
          color: "#171717",
        },
        grid: {
          color: "#F7F7F7",
        },
        border: {
          color: "#646464",
        }
      },
      y: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          align: "start",
          crossAlign: "far",
          padding: 0,
          font: {
            size: 11,
            family: "Roboto",
            weight: 400,
          },
          color: "#171717",
        },

        border: {
          color: "#646464",
        }
      },
    },
    plugins: {
      legend: {
        display: false, // Display legend for comparison
        align: "start",
        position: "top",
        labels: {
          boxHeight: 10,
          boxWidth: 30,
        },

      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        height: `${Math.max(labels.length * 30 + 50, 0)}px`,
        width: "100%",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default CompareBarChart;