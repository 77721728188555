import styled from "styled-components";
import colors from "../../../APP/colors";
import {IStyledButton} from "../../../interface/styled";
import {fontSize} from "../../../APP/theme";

const StyledButton = styled.div<IStyledButton>`
    background-color:  ${(props) => props.background || colors.primary["600"]};
    color: ${(props) => props.color || colors.white["50"]};
    border: ${(props) => props.border || "none"};
    box-shadow: ${(props) =>  props.boxShadow ||"0px 2px 4px 0px rgba(0, 0, 0, 0.25)"};
    border-radius: ${(props) => props.borderRadius || "6px"};
    height: ${(props) => props.height || "40px"};
    padding: ${(props) => props.padding || "8px 24px"};
    box-sizing: ${(props) => props.boxSizing || "border-box"};
    text-align: ${(props) => props.textAlign || "left"};
    font-size: ${(props) => props.fontSize || fontSize.large};
    display: ${(props) => props.icon ? 'grid' : 'flex'};
    grid-template-columns: ${(props)=>props.icon ? '1fr 2fr' : ''};
    flex-direction: row;
    align-items: center;
    justify-content:center;
    white-space: ${(props) => props.whiteSpace || "nowrap"};
    cursor: ${(props) => props.cursor || "pointer"}
`;

export default StyledButton;