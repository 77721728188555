import React from "react";
import { Route, Routes } from "react-router";
import StyledCreateEstimate from "./styled";
import Left from "./Left";
import Method from "./Method";
import Define from "./Define";
import Keyword from "./Keyword";

interface CreateEstimateProps {
  setHeading: (val: String[]) => void;
}

const CreateAudience: React.FunctionComponent<CreateEstimateProps> = ({
  setHeading,
}) => {
  return (
    <StyledCreateEstimate>
      <div className="create-container">
        <Routes>
          <Route path="*" element={<Method setHeading={setHeading} />} />
          <Route path="define" element={<Define setHeading={setHeading} />} />
          <Route path="keyword" element={<Keyword setHeading={setHeading} />} />
        </Routes>
      </div>
    </StyledCreateEstimate>
  );
};

export default CreateAudience;
