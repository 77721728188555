import styled from "styled-components";
import StyledLogin from "../Login/styled";
import colors from 'APP/colors';
import {dimension} from "APP/theme";

const StyledEmailVerification = styled(StyledLogin)`
    .ctn-override {
        width: 560px;
        height: 480px;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .ctn-override {
            width: 552px;
            height: 480px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .ctn-override {
            width: 442px;
            height: 480px;
        }
    }
    
    .msg-ctn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .msg {
        padding: 30px;
        background: ${colors.primary[50]};
        border-radius: 50%;
    }
    
    .txt {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .back {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`;

export default StyledEmailVerification;