import colors from "APP/colors";
import CommonStyles from "APP/common";
import { dimension, fontSize } from "APP/theme";
import styled from "styled-components";

const StyledHomeReports = styled(CommonStyles)`
  width: 100%;

  .heading-ctn {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .summary-text {
    color: ${colors.black[500]};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 33.6px */
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: ${colors.black[100]};
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .filters-ctn {
    display: flex;
    gap: 40px;
    margin-top: 40px;
  }

  .filter-input {
    width: 272px;
    height: 48px;
    padding: 10px 16px 10px 16px;
    gap: 10px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    border-color: ${colors.black[300]};
    background-color: ${colors.white[50]};
  }

  .platform-options-ctn {
    display: flex;
    gap: 16px;
  }

  .platform-option-ctn {
    display: flex;
    color: ${colors.primary[600]};
    width: 120px;
    padding: 16px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .active-option {
    background-color: ${colors.primary[600]};
    color: ${colors.white[50]};
    border-radius: 6px;
  }

  .report-cards-ctn {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .insight-ctn {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .t-header {
    height: 64px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: rgba(68, 133, 244, 0.15);
    border-radius: 5px;
  }

  .demographic-main-ctn {
    border-radius: 4px;
    border: 1px solid ${colors.black[50]};
    padding: 24px;
    background: var(--White, #fff);
    width: 400px;
  }

  .device-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .demographic-legend-ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .demographic-circle {
    height: 180px;
  }

  .tr {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    height: 64px;
    border-top: 0.5px solid ${colors.black[50]};
    padding: 0 10px;
  }

  .th {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 10px;
  }

  .chart-option-ctn {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .chart-options-ctn {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
  }

  @media (max-width: ${dimension.desktopSmall.width}) {
  }

  @media (max-width: ${dimension.mobile.width}) {
  }
`;

export default StyledHomeReports;
