import React from 'react';
import StyledFooter from "./styled";
import Button from "../Button";
import colors from "../../../APP/colors";
import { useNavigate } from 'react-router';

const Footer = () => {
    const nav = useNavigate();
    return (
        <StyledFooter>
            <div className="ctn">
                <div className="desc">
                    Not sure what you wanna search or target? Go to Homepage and take a tour of the platform
                </div>
                <Button text={"Go to Homepage"} border={`1px solid ${colors.primary[600]}`} background = {colors.white[50]} color={colors.primary[600]} action={() => nav('/dashboard/home')} />
            </div>
        </StyledFooter>
    );
}

export default Footer;