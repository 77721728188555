import React, {useState} from "react";
import {useNavigate} from "react-router";
import {
    FULLSTORY_ORG_ID,
} from "utils/axios_helper";
import StyledSignup from "./styled";
import FullStory from "react-fullstory";
import business from "assets/auth/Shop.png";
import profile from "assets/auth/User.png";
import message from "assets/auth/Message.png";
import password_icon from "assets/auth/lock.png";
import hideEyeLight from "assets/auth/hide_eye_light.png";
import google_logo from "assets/auth/google-icon.png";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import useSignup from "hooks/useSignup";
import {googleSite} from "utils/google";

const SignupForm: React.FC = () => {
    const navigate = useNavigate();
    const {data, handleDataChange, errors, passwordStrength, handleSignup, loading} = useSignup();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <StyledSignup>
            <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <button className="header-login-btn" onClick={()=> navigate('/auth/login')}>
                        Login
                    </button>
                </div>
            </div>
            <div className="ctn ctn-override">
                <div className="inner-ctn">
                    <div className="heading">Welcome to VaticAI</div>
                    <div className="desc">
                        Please sign up by filling the form or use SSO.
                    </div>

                    <div className="label m-top">
                        Company name *
                    </div>
                    <div className="input-ctn">
                        <img src={business} alt=''></img>
                        <input
                            className='email-input'
                            placeholder="Your company name"
                            type="text"
                            id="company_name"
                            name="company_name"
                            value={data.companyName}
                            onChange={(e) => handleDataChange('companyName', e.target.value)}
                        />
                    </div>
                    {errors.companyName && (
                        <span className="error">{errors.companyName}</span>
                    )}

                    <div className="label m-top">
                        First Name *
                    </div>
                    <div className="input-ctn">
                        <img src={profile} alt=""></img>
                        <input
                            className='email-input'
                            placeholder="First Name"
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={data.firstName}
                            onChange={(e) => handleDataChange('firstName', e.target.value)}
                        />
                    </div>
                    {errors.firstName && (
                        <span className="error">{errors.firstName}</span>
                    )}

                    <div className="label m-top">
                        Last Name *
                    </div>
                    <div className="input-ctn">
                        <img src={profile} alt=""></img>
                        <input
                            className=' email-input'
                            placeholder="Last Name"
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={data.lastName}
                            onChange={(e) => handleDataChange("lastName", e.target.value)}
                        />
                    </div>
                    {errors.lastName && (
                        <span className="error">{errors.lastName}</span>
                    )}

                    <div className="label m-top">
                        Email *
                    </div>
                    <div className="input-ctn">
                        <img src={message} alt=''/>
                        <input
                            className=' email-input'
                            placeholder="Enter your email"
                            type="email"
                            id="email"
                            name="email"
                            value={data.email}
                            disabled={data.googleSignup}
                            onChange={(e) => handleDataChange("email",e.target.value)}
                        />
                    </div>
                    {errors.email && (
                        <span className="error">{errors.email}</span>
                    )}

                    {
                        !data.googleSignup &&
                        <div className="label m-top">
                            Password *
                        </div>
                    }

                    {
                        !data.googleSignup &&
                        <div className="input-ctn">
                            <img src={password_icon} alt=''/>
                            <input
                                className='pass-input'
                                placeholder="Create a password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={data.password}
                                onChange={(e) => handleDataChange('password', e.target.value)}
                            />
                            <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => !prev)}
                                 className="hide-icon"/>
                        </div>
                    }


                    {
                        !data.googleSignup && data.password.length > 0 && (
                            <div className="strength-ctn">
                                <div className="progress-ctn">
                                    <div
                                        className={passwordStrength <= 2 ? "bar-weak" : passwordStrength <= 4 ? "bar-medium" : "bar-strong"}></div>
                                    <div
                                        className={passwordStrength <= 4 && passwordStrength > 2 ? "bar-medium" : passwordStrength > 4 ? "bar-strong" : ""}></div>
                                    <div className={passwordStrength >= 5 ? "bar-strong" : ""}></div>
                                </div>
                                <div className="progress-text-ctn">
                                <div className="desc">Password Strength:</div>
                                    {
                                        <div className={passwordStrength <= 2 ? "desc text-weak": passwordStrength <= 4 ? "desc text-medium": "desc text-strong"} >
                                            {
                                                passwordStrength <= 2 ? "Weak": passwordStrength <= 4 ? "Medium": "Strong"
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    {!data.googleSignup && errors.password && (
                        <span className="error">{errors.password}</span>
                    )}

                    <div className="tnc-container">
                        <input
                            className="checkbox tnc-checkbox"
                            type="checkbox"
                            checked={data.condition}
                            onChange={() => handleDataChange('condition', !data.condition)}
                        />
                        <div className="label m-top">
                            By creating an account you agree with our {" "}
                            <span className="forget">
                              <a
                                  href="https://audience.vaticai.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                Terms of Service & Privacy Policy.
                              </a>
                            </span>
                        </div>
                    </div>
                    {errors.checkbox && (
                        <div className="error">{errors.checkbox}</div>
                    )}

                    <div className="login-btn m-top" onClick={handleSignup}>
                        {loading ? "Loading...": "Sign Up"}
                    </div>

                    {!data.googleSignup &&
                        <div
                            className="google-signin m-top"
                            onClick={() => googleSite('/auth/signup')}
                            >
                            <img
                                className="google-icon"
                                alt="google-login"
                                src={google_logo}
                            />
                            <div className="google-login-text">Sign up with Google</div>
                        </div>
                    }

                    <div className="signup-text m-top">
                        Already have an account? {" "}
                        <span
                            className="forget"
                            onClick={() => navigate("/auth/login")}
                        >
                        Log in
                      </span>
                    </div>
                    <div className="m-top hide-icon txt-center">
                      <span
                        className="privacy-text"
                        onClick={() => navigate("/privacy")}
                      >
                        PRIVACY POLICY
                      </span>
                    </div>
                </div>
            </div>

            <div>
                <FullStory org={FULLSTORY_ORG_ID || ""}/>
            </div>
        </StyledSignup>
    );
};

export default SignupForm;
