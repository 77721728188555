import styled from "styled-components";
import colors from "APP/colors";
import downArrow from "assets/campaign/downArrow.svg";

const StyledLeftPanel = styled.div`
  width: 72px;
  display: flex;
  flex-direction: column;
  background: ${colors.white[50]};
  position: fixed;
  left: 4px;
  top: 72px;
  z-index: 100;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  align-items: center;
  padding: 16px 0px;

  &:hover {
    width: 220px;

    .d-none {
      display: block;
    }

    .menu-row {
      height: 40px;
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      width: 220px;
    }

    & {
      padding: 16px 16px;
    }
  }

  .d-none {
    display: none;
  }

  .d-none:hover {
    display: block;
  }

  .hor-line {
    opacity: 0.15;
    background: #fff;
    height: 1px;
  }

  .region-ctn {
    margin: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
  }

  .region-h {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .region-box {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .region-text {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .region-arrow {
    width: 16px;
    height: 16px;
    opacity: 0.6;
  }

  .menu-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .menu-row {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .icon {
    width: 24px;
    height: 24px;
    padding: 10px;
  }

  .icon-text {
    color: rgba(0,0,0,0.8);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .icon-text-active {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .active_tab {
    background-color: rgba(68, 133, 244, 0.2);
    color: #000 !important;
    border-radius: 5px;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${downArrow}) no-repeat right 0px center;
    background-size: 12px 12px;
    width: 100%;
    padding: 10px 0px;
    font-size: 16px;
    // border: 2px solid #4caf50;
    border-radius: 4px;
    // background-color: #f1f1f1;
    color: #000;
    text-align: center;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  /* Customize the option tag */
  .custom-select option {
    padding: 10px;
    background-color: #fff;
    color: #000;
  }

  /* Hover and focus effects for the select tag */
  .custom-select:hover,
  .custom-select:focus {
    // border-color: #3e8e41;
    outline: none;
  }

  /* Additional styles for the dropdown */
  .custom-select option:hover {
    background-color: #303633;
    color: red;
  }

  .icon-active {
    fill: #fff;
  }

  @media (max-width: 720px) {
    & {
      top: 90vh;
      left: auto;
      width:90%;
    }

    &:hover {
      width: 90%;

      .d-none {
        display: none;
      }

      .menu-row {
        height: 40px;
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        width: 100%;
      }

      & {
        padding: 16px 16px;
      }
    }

    .menu-box {
      flex-direction: row;
      gap:2px;
    }
  }
`;

export default StyledLeftPanel;
