import React, { useEffect, useState } from "react";
import StyledHeader from "./styled";
import { useDispatch, useSelector } from "react-redux";
import leftArrow from "assets/campaign/leftArrow.svg";
import rightArrow from "assets/campaign/arrow-right.svg";
import help from "assets/campaign/Settings.svg";
import setting from "assets/campaign/Settings (1).svg";
import { set as setlogin, unset as loginUnset } from "services/reducer/login";
import {
  set as setplatform,
  unset as platformUnset,
} from "services/reducer/platform";
import { useNavigate } from "react-router";
import { FULLSTORY_ORG_ID } from "utils/axios_helper";
import FullStory from "react-fullstory";
import { unset as audienceUnset } from "services/reducer/audience";

import breadCrumIcon from "assets/right-arrow.png";
import { unset as callibrateUnset } from "services/reducer/callibrate";
import { unset as estimateUnset } from "services/reducer/estimate";
import { unset as signupUnset } from "services/reducer/signup";
import { unset as homeUnset } from "services/reducer/home";
import vaticLogo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import { IHeaderInterface } from "interface/components";
import authService from "api/authService";
import { login } from "services/selector";

const Header: React.FunctionComponent<IHeaderInterface> = ({
  heading,
  isExtended,
  setIsExtended,
}) => {
  const [show_profile_options, set_show_profile_options] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const LoginInfo = useSelector(login);
  const [showSettingOpt, setShowSettingOpt] = useState(false);

  useEffect(() => {
    const load = async () => {
      let rawRes = await authService.me();
      let resp = rawRes.data;
      if (resp && resp.data && resp.data.email) {
        dispatch(
          setlogin({
            first_name: resp.data.first_name,
            user_id: LoginInfo.user_id,
            access_token: LoginInfo.access_token,
            is_authenticated: LoginInfo.is_authenticated,
            img_url: resp.data.img_url,
            organization: resp.data.organization,
          })
        );
      } else {
        navigate("/auth/login");
      }
    };

    load();
  }, []);

  const handle_profile_click = (flag: boolean) => {
    set_show_profile_options(flag);
  };

  const handle_logout = () => {
    dispatch(
      setlogin({
        user_id: -1,
        access_token: "",
        is_authenticated: false,
        first_name: "",
        img_url: "",
      })
    );
    dispatch(loginUnset());
    dispatch(callibrateUnset());
    dispatch(estimateUnset());
    dispatch(signupUnset());
    dispatch(platformUnset());
    dispatch(audienceUnset());
    dispatch(homeUnset());
    dispatch(setplatform({ google: [], meta: [] }));
    localStorage.removeItem("xAuthSecret");
    localStorage.removeItem("ver_token");
    navigate("/");
  };

  return (
    <StyledHeader>
      <div className="h-container">
        <div className="ctn">
          <img src={vaticLogo} className="header-vatic-logo" />
        </div>

        <div className="ctn">
          <div
            className="setting"
            // onMouseLeave={() => setShowSettingOpt(false)}
          >
            <img
              src={setting}
              alt="Setting"
              className="icon"
              onClick={() => setShowSettingOpt((prev) => !prev)}
            />
            {showSettingOpt && (
              <div className="setting-opt">
                <div
                  className="setting-option"
                  onClick={() => navigate("/organization")}
                >
                  Organization
                </div>
              </div>
            )}
          </div>
          <div className="profile-cnt">
            {LoginInfo.img_url ? (
              <img
                src={LoginInfo.img_url}
                className="profile-img"
                onMouseOver={() => handle_profile_click(true)}
                onMouseOut={() => {
                  setTimeout(() => handle_profile_click(false), 1000);
                }}
              />
            ) : (
              <div
                className="user-img"
                onMouseOver={() => handle_profile_click(true)}
                onMouseOut={() => {
                  setTimeout(() => handle_profile_click(false), 1000);
                }}
              ></div>
            )}
            <div
              className={`profile-menu-btn-cntnr ${
                show_profile_options ? "" : "invisible"
              }`}
            >
              <button className="logout-btn" onClick={handle_logout}>
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledHeader>
  );
};

export default Header;
