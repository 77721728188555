import React, {useEffect, useState} from "react";
import {getGoogleUserInfo} from "utils/google";
import authService from "api/authService";
import {set} from "services/reducer/login";
import queryString from "query-string";
import { useNavigate } from "react-router";
import {useDispatch} from "react-redux";
import {toastPopup} from "utils/util";

const useLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });

    const validate = () => {
        let valid = true;
        if (!email) {
            setErrors((prev) => { return {...prev, email: "Please check if your email address is correct."}});
            valid = false;
        }
        if (!password) {
            setErrors((prev) => {return {...prev, password: "Please enter correct password."}});
            valid = false;
        }
        return valid;
    };

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.hash);
        const accessToken = Array.isArray(parsedHash["access_token"])
            ? parsedHash["access_token"][0]
            : parsedHash["access_token"];
        if (accessToken) {
            (async function() {
                const {email: userEmail, firstName: userFirstName,} = await getGoogleUserInfo(accessToken);
                if (userEmail) {
                    const rawUserInfo = await authService.googleLogin({
                        email: userEmail,
                    });
                    const userInfo = rawUserInfo.data;
                    if (userInfo && userInfo.statusCode == 200) {
                        dispatch(
                            set({
                                user_id: userInfo.data.user_id,
                                access_token: userInfo.data.accessToken,
                                is_authenticated: true,
                                first_name: userFirstName,
                                first_login: userInfo.data.first_login,
                            })
                        );
                        toastPopup.success(userInfo.message);
                        navigate("/dashboard");
                    } else {
                        toastPopup.error(`${userInfo.message}`);
                    }
                } else {
                    toastPopup.error("Unable to log in using google account");
                }
            })();
        }
    }, []);

    const authenticate = async () => {
        const isValid = validate();
        if (isValid) {
            const rawResponse = await authService.login({email, password});
            const res = rawResponse.data;
            if (res && res.statusCode === 200) {
                dispatch(
                    set({
                        user_id: res.data.user_id,
                        access_token: res.data.accessToken,
                        is_authenticated: true,
                        first_name: res.data.user_id,
                        first_login: res.data.first_login,
                    })
                );
                toastPopup.success(res.message);
                navigate("/dashboard");
            } else {
                toastPopup.error(res.message);
            }
        }
    };

    return {email, setEmail, password, setPassword, errors, authenticate};
}

export default useLogin;