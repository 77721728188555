import styled from "styled-components";
import StyledLogin from "../Login/styled";
import { dimension } from "APP/theme";
import colors from 'APP/colors';

const StyledSignup = styled(StyledLogin)`
    .ctn-override {
        width: 560px !important;
        height: fit-content !important;
        padding: 2rem 0rem;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .ctn-override {
            width: 552px;
            height: fit-content !important;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .ctn-override {
            width: 442px;
            height: fit-content !important;
        }
    }
    
   .tnc-container {
     display: flex;
     flex-direction: row;
     gap: 1rem;
       align-items: center;
   }

   .tnc-checkbox {
     height: 24px;
     width: 24px;
   }
    
    .txt-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .strength-ctn {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
    .progress-ctn {
        height: 8px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2px;
    }
    
    .progress-ctn div {
        background: ${colors.white[500]};
    }
    
    .bar-weak {
        background: ${colors.danger[600]} !important;
    }

    .bar-medium {
        background: ${colors.warning[600]} !important;
    }

    .bar-strong {
        background: ${colors.success[600]} !important;
    }
    
    .progress-text-ctn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .text-weak {
        color: ${colors.danger[600]} !important;
    }

    .text-medium {
        color: ${colors.warning[600]} !important;
    }

    .text-strong {
        color: ${colors.success[600]} !important;
    }
`;

export default StyledSignup;
