import React, {useEffect, useState} from "react";
import {getGoogleUserInfo} from "utils/google";
import queryString from "query-string";
import validator from "validator";
import authService from "api/authService";
import {set, setVerify} from "services/reducer/signup";
import {toastPopup} from "../utils/util";
import {calculatePasswordStrength, validatePassword} from "../utils/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";

const useSignup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        imgUrl: "",
        accessToken: "",
        companyName: "",
        condition: true,
        googleSignup: false,
    });

    const [errors, setErrors] = useState({
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        checkbox: "",
    });

    const [passwordStrength, setPasswordStrength] = useState(0);

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.hash);
        const accessToken = Array.isArray(parsedHash["access_token"])
            ? parsedHash["access_token"][0]
            : parsedHash["access_token"];
        const referToken = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];

        if (referToken) {
            localStorage.setItem("refer_token", referToken);
        }
        if (accessToken) {
            (async function ()  {
                const {email, firstName, lastName, imgUrl, access_token: tmp} = await getGoogleUserInfo(accessToken);

                if (email) {
                    setData({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        imgUrl: imgUrl,
                        accessToken: tmp,
                        companyName: "",
                        condition: true,
                        googleSignup: true,
                        password: ""
                    });
                } else {
                    toastPopup.error("Unable to sign up using google account");
                }
            })();
        }
    }, []);

    const validateForm = () => {
        let valid = true;

        if (!data.companyName) {
            setErrors((prev) => {return {...prev, companyName: "Company name is required"}});
            valid = false;
        }
        if (!data.firstName) {
            setErrors((prev) => {return {...prev, firstName: "First name is required"}});
            valid = false;
        }
        if (!data.lastName) {
            setErrors((prev) => {return {...prev, lastName: "Last name is required"}});
            valid = false;
        }
        if (!data.email) {
            setErrors((prev) => {return {...prev, email: "Email is required"}});
            valid = false;
        } else if (!validator.isEmail(data.email)) {
            setErrors((prev) => {return {...prev, email: "Please enter a valid Email"}});
            valid = false;
        }
        if (!data.googleSignup && !validatePassword(data.password)) {
            setErrors((prev) => {return {...prev, password:             "Your password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character."
            }});
            valid = false;
        }
        if (!data.condition) {
            setErrors((prev) => {return {...prev, checkbox: "Please agree to the terms"}});
            valid = false;
        }
        return valid;
    };

    const handleSignup = async () => {
        if(loading) {
            toastPopup.error("Please wait...");
            return;
        }
        const isValid = validateForm();
        if (isValid) {
            try {
                setLoading(true);
                const referToken = localStorage.getItem('refer_token') || '';
                const rawRes = data.googleSignup ? await authService.googleRegister({
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                    img_url: data.imgUrl,
                    access_token: data.accessToken,
                    company_name: data.companyName,
                    refer_token: referToken,
                }) : await authService.register({
                    company_name: data.companyName,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                    password: data.password,
                    refer_token: referToken
                });
                const res = rawRes.data;
                if (res.statusCode == 200) {
                    toastPopup.success(res.message);
                    if (!data.googleSignup) {
                        toastPopup.success('Please verify your auth.ts');
                        localStorage.setItem("ver_token", res.data.token);
                        dispatch(setVerify({verificationType: 1, verify_email: data.email}))
                        navigate('/auth/verification');
                    } else {
                        dispatch(set({
                            email: data.email,
                            company_name: data.companyName,
                            first_name: data.firstName,
                            last_name: data.lastName,
                            password: data.password
                        }));
                        navigate("/auth/login");
                    }
                } else {
                    toastPopup.error(`${res.message}`);
                }
            } catch (e) {
                toastPopup.error(`${e}`);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDataChange = (key: string, value: string | boolean) => {
        setData((p) => {
            return {...p, [key]: value};
        });

        if(key === 'password') {
            const tmp = calculatePasswordStrength(value.toString());
            setPasswordStrength(tmp);
        }
    }

    return {data, handleDataChange, errors, passwordStrength, handleSignup, loading};
}

export default useSignup;