import styled from "styled-components";
import bgAuthDesktop from "assets/background-theme/bg-auth-desktop.svg";
import {dimension} from 'APP/theme';
import bgTablet from "assets/background-theme/bg-tablet.svg";
import bgMobile from "assets/background-theme/bg-mobile.svg";

const StyledAuth = styled.div`
    min-width: 100%;
    min-height: 100%;
    background-image: url(${bgAuthDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    @media (max-width: ${dimension.desktopSmall.width}){
        background-image: url(${bgTablet});
    }

    @media (max-width: ${dimension.mobile.width}){
        background-image: url(${bgMobile});
    }
`;

export default StyledAuth;
