import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
import colors from "../../../APP/colors";

// Register necessary chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface HistogramProps {
    data: { label: string; value: number }[];
    color?: string; // Optional prop to customize bar color
}

const VerticalHistogram: React.FC<HistogramProps> = ({ data, color = colors.primary[400] }) => {
    // Prepare chart data
    const chartData = {
        labels: data.map(item => item.label),
        datasets: [
            {
                label: 'Values',
                data: data.map(item => item.value),
                backgroundColor: color,
                borderWidth: 1,
            },
        ],
    };

    // Chart configuration for a horizontal bar graph
    const chartOptions: ChartOptions<'bar'> = {
        indexAxis: 'x', // Makes the bars horizontal
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide legend
            },
            tooltip: {
                enabled: true, // Enable tooltips
            },
        },
        scales: {
            x: {
                beginAtZero: true, // Ensure the X-axis starts at 0
            },
        },
    };

    return (
        <div style={{ minWidth: '400px' }}>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default VerticalHistogram;