import styled from "styled-components";
import theme, {dimension, fontSize} from "../../../APP/theme";
import colors from "../../../APP/colors";

const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    height: 64px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: ${colors.white[100] || "#FCFCFC"};
    
    .c1 {
        display: flex;
        flex-direction: row;
        height: 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        //padding: 23px 150px;
    }
    
    .arrow {
        width: 24px;
        height: 24px;
        rotate: 90deg;
    }
    
    .text {
        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    @media (max-width: ${dimension.desktopSmall.width}) {
        .c1 {
            margin: 8px 24px;
        }
    }

    @media (max-width: ${dimension.mobile.width}) {
        .c1 {
            margin: 8px 24px;
        }
    }
    `;

export default StyledHeader;