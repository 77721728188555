import styled from "styled-components";
import colors from "../../../APP/colors";
import theme, {dimension, fontSize} from "../../../APP/theme";

export const SlidingContent = styled.div<{ translateX: number }>`
    display: flex;
    flex-direction: row;
    gap: 20px;
    transition: transform 0.5s ease-in-out; /* Smooth sliding effect */
    transform: translateX(${(props) => props.translateX}%);

    @media (max-width: ${dimension.mobile.width}) {
        flex-direction: column;
        gap: 24px;
        transition: none;
        transform: none;
    }
`;

const StyledHomeCards = styled.div`
    width: 100%;
    
    .header-ctn {
        width: 1138px;
        display: flex;
        flex-direction: column;
        gap: 24px
    }

    .heading {
        color: ${colors.primary[600]};
        font-family: ${theme.fontFamily};
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: ${theme.lineHeight};
    }

    .heading-desc {
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .cart-ctn {
        width: 100%;
        margin-top: 32px;
        
        display: flex;
        flex-direction: row;
        gap: 20px;
        
        overflow: hidden;
        height: 400px;
    }
    
    .cart {
        display: flex;
        width: 326px;
        height: 308px;
        padding: 42px 23px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid ${colors.black[100]};
        cursor: pointer;
    }
    
    .num-ctn {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
    }
    
    .num-item {
        display: flex;
        width: 56px;
        height: 56px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: ${colors.primary[800]};

        color:${colors.white[50]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h1};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .num-h {
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h5};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .cart-desc {
        align-self: stretch;
        color: #000;
        text-align: center;
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .completed-ctn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    
    .icon {
        width: 24px;
        height: 24px;
    }
    
    .completed-text {
        color: ${colors.success[800]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    .btn-ctn {
        margin-top: 40px;
        width: 1138px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 21px
    }
    
    .cart-desc-ctn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70%;
    }

    @media (max-width: ${dimension.desktopSmall.width}) {
        .header-ctn, .btn-ctn {
            width: 1128px;
        }
        
        .heading {
            font-size: 40px;
        }
        
        .cart {
            height: 255px;
        }
    }

    @media (max-width: ${dimension.mobile.width}) {
        .header-ctn {
            width: 100%;
        }

        .heading {
            font-size: 40px;
        }
        
        .btn-ctn {
            display: none;
        }
        
        .cart {
            width: 87%;
            height: auto;
            padding: 32px 24px;
            flex-direction: row;
        }
        
        .num-ctn {
            flex-direction: row;
        }
        
        .cart-desc-ctn {
            gap: 24px;
            padding-right: 10%;
        }
        
        .cart-desc {
            text-align: left;
        }
        
        .cart-ctn {
            height: auto;
        }
    }
`;

export default StyledHomeCards;
