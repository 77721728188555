import React, { useEffect, useState, useSyncExternalStore } from "react";
import StyledHomeReports from "../styled";
import ReportSummaryCard from "../shared/ReportSummaryCard";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import colors from "APP/colors";
import {
  TAdGroupProp,
  TAdNetworkTypeProp,
  TCampaignMetrics,
  TCampaignReportData,
  TGeoInsightsProp,
  TReportComponentProps,
} from "interface/types";
import ReportTable from "../shared/ReportTable";
import ReportChart from "../shared/ReportChart";
import { vaticAxiosGet } from "utils/axios_helper";
import { toastPopup } from "utils/util";
import { isByteLength } from "validator";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import MultiSegmentProgress from "components/Chart/MutlipleRing";

const GoogleAdReports: React.FunctionComponent<TReportComponentProps> = ({data, campaignId, campaignData}) => {
  const [filterChartOptions, setFilterChartOptions] = useState<{
    [id: string]: boolean
  }>({
    impressions: true,
    clicks: true,
    conversions: true,
    allConversions: true,
    allConversionsValue: true,
    costMacros: true,
    ctr: true,
    cpc: true,
    cvr: true,
    costPerConversion: true,
  });
  
  const [allDatasets, setAllDatasets] = useState<any[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  
  const mapChartOptions: any = {
    impressions: "Impressions",
    clicks: "Clicks",
    conversions: "Conversions",
    allConversions: "All Conversions",
    allConversionsValue: "All Conversions Value",
    costMacros: "Cost Macros",
    ctr: "CTR",
    cpc: "CPC",
    cvr: "CVR",
    costPerConversion: "Cost Per Conversion",
  };
  
  const [totalData, setTotalData] = useState<TCampaignReportData>({
    impressions: 0,
    clicks: 0,
    conversions: 0,
    allConversions: 0,
    allConversionsValue: 0,
    costMacros: 0,
    ctr: 0,
    cpc: 0,
    cvr: 0,
    costPerConversion: 0,
  });
  
  const [dateRangeData, setDateRangeData] = useState<{
    [key: string] : TCampaignMetrics[]
  }>({});
  
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>("");  
  const [deviceTypeData, setDeviceTypeData] = useState<{
    [key: string]: number
  }>({});
  
   const [adNetworkData, setAdNetworkData] = useState<{
    [key: string] : TCampaignMetrics[]
   }>({});

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  const adGroupColumns: (keyof TAdGroupProp)[] = [
    "Campaign Name",
    "Ad Group Name",
    "Ad Name",
    "Keyword",
    "Placement",
    "Impressions",
    "CTR",
  ];

  const geoInsightsColumns: (keyof TGeoInsightsProp)[] = [
    "Country/City",
    "Impressions",
    "CTR",
    "Conversions",
  ];

  const adNetworkColumns: string[] = [
    "Ad Network Type",
    "Impressions",
    "Clicks",
    "CTR",
    "Conversions"
  ];

  const [geoInsightsData, setGeoInsightsData] = useState<TGeoInsightsProp[]>([
    { "Country/City": "USA", Impressions: 1000, CTR: 5, Conversions: 20 },
    { "Country/City": "Canada", Impressions: 1200, CTR: 6, Conversions: 29 },
  ]);
  const [adGroupData, setAdGroupData] = useState<TAdGroupProp[]>([
    {
      "Campaign Name": "Campaign 1",
      "Ad Group Name": "Group 1",
      "Ad Name": "Ad A",
      Keyword: "Keyword 1",
      Placement: "Top",
      Impressions: 1000,
      CTR: 5,
    },
    {
      "Campaign Name": "Campaign 2",
      "Ad Group Name": "Group 2",
      "Ad Name": "Ad B",
      Keyword: "Keyword 2",
      Placement: "Sidebar",
      Impressions: 2000,
      CTR: 7,
    },
  ]);

  const handleChartOptionSelect = (id: string) => {
    setFilterChartOptions((p) => {
      return {
        ...p,
        [id]: !p[id],
      };
    });
  };
  
  const handleCampaignChange = (campaignId: any) => {
      const impressions = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.impressions, 0);
      const clicks = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.clicks, 0);
      const conversions = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.conversions, 0);
      const allConversions = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.allConversions, 0);
      const allConversionsValue = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.allConversionsValue, 0);
      const costMacros = data.filter(item=>item.campaignId==campaignId).reduce((curr: number, acc: any) => curr + acc.costMicros, 0);
      const ctr = impressions ? parseFloat(((clicks / impressions) * 100).toFixed(2)) : 0;
      const cpc = clicks > 0 ? parseFloat((costMacros / clicks / 1000000).toFixed(2)) : 0;
      const cvr = clicks > 0 ? parseFloat(((conversions / clicks) * 100).toFixed(2)) : 0;
      const costPerConversion = conversions > 0 ? parseFloat((costMacros / conversions / 1000000).toFixed(2)) : 0;
      setTotalData({
        impressions, clicks, conversions,
        allConversions, allConversionsValue,
        costMacros, ctr, cpc, cvr, costPerConversion
      });
  }
  
  useEffect(() => {
    data.map((item) => {
      const date = item.date;
      if (!dateRangeData[date]) {
        setDateRangeData((prev) => { return { ...prev, [date]: [item] } });
      } else {
        setDateRangeData((prev) => { return { ...prev, [date]: [...prev[date], item] } })
      }
    })
    
     data.map((item) => {
      const device = item.device;
      if (!deviceTypeData[device]) {
        setDeviceTypeData((prev: any) => { return { ...prev, [device]:1 } });
      } else {
        setDeviceTypeData((prev: any) => { return { ...prev, [device]: prev[device]+1 } })
      }
     })
    
    data.map((item) => {
      const adNetworkType = item.adNetworkType;
      if (!adNetworkData[adNetworkType]) {
        setAdNetworkData((prev) => { return { ...prev, [adNetworkType]: [item] } });
      } else {
        setAdNetworkData((prev) => { return { ...prev, [adNetworkType]: [...prev[adNetworkType], item] } })
      }
    })
    
  }, [data]);
  
  useEffect(() => {
    handleCampaignChange(campaignId);
  }, [campaignId]);
  
  const lineColors = [
  "#1f77b4", 
  "#ff7f0e", // Orange - Highlights
  "#2ca02c", // Green - Growth
  "#d62728", // Red - Alerts or drops
  "#9467bd", // Purple - Secondary data
  "#8c564b", // Brown - Neutral tone
  "#e377c2", // Pink - Subtle variance
  "#7f7f7f", // Gray - Baseline
  "#bcbd22"  // Yellow-green - Optimism or progress
];

  
  useEffect(() => {
    const tempLabel: string[] = [];
    const tempDataset: any[] = [];
    
    Object.keys(filterChartOptions).filter(item => item != "costMacros").map((item, index) => {
      tempDataset.push(
        {
          id: index + 1,
          label: item,
          data: [],
          borderColor: lineColors[index],
          tension: 0.2,
          fill: false,
        });
    })
    
    Object.keys(dateRangeData).map((date: string, index: number) => {
      tempLabel.push(date)
      const impressions = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.impressions, 0);
      const clicks = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.clicks, 0);
      const conversions = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.conversions, 0);
      const allConversions = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.allConversions, 0);
      const allConversionsValue = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.allConversionsValue, 0);
      const costMacros = dateRangeData[date].filter(item => item.campaignId == selectedCampaignId).reduce((curr: number, acc: any) => curr + acc.costMicros, 0);
      const ctr = impressions ? parseFloat(((clicks / impressions) * 100).toFixed(2)) : 0;
      const cpc = clicks > 0 ? parseFloat((costMacros / clicks / 1000000).toFixed(2)) : 0;
      const cvr = clicks > 0 ? parseFloat(((conversions / clicks) * 100).toFixed(2)) : 0;
      const costPerConversion = conversions > 0 ? parseFloat((costMacros / conversions / 1000000).toFixed(2)) : 0;
      tempDataset[0].data.push(impressions)
      tempDataset[1].data.push(clicks)
      tempDataset[2].data.push(conversions)
      tempDataset[3].data.push(allConversions)
      tempDataset[4].data.push(allConversionsValue)
      tempDataset[5].data.push(ctr)
      tempDataset[6].data.push(cpc)
      tempDataset[7].data.push(cvr)
      tempDataset[8].data.push(costPerConversion)
    })
      
    setAllDatasets(tempDataset);
    setLabels(tempLabel)
    
    console.log(tempDataset);
    }, [filterChartOptions, dateRangeData, selectedCampaignId]);

  return (
    <StyledHomeReports>
      <div className="report-cards-ctn mt-40">
        <ReportSummaryCard
          title="Impressions"
          type="count"
          value={totalData.impressions}
          percentageChange={12.18}
          progressPercent={35}
        />
        <ReportSummaryCard
          title="Clicks"
          type="count"
          value={totalData.clicks}
          percentageChange={-9.18}
        />
        <ReportSummaryCard
          title="Click-through Rate (CTR)"
          type="percent"
          value={totalData.ctr}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="Cost per Click (CPC)"
          type="cost"
          value={totalData.cpc}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="Conversions"
          type="count"
          value={totalData.conversions}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="Conversion Rate (CVR)"
          type="percent"
          value={totalData.cvr}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="Cost per Conversion"
          type="cost"
          value={totalData.costPerConversion}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="All Conversions"
          type="count"
          value={totalData.allConversions}
          percentageChange={12.18}
        />
        <ReportSummaryCard
          title="All Conversions Value"
          type="cost"
          value={totalData.allConversionsValue}
          percentageChange={12.18}
        />
      </div>

      <div className="hor-line"></div>

      <div className="insight-ctn">
        <div className="filter-ctn">
          <div>Campaigns</div>
          <div>
            <select className="filter-input" onChange={(e) => {setSelectedCampaignId(e.target.value)}}>
              {campaignData.map((item: any) => (<option value={item.campaignId}>{ item.campaignName}</option>))}
            </select>
          </div>
        </div>

        <div className="report-chart">
          <ReportChart
            labels={labels}
            datasets={allDatasets.filter((item)=>(filterChartOptions[item.label]))}
            options={options}
            height={500}
          />
        </div>

        <div className="chart-options-ctn">
          {Object.keys(filterChartOptions).map((item, index) => (
            <div key={"chart-option-" + index} className="chart-option-ctn">
              <input
                type="checkbox"
                className="checkbox icon-img"
                checked={filterChartOptions[item]}
                onChange={() => handleChartOptionSelect(item)}
              />
              <span className="label-14" style={{color:`${lineColors[index]}`}}>
                {mapChartOptions[item]}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="hor-line"></div>
      <ReportTable
        columns={adGroupColumns}
        data={adGroupData}
        title="Ad Group Name"
      />
      <div className="hor-line"></div>
      <ReportTable
        columns={geoInsightsColumns}
        data={geoInsightsData}
        title="Geo Insights"
      />
      <div className="hor-line"></div>
      <div className="demographics-ctn">
        <div className="summary-text">Demographics</div>
        <div className="demographic-main-ctn mt-40">
          <div className="device-type label-16">
            <div></div>
            <div style={{textAlign:'center'}}>Device Type</div>
          </div>
          <div>
            <MultiSegmentProgress data={deviceTypeData}></MultiSegmentProgress>
          </div>
        </div>
      </div>
      <div className="hor-line"></div>
      <div className="ad-group-ctn">
      <div
        className="t-header"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${adNetworkColumns.length}, 1fr)`,
        }}
      >
        {adNetworkColumns.map((col, index) => (
          <div className="th" key={index}>
            {String(col)}
          </div>
        ))}
      </div>
      {Object.keys(adNetworkData).map((item, rowIndex) => (
        <div className="tr"
                key={rowIndex}
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${adNetworkColumns.length}, 1fr)`,
                }}>
          <div>{ item}</div>
          <div>{adNetworkData[item].reduce((acc, curr) => (acc + curr.impressions), 0)}</div>
          <div>{adNetworkData[item].reduce((acc, curr) => (acc + curr.clicks), 0)}</div>
          <div>{adNetworkData[item].reduce((acc, curr) => (acc + curr.ctr), 0)}</div>
          <div>{adNetworkData[item].reduce((acc, curr) => (acc + curr.conversions), 0)}</div>
        </div>
      ))}
      </div>
    </StyledHomeReports>
  );
};

export default GoogleAdReports;
