import styled from "styled-components";
import colors from "APP/colors";
import theme, {fontSize} from "../../../../../APP/theme";

const StyledMethod = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  height: 100%;
  font-family: "Roboto", sans-serif;
  padding-bottom: 1rem;
  width: 100%;

  .fl-ctn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .main-intro {
    transition: all 10s ease-in-out;
  }

  .m1 {
    margin-top: 15%;
  }

  .inner-ctn {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .welcome {
    font-size: 48px;
    color: ${colors.primary[500]};
    font-weight: 600;
  }

  .second-desc {
    font-size: 20px;
  }

  .third-desc {
    font-size: 16px;
    color: ${colors.primary[500]};
  }

  .move-up {
    margin-top: 0;
  }

  .white-font {
    color: white;
  }

  .bg-white {
  }

  .create-methods-ctn {
    display: flex;
    height: 60%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .method-icon-ctn {
    display: flex;
    justify-content: center;
  }

  .method-ctn {
    background-color: ${colors.primary[50]};
    height: 450px;
    max-width: 367px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
  }

  .method-title {
    font-size: 48px;
    text-align: center;
  }

  .method-desc {
    padding: 1rem;
  }

  .method-icon {
    width: 128px;
  }

  .aud-name-input {
    color: #000;
    height: 45px;
    padding: 0px 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: none;
    outline: none;
    width: 400px;
    border: solid #dcdcdc 1px;
    border-radius: 5px;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  @media (max-width: 720px) {
    .create-methods-ctn {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 1rem;
      width: 100%;
    }

    .method-icon {
      width: 80px;
      height: 80px;
    }

    .method-ctn {
      height: auto;
      padding: 40px 5px;
      max-width: 1200px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: space-between;
      border-radius: 10px;
      cursor: pointer;
    }

    .method-title {
      font-size: 40px;
      text-align: center;
    }

    .method-desc {
      padding: 1rem;
      font-size: 16px;
    }

    .method-detail-ctn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .method-icon-ctn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .welcome {
      font-size: 40px;
    }

    .second-desc {
      font-size: 16px;
    }

    .third-desc {
      font-size: 14px;
    }
  }

    .label {
        color: ${colors.black[700]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .aud-name-inp {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

export default StyledMethod;
