import React from "react";
import { Navigate } from "react-router";
import {IAuthenticate} from "../../interface/components";
import useAuthenticate from "../../hooks/useAuthenticate";

const Authenticate: React.FunctionComponent<IAuthenticate> = ({
  component,
}) => {
  const isAuthenticated = useAuthenticate();
  return isAuthenticated ? component : <Navigate to="/auth/login" />;
};

export default Authenticate;
