const colors = {
  primary: {
    50: "#eaf1ff",
    100: "#bdd5ff",
    200: "#9dc0ff",
    300: "#70a4ff",
    400: "#5492ff",
    500: "#2977ff",
    600: "#256ce8",
    700: "#1d54b5",
    800: "#17418c",
    900: "#11326b",
  },

  green: {
    50: "#e6f2ed",
    100: "#b2d8c7",
    200: "#8dc5ac",
    300: "#5aab86",
    400: "#399a6e",
    500: "#08814a",
    600: "#077543",
    700: "#065c35",
    800: "#044729",
    900: "#03361f",
  },

  success: {
    50: "#eefbec",
    100: "#cbf2c4",
    200: "#b2eba7",
    300: "#8fe27f",
    400: "#79dd66",
    500: "#58d440",
    600: "#50c13a",
    700: "#3e972d",
    800: "#307523",
    900: "#25591b",
  },

  warning: {
    50: "#fffdeb",
    100: "#fef8bf",
    200: "#fdf5a1",
    300: "#fcf176",
    400: "#fcee5b",
    500: "#fbea32",
    600: "#e4d52e",
    700: "#b2a624",
    800: "#8a811c",
    900: "#696215",
  },

  danger: {
    50: "#fcebeb",
    100: "#f6c1c1",
    200: "#f1a3a3",
    300: "#eb7979",
    400: "#e75f5f",
    500: "#e13737",
    600: "#cd3232",
    700: "#a02727",
    800: "#7c1e1e",
    900: "#5f1717",
  },

  white: {
    50: "#fefefe",
    100: "#fcfcfc",
    200: "#fafafa",
    300: "#f8f8f8",
    400: "#f7f7f7",
    500: "#f5f5f5",
    600: "#dfdfdf",
    700: "#aeaeae",
    800: "#878787",
    900: "#676767",
  },

  black: {
    50: "#e8e8e8",
    100: "#b7b7b7",
    200: "#949494",
    300: "#646464",
    400: "#454545",
    500: "#171717",
    600: "#151515",
    700: "#101010",
    800: "#0d0d0d",
    900: "#0a0a0a",
  },
};

export default colors;
