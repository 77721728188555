import axiosInstance from './axiosInstance';
import {IGoogleLogin, ILogin, IRegister, IGoogleRegister, IEmailVerificationResend, IEmailVerification, IPasswordReset} from "interface/auth";

const authService = {
    login: (credentials: ILogin) => axiosInstance.post('/api/auth/v1/login', credentials),
    register: (data: IRegister) => axiosInstance.post('/api/auth/v1/register', data),
    me: () => axiosInstance.get('/api/auth/v1/me'),
    googleLogin: (credentials: IGoogleLogin) => axiosInstance.post('/api/auth/v1/login-by-google', credentials),
    googleRegister: (data: IGoogleRegister) => axiosInstance.post('/api/auth/v1/register-by-google', data),
    emailVerification: (data: IEmailVerification) => axiosInstance.post('/api/auth/v1/reset-password-send', data),
    emailVerificationResend: (data: IEmailVerificationResend) => axiosInstance.post('/api/v1/email-verification-repeat', data),
    passwordReset: (data: IPasswordReset) => axiosInstance.post('/api/auth/v1/reset-password', data),
    validPasswordRequest: (data: any) => axiosInstance.get('/api/auth/v1/is-valid-pass-reset-req', {
        params: data
    })
};

export default authService;