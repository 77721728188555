import React, { useEffect } from "react";
import StyledPaymentFail from "./styled";
import { useSelector } from "react-redux";
import { vaticAxiosPost } from "utils/axios_helper";
import queryString from "query-string";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { home } from "services/selector";
import cautionImg from "../../assets/caution.svg";
import Button from "../../shared/components/Button";

interface IPaymentFail {
  setHeading: (val: string[]) => void;
}

const PaymentFail: React.FunctionComponent<IPaymentFail> = ({ setHeading }) => {
  const { country } = useSelector(home);

  const updateInfo = async () => {
    const parsedHash = queryString.parse(window.location.search);
    const token = Array.isArray(parsedHash["token"])
      ? parsedHash["token"][0]
      : parsedHash["token"];
    if (!token) {
      return;
    }

    const sessionId = Array.isArray(parsedHash["session_id"])
        ? parsedHash["session_id"][0]
        : parsedHash["session_id"];

    const response = await vaticAxiosPost("/api/v1/payment/status", {
      token: token,
      status: "failed",
      checkout_session_id: sessionId
    });

    if (response.statusCode === 200) {
      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    setHeading([
      "Billing",
      country.charAt(0) + country.substring(1).toLowerCase(),
    ]);
    updateInfo();
  }, []);
  return (
    <StyledPaymentFail>
      <div>
        <img src={cautionImg} className="caution-img"></img>
      </div>
      <div className="failed-text">Transaction Failed</div>
      <div className="btn-ctn">
        <div className="home-btn-ctn">
          <Button
            text="Go to Home Page"
            action={() => {
              window.location.href = "/";
            }}
          />
        </div>
        <div className="home-btn-ctn">
          <Button
            text="Try Again"
            action={() => {
              window.location.href = "/dashboard/billing";
            }}
          />
        </div>
      </div>
    </StyledPaymentFail>
  );
};

export default PaymentFail;
