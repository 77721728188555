import styled from "styled-components";
import colors from "../../../../../APP/colors";
import {fontSize} from "../../../../../APP/theme";

const StyledLeft = styled.div`
    max-width: 721px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  .row {
    display: flex;
      flex-direction: row;
    align-items: center;
      gap: 16px;
    height: 48px;
  }

  .num {
    width: 48px;
    height: 100%;
    border-radius: 50%;
    background: #fff;
    border: 1px solid ${colors.black[200] || "#949494"};
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${fontSize.h4};
  }

  .num-active {
      background: ${colors.primary[700] || "#1D54B5"};
        color: ${colors.white[50] || "#FFF"};
      border: none;
  }

  .name {
    color: ${colors.black[200] || "#949494"};
    font-size: ${fontSize.h4};
  }

  .name-active {
      color: ${colors.black[500] || "#171717"};
      font-weight: 700;
  }
`;

export default StyledLeft;
