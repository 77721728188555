import React, { useState } from "react";
import authService from "../api/authService";
import {useSelector} from "react-redux";
import {signup} from "services/selector";
import {toastPopup} from "utils/util";

const useEmailVerification = () => {
    const data = useSelector(signup);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const getVerificationLink = async () => {
        setLoading(true);
        try {
            let rawRes;
            if (data.verificationType == 1) {
                const token = localStorage.getItem('ver_token') || '';
                rawRes = await authService.emailVerificationResend({
                    first_name: data.first_name,
                    email: data.email,
                    token: token,
                });
            } else {
                rawRes = await authService.emailVerification({
                    'email': data.verify_email
                });
            }
            const res = rawRes.data;
            if (res.statusCode == 200) {
                toastPopup.success(res.message);
                toastPopup.success('Please verify your auth.ts');
            } else {
                setError(res.message);
                toastPopup.error(`${res.message}`);
            }
        } catch (error) {
            setError(`${error}`);
            toastPopup.error(`${error}`);
        } finally {
            setLoading(false);
        }
    };

    return {getVerificationLink, loading, error}
}

export default useEmailVerification;