import React, { useEffect, useState } from "react";
import StyledSignalCategory from "./styled";
import { vaticAxiosGet } from "utils/axios_helper";
import { useSelector } from "react-redux";

import {home} from "services/selector";
import {ISignalCategory} from "../../interface/components";
import {TSegmentCategory, TSegmentData} from "../../interface/types";

import expandIcon from "assets/calibrate/Add_ring.png";
import collapseIcon from "assets/calibrate/Remove.png";
import userIcon from "assets/calibrate/Group_light.png";
import activeUserIcon from "assets/calibrate/Group_light_active.png";


const SignalCategory: React.FunctionComponent<ISignalCategory> = ({parentId, handleSegmentSelect, level}) => {
  const [segmentCategory, setSegmentCategory] = useState<Array<TSegmentCategory>>();
  const {countryDb} = useSelector(home);
  const [segments, setSegments] = useState<Array<TSegmentData>>();
  const [activeTab, setActiveTab] = useState<number>(-1);

  const handleCategorySelect = (ind: number) => {
    if( activeTab === ind)
      setActiveTab(-1);
    else
      setActiveTab(ind);
  }

  useEffect(() => {
    (async function ()  {
      const res1 = await vaticAxiosGet("/api/v1/signals_category", {
        country: countryDb,
        parent_id: parentId,
      });
      if (res1 && res1.data) {
        setSegmentCategory(res1.data);
      }

      const res = await vaticAxiosGet("/api/v1/signal_data/" + parentId, {
        db: countryDb,
      });
      if (res && res.data) {
        setSegments(res.data);
      }
    })();
  }, []);

  return (
    <StyledSignalCategory>
      <div className={level != 1 ? "vertical-line": ""} />
      <div>
      {
        segmentCategory?.map((item, ind) => (
            <div key={"category" + ind.toString()} className="child-ctn">
              <div className={level != 1 ? "small-horizontal-line": ""}/>
              <div>
                <div onClick={() => handleCategorySelect(ind)}
                     className={ind === activeTab ? "category category-active" : "category"}>
                  <div className="row">
                    <img src={ind === activeTab ? activeUserIcon : userIcon} alt={''} className="icon-img"/>
                    <span className={ind === activeTab ? "label-14 label-14-active" : "label-14"}>{item.name + " (" + item.count + ")"}</span>
                  </div>

                  <img src={ind === activeTab ? collapseIcon : expandIcon} alt={'+'} className="icon-img"/>
                </div>
                {
                    ind === activeTab && <div>
                      <SignalCategory parentId={item.id} handleSegmentSelect={handleSegmentSelect}/>
                    </div>
                }

                {level === 1 && <div className="horizontal-line"/>}
              </div>
            </div>
        ))
      }

        {
          segments?.map((item, ind) => (
              <div key={"segment" + ind.toString()} className="child-ctn">
                <div className={level != 1 ? "smaller-horizontal-line": ""}/>
                <div onClick={() => {
                  handleSegmentSelect(item.id, item.name)
                }} className="label-14 segment">{item.name}</div>
              </div>
          ))
        }
      </div>

    </StyledSignalCategory>
  );
};

export default SignalCategory;
