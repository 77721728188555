import React, {useState, useEffect} from "react";
import StyledHome from "./styled";
import LeftPanel from "./LeftPanel";
import Header from "./Header";
import Body from "./Body";
import { useNavigate } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "../../utils/axios_helper";
import { set } from "services/reducer/platform";
import { useDispatch, useSelector } from "react-redux";
import { TCampaign } from "interface/types";
import { login } from "services/selector";
import FirstLogin from "./FirstLogin";
import {useLocation} from "react-router-dom";
import {getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem} from "../../utils/util";

const Home = () => {
  const [isExtended, setIsExtended] = useState(true);
  const [heading, setHeading] = useState<Array<String>>(["Home"]);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const { first_login } = useSelector(login);

  const navigate = useNavigate();
  const location =useLocation();

  const redirect = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    const load = async () => {
      const googleConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/google/connected-accounts"
      );
      if (googleConnectedAccRes && googleConnectedAccRes.data) {
        dispatch(set({ google: googleConnectedAccRes.data }));
      }

      const metaConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/meta/connected-accounts"
      );
      if (metaConnectedAccRes && metaConnectedAccRes.data) {
        dispatch(set({ meta: metaConnectedAccRes.data }));
      }

      const xConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/x/connected-accounts"
      );
      if (xConnectedAccRes && xConnectedAccRes.data) {
        dispatch(set({ x: xConnectedAccRes.data }));
      }

      const tiktokConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/tiktok/connected-accounts"
      );
      if (tiktokConnectedAccRes && tiktokConnectedAccRes.data) {
        dispatch(set({ tiktok: tiktokConnectedAccRes.data }));
      }
    };
    load();
  }, []);

  // useEffect(() => {
  //   const previousPath = getLocalStorageItem('previousPath', '');
  //   if(location.pathname === '/dashboard/estimate' && previousPath != 'xyz') {
  //     setLocalStorageItem('previousPath', location.pathname);
  //   } else {
  //     if (previousPath === '/dashboard/estimate') {
  //       setLocalStorageItem('previousPath', 'xyz');
  //       navigate('/dashboard/estimate', {
  //         state: { currPath: location.pathname , showModel: true},
  //       });
  //     }
  //   }
  // }, [location]);

  // useEffect(() => {
  //   removeLocalStorageItem('previousPath');
  // }, []);

  return (
    first_login?(<FirstLogin />):(<StyledHome>
      <LeftPanel
        isExtended={isExtended}
        heading={heading}
        setHeading={setHeading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="m-container">
        <Header
          isExtended={isExtended}
          setIsExtended={setIsExtended}
          heading={heading}
        />
        <Body heading={heading} setHeading={setHeading} activeTab={activeTab} />
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledHome>)
  );
};

export default Home;
