import React, {useEffect, useState} from 'react';
import StyledBullsEye from "./styled";
import {TActiveArc} from "../../../interface/types";

const BullsEyeNew: React.FunctionComponent<TActiveArc> = ({activeArc, setActiveArc, getShowData}) => {
    const numRings = 5;
    const numArcs = 5;

    // State to track the visibility of arcs
    const temp = Array.from({ length: numRings }, () => Array(numArcs).fill(true));
    for (let i = 0; i < numRings; i++) {
        for (let j = 0; j < numArcs; j++) {
            temp[i][j] = i <= activeArc[j];
        }
    }
    const [visibleArcs, setVisibleArcs] = useState(temp);

    const arcColors = [
        ['#5DE12A', '#F4D505', '#00E8B8', '#FF00DE','#B4D0FF'],
        ['#CFEF2E', '#FFE534', '#47FFD8', '#FF70EC','#8AB0F1'],
        ['#FFF630', '#FFEF85', '#6EFFE0', '#FF9CF2','#4485F4'],
        ['#FFA21D', '#FFF4AF', '#86FFE5', '#FFB9F6','#0858E1'],
        ['#FF2905', '#FFF9D1', '#B8FFF0', '#FFE2FB','#1146A2']
    ];

    const labels = [
        [5, '1W', 5, 5, 5],
        [4, '2W', 4, 4, 4],
        [3, '1M', 3, 3, 3],
        [2, '3M', 2, 2, 2],
        [1, '∞', 1, 1, 1]
    ];

    // Function to handle arc clicks
    const handleArcClick = (ringIndex: number, arcIndex: number) => {
        const newVisibility = [...visibleArcs];

        for (let i = 0; i < numRings; i++) {
            newVisibility[i][arcIndex] = i <= ringIndex;
        }
        setVisibleArcs(newVisibility);
        setActiveArc((p: any) => {return {...p, [arcIndex]: ringIndex}});
    };

    // Function to calculate arc path
    const getArcPath = (outerRadius: number, innerRadius: number, startAngle: number, endAngle: number) => {
        const x1 = 150 + outerRadius * Math.cos(startAngle);
        const y1 = 150 + outerRadius * Math.sin(startAngle);
        const x2 = 150 + outerRadius * Math.cos(endAngle);
        const y2 = 150 + outerRadius * Math.sin(endAngle);

        const x1Inner = 150 + innerRadius * Math.cos(endAngle);
        const y1Inner = 150 + innerRadius * Math.sin(endAngle);
        const x2Inner = 150 + innerRadius * Math.cos(startAngle);
        const y2Inner = 150 + innerRadius * Math.sin(startAngle);

        const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

        return `M 150 150 L ${x1} ${y1} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${x2} ${y2} L ${x1Inner} ${y1Inner} A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x2Inner} ${y2Inner} Z`;
    };

    return (
        <StyledBullsEye>
            <svg width="300" height="300" viewBox="0 0 300 300" style={{background: '#FFF', borderRadius: '50%'}}>
                {Array.from({length: numRings}, (_, ringIndex) => {
                    const outerRadius = (ringIndex + 1) * 30  // Adjust radius for each ring with a gap
                    const innerRadius = outerRadius - 30; // Ensure the inner radius is smaller than the outer
                    return Array.from({length: numArcs}, (_, arcIndex) => {
                        const startAngle = (arcIndex * (2 * Math.PI / numArcs)) - Math.PI / 2; // Position arcs evenly
                        const endAngle = ((arcIndex + 1) * (2 * Math.PI / numArcs)) - Math.PI / 2;

                        const arcPath = getArcPath(outerRadius, innerRadius, startAngle, endAngle);
                        const isVisible = visibleArcs[ringIndex][arcIndex];

                        return (
                            <g key={`ring-${ringIndex}-arc-${arcIndex}`}>
                                <path
                                    d={arcPath}
                                    fill={isVisible ? arcColors[ringIndex][arcIndex] : 'transparent'}
                                    stroke="#F3F3F3"
                                    strokeWidth={"1"}
                                    onClick={() => handleArcClick(ringIndex, arcIndex)}
                                    style={{cursor: 'pointer'}}
                                />
                                {/* Label Positioning */}
                                <rect
                                    x={150 + (outerRadius - 15) * Math.cos((startAngle + endAngle) / 2) - 10} // Adjust x to center rect
                                    y={150 + (outerRadius - 15) * Math.sin((startAngle + endAngle) / 2) - 10} // Adjust y to center rect
                                    width="20" // Width of the background
                                    height="20" // Height of the background
                                    fill={activeArc[arcIndex] === ringIndex ? "#FFF" : "#949494"} // Background color
                                    rx="50%" // Optional: Rounded corners
                                    ry="50%"
                                    onClick={() => handleArcClick(ringIndex, arcIndex)}
                                    cursor="pointer"
                                />

                                {/* Text label */}
                                <text
                                    x={150 + (outerRadius - 15) * Math.cos((startAngle + endAngle) / 2)}
                                    y={150 + (outerRadius - 15) * Math.sin((startAngle + endAngle) / 2)}
                                    textAnchor="middle"
                                    fill={activeArc[arcIndex] === ringIndex ? "#151515" : "#FFF"}
                                    fontSize="10"
                                    dy="0.35em" // Adjust vertical alignment
                                    style={{pointerEvents: 'none'}}
                                    onClick={() => handleArcClick(ringIndex, arcIndex)}
                                >
                                    {labels[ringIndex][arcIndex]} {/* Labels from 5 to 1 */}
                                </text>
                            </g>
                        );
                    });
                })}
            </svg>

            <div className="label-14 item div-item1" onClick={() => getShowData('qscore')}>
                Credit Qualifier Show
            </div>

            <div className="label-14 item div-item2" onClick={() => getShowData('recency')}>
                Recency Show
            </div>

            <div className="label-14 item div-item3" onClick={() => getShowData('frequency')}>
                Frequency Show
            </div>

            <div className="label-14 item div-item4" onClick={() => getShowData('intent')}>
                 Activity Show
            </div>

            <div className="label-14 item div-item5" onClick={() => getShowData('similarity')}>
                 Keyword Match Level Score
            </div>
        </StyledBullsEye>
    );
};

export default BullsEyeNew;