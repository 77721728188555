import styled from 'styled-components'
import {dimension} from 'APP/theme';
import StyledLogin from "../Login/styled";

const StyledForgot = styled(StyledLogin)`
    .ctn {
        width: 560px;
        height: 519px;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .ctn {
            width: 552px;
            height: 519px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        .ctn {
            width: 542px;
            height: 559px;
        }
    }
    
    .m-32 {
        margin-top: 32px;
    }
    
    .back {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`

export default StyledForgot;