import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {login} from "../services/selector";
import authService from "../api/authService";
import {set as setlogin} from "../services/reducer/login";

const useAuthenticate = () => {
    const {is_authenticated: isAuthenticated} = useSelector(login);
    const dispatch = useDispatch();

    useEffect(() => {
        (async function () {
            try {
                let rawRes = await authService.me();
                let resp = rawRes.data;
                if (resp && resp.data) {
                    dispatch(
                        setlogin({
                            first_name: resp.data.first_name,
                            is_authenticated: true,
                            img_url: resp.data.img_url,
                            organization: resp.data.organization
                        })
                    );
                }
            } catch {
                dispatch(setlogin({ is_authenticated: false }));
            }
        })();
    }, []);

    return isAuthenticated;
}

export default useAuthenticate;