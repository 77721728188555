import styled from "styled-components";

const StyledCreateAudience = styled.div`
  width: 100%;
  display: flex;
    flex-direction: column;
    gap: 24px;
`;

export default StyledCreateAudience;
