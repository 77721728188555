import React, { useState } from "react";
import StyledResetPassword from "./styled";
import {FULLSTORY_ORG_ID} from "utils/axios_helper";
import lockIcon from "assets/auth/lock.png";
import hideEyeLight from "assets/auth/hide_eye_light.png";
import FullStory from "react-fullstory";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import useResetPassword from "hooks/useResetPassword";

const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState({pass: false, confirmPass: false});
    const { password, errors, passwordStrength, loading, handlePasswordChange, handleConfirmPasswordChange, handleReset} = useResetPassword();

    return (
        <StyledResetPassword>
             <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="ctn">
                <div className="inner-ctn">
                    <div className="heading">Create New Password</div>
                    <div className="desc">Your new password must be different from previous password</div>
                    <div className="label m-top">New Password</div>
                    <div className="input-ctn">
                        <img src={lockIcon} alt={''}/>
                        <input className="pass-input"
                               type={showPassword.pass ? "text" : "password"}
                               placeholder="Enter password"
                               id="password"
                               name="password"
                               value={password.pass}
                               onChange={handlePasswordChange}
                        />
                        <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => {
                            return {...prev, pass: !prev.pass}
                        })}
                             className="hide-icon"/>
                    </div>
                    {
                        password.pass.length > 0 && (
                            <div className="strength-ctn">
                                <div className="progress-ctn">
                                    <div className={passwordStrength <= 2 ? "bar-weak": passwordStrength <= 4 ? "bar-medium": "bar-strong"}></div>
                                    <div className={passwordStrength <= 4 && passwordStrength > 2 ? "bar-medium": passwordStrength > 4 ? "bar-strong": ""}></div>
                                    <div className={passwordStrength >= 5 ? "bar-strong": ""}></div>
                                </div>
                                <div className="progress-text-ctn">
                                    <div className="desc">Password Strength:</div>
                                    {
                                        <div className={passwordStrength <= 2 ? "desc text-weak": passwordStrength <= 4 ? "desc text-medium": "desc text-strong"} >
                                            {
                                                passwordStrength <= 2 ? "Weak": passwordStrength <= 4 ? "Medium": "Strong"
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    {errors.pass && (
                        <div className="error">{errors.pass}</div>
                    )}

                    <div className="label m-top">Confirm New Password</div>
                    <div className="input-ctn">
                        <img src={lockIcon} alt={''}/>
                        <input className="pass-input"
                               type={showPassword.confirmPass ? "text" : "password"}
                               placeholder="Enter password"
                               id="password"
                               name="password"
                               value={password.confirmPass}
                               onChange={handleConfirmPasswordChange}
                        />
                        <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => {
                            return {...prev, confirmPass: !prev.confirmPass}
                        })}
                             className="hide-icon"/>
                    </div>
                    {errors.confirmPass && (
                        <div className="error">{errors.confirmPass}</div>
                    )}

                    {
                        errors.msg && (<div className="desc m-top">{errors.msg}</div>)
                    }

                    <div className="login-btn m-top" onClick={loading ? () => {}: handleReset}>
                        {loading ? "Loading..." : "Reset Password"}
                    </div>
                </div>
            </div>

            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledResetPassword>
    )
}

export default ResetPassword;