import React, { useEffect, useState } from "react";
import StyledAudience from "./styled";
import AudienceDetail from "./AudienceDetail";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID } from "../../../utils/axios_helper";
import CreateAudience from "./CreateAudience";
import {home} from "services/selector";

interface BodyInterface {
  heading: String[];
  setHeading: (val: String[]) => void;
}

const Audience: React.FunctionComponent<BodyInterface> = ({
  heading,
  setHeading,
}) => {
  const [showAudienceDetailsPage, setShowAudienceDetailsPage] = useState(false);
  const {country} = useSelector(home);

  useEffect(() => {
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase()]);
  }, []);
  return (
    <StyledAudience>
      {/* {
                showAudienceDetailsPage ? <AudienceDetail setShowAudienceDetailsPage={setShowAudienceDetailsPage} /> : <NewAudience setHeading={setHeading} />
            } */}
      <Routes>
        <Route
          path="*"
          element={
            <AudienceDetail
              setShowAudienceDetailsPage={setShowAudienceDetailsPage}
            />
          }
        />
        <Route
          path="create/*"
          element={<CreateAudience setHeading={setHeading} />}
        />
      </Routes>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudience>
  );
};

export default Audience;
