import React from "react";
import StyledReportTable from "./styled";

type ReportTableProps<T> = {
  title: string;
  columns: (keyof T)[];
  data: T[];
};

const ReportTable = <T extends Record<string, any>>({
  columns,
  data,
  title,
}: ReportTableProps<T>) => {
  const columnCount = columns.length;

  return (
    <StyledReportTable>
      <div className="ad-group-ctn">
        <div className="summary-text">{title}</div>
        <div className="table-container mt-40">
          <div
            className="t-header"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
            }}
          >
            {columns.map((col, index) => (
              <div className="th" key={index}>
                {String(col)}
              </div>
            ))}
          </div>
          <div>
            {data.map((row, rowIndex) => (
              <div
                className="tr"
                key={rowIndex}
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
                }}
              >
                {columns.map((col, colIndex) => (
                  <div key={colIndex}>{row[col] || "-"}</div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledReportTable>
  );
};

export default ReportTable;
