import React from 'react';
import { useNavigate } from 'react-router';
import StyledForgot from './styled';
import FullStory from 'react-fullstory';
import {FULLSTORY_ORG_ID} from "utils/axios_helper";
import leftArrow from "assets/auth/Arrow_left.png";
import messageIcon from "assets/auth/Message.png";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg";
import useForgetPassword from "hooks/useForgetPassword";

const ForgotForm: React.FC = () => {
  const navigate = useNavigate();

  const {email, errorMsg, loading, error , handleChange, handleSubmit} = useForgetPassword();
  return (
    <StyledForgot>
      <div className="header">
        <div className="header-inner-ctn">
          <div>
            <img className="header-logo" src={vatic_logo}></img>
          </div>
          <button className="header-signup-btn" onClick={()=>navigate("/auth/signup")}>Sign Up</button>
        </div>
      </div>
        <div className="ctn">
          <div className="inner-ctn">
            <div className="heading">Forgot Password?</div>
            <div className="desc">No worries, enter your email and we will send you reset instructions</div>
            <div className="label m-top">Email Address</div>
            <div className="input-ctn">
              <img src={messageIcon} alt={''}/>
              <input className="email-input"
                     type="email"
                     placeholder="Enter email address"
                     id="email"
                     name="email"
                     value={email}
                     onChange={handleChange}
              />
            </div>
            {errorMsg && (
                <div className="error">{errorMsg}</div>
            )}

            <div className="login-btn m-top" onClick={loading ? () => {}: handleSubmit}>
              {loading ? 'Sending...': "Reset Password"}
            </div>

            <div className="desc m-32">Came by mistake or remember your password?</div>

            <div className="back m-top">
               <img src={leftArrow} alt='' className="google-icon"/><span className="forget" onClick={() => navigate("/auth/login")}>Back to Login</span>
            </div>
          </div>
        </div>

        <div>
          <FullStory org={FULLSTORY_ORG_ID || ''}/>
        </div>
      </StyledForgot>
  );
};

export default ForgotForm;
