import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLoginState } from "interface/types";


let user_id_str = localStorage.getItem("user_id") || "-1";
let user_id = parseInt(user_id_str);

if (isNaN(user_id)) {
  user_id = -1;
}
let access_token = localStorage.getItem("access_token") || "";

const initialState: TLoginState = {
  user_id: user_id,
  first_name: "Jhon",
  access_token: access_token,
  is_authenticated: user_id != -1,
  img_url: "",
  organization: '',
  first_login: false
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<TLoginState>>) => {
      if (action.payload.access_token) {
        localStorage.setItem("access_token", action.payload.access_token);
      }
      if (action.payload.user_id) {
        localStorage.setItem("user_id", JSON.stringify(action.payload.user_id));
      }
      if ('first_login' in action.payload) {
        localStorage.setItem("first_login", JSON.stringify(action.payload.first_login));
      }
      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      state.user_id -= 1;
      state.first_name = "Jhon";
      state.access_token = "";
      state.is_authenticated = false;
      localStorage.setItem("user_id", JSON.stringify(-1));
      localStorage.setItem("access_token", JSON.stringify(""));
      state.organization = '';
      state.first_login = false;
    },
  },
});

export const { set, unset } = loginSlice.actions;
export default loginSlice.reducer;
