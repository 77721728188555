import React, { useEffect, useState } from "react";
import StyledDefine from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/estimate";
import { toast } from "react-toastify";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import {estimate as estimateSelector, home} from "services/selector";
import {TKeyword} from "interface/types";
import DropDown from "../../../../../shared/components/DropDown";
import Button from "../../../../../shared/components/Button";
import Footer from "../../../../../shared/components/Footer";
import Header from "shared/components/Header";
import {set as estimateSet, set as audienceSet} from "../../../../../services/reducer/audience";
import { set as homeSet } from "services/reducer/home";
import { useSearchParams } from 'react-router-dom';

interface Props {
  setHeading: (val: String[]) => void;
}

interface CountryList {
  name: string;
  short_name: string;
  db: string;
}

const Define: React.FunctionComponent<Props> = ({setHeading}) => {
  const estimate = useSelector(estimateSelector);
  const { countryDb, country } = useSelector(home);
  const [searchParams] = useSearchParams();
  const [currMethod, setCurrMethod] = useState<number>(1);

  interface LanguageObj {
    name: string;
    code: string;
    default_language: boolean;
  }
  const [languages, setLanguages] = useState<Array<LanguageObj>>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initValue, setInitialValue] = useState({
    method: currMethod,
    estimateName: estimate.estimateName,
    customKeywords: estimate.customKeywords,
    region: estimate.region,
    awareness: estimate.awareness,
    promoting: estimate.promoting,
    audience: estimate.audience,
    updating: false,
    language: estimate.language,
    urls: estimate.urls,
  });

  const initError = {
    promoting: "",
    audience: "",
    keyword: "",
    urls: "",
  };

  useEffect(() => {
    setHeading(["Create", country.charAt(0) + country.substring(1).toLowerCase(), "Define"]);
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/language-list");
      if (res && res.data) {
        setLanguages(res.data);
        if(!estimate.language) {
          const defaultLanguage = res.data?.filter((item:any) => item.default_language)[0]?.code || ''
          dispatch(set({language: defaultLanguage }))
        }
      } else {
        setLanguages([]);
      }
      const methodSelected = searchParams.get('method') || '1';
      dispatch(estimateSet({ method: parseInt(methodSelected) }));
      setCurrMethod(parseInt(methodSelected));
    };
    load();
  }, []);

  const [errors, setErrors] = useState(initError);

  const isChanged = () => {
    return (
      initValue.promoting !== estimate.promoting ||
      initValue.region !== estimate.region ||
      initValue.estimateName !== estimate.estimateName ||
      currMethod !== initValue.method ||
      estimate.customKeywords !== initValue.customKeywords ||
      estimate.language !== initValue.language ||
      estimate.urls !== initValue.urls
    );
  };

  const handleNext = async () => {
    const changed = isChanged();
    // ai generated flow
    if (currMethod == 1) {
      dispatch(set({ activeTab: 2 }));
      navigate("/dashboard/estimate/create/keyword");

      if (!changed) {
        dispatch(set({ updating: true }));
        return;
      } else {
        dispatch(set({ keywordsFetched: false, keywords: [] }));
        if (estimate.updating) {
          const res = await vaticAxiosPost("/estimate/update/keywords", {
            object_of_interest: estimate.promoting,
            region: country,
            language: estimate.language,
            estimate_name: estimate.estimateName,
            id: estimate.currEstimateId,
            db: countryDb,
          });
          if (res && res.statusCode == 200) {
            const temp = res.keywords
              .filter((i: TKeyword) => i.candi_keyword)
              .map((i: TKeyword, ind: number) => {
                return { ...i, selected: i.score >= 0.35 ? true : false, id: ind };
              });
            dispatch(
              set({
                currEstimateId: res.estimate_id,
                keywordsFetched: true,
                keywords: temp,
              })
            );
          } else if (res.statusCode == 500) {
            toast.error(
              "The selected language might be inappropriate for the region.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
          }
          return;
        }
        dispatch(
          set({
            updating: true,
            keywordsFetched: false,
            keywords: [],
          })
        );
        const res = await vaticAxiosPost("/estimate/generate/keywords", {
          object_of_interest: estimate.promoting,
          region: country,
          estimate_name: estimate.estimateName,
          db: countryDb,
          language: estimate.language,
        });
        if (res && res.statusCode == 200) {
          const temp = res.keywords
            .filter((i: TKeyword) => i.candi_keyword)
            .map((i: TKeyword, ind: number) => {
              return { ...i, selected: i.score >= 0.35 ? true : false, id: ind};
            });
          dispatch(
            set({
              currEstimateId: res.estimate_id,
              keywordsFetched: true,
              keywords: temp,
            })
          );
        } else if (res.statusCode == 500) {
          toast.error(
            "The selected language might be inappropriate for the region.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      }
    }
    // custom keywords flow
    else if (currMethod === 2) {
      if (!estimate.customKeywords) {
        setErrors((prev) => {
          return {
            ...initError,
            keyword: "Please enter some keywords to search.",
          };
        });
        return;
      }
      const res = await vaticAxiosPost("/estimate/custom/keywords", {
        keywords: estimate.customKeywords,
        region: country,
        estimate_name: estimate.estimateName,
        db: countryDb,
        language: estimate.language,
      });
      if (res) {
        dispatch(set({ currEstimateId: res.estimate_id }));
      }
      navigate("/dashboard/estimate");
      // handleClose(res.estimate_id);
    }
    // custom urls flow
    else if (currMethod === 3) {
      if (!estimate.urls) {
        setErrors((prev) => {
          return {
            ...initError,
            urls: "Please enter list of urls separated by line",
          };
        });
        return;
      }
      const res = await vaticAxiosPost("/estimate/custom_urls", {
        urls: estimate.urls,
        region: country,
        estimate_name: estimate.estimateName,
        db: countryDb,
        language: estimate.language
      });
      if (res) {
        dispatch(set({ currEstimateId: res.estimate_id }));
        handleClose(res.estimate_id);
      }
    }
  };

  const handleClose = async (estimateId: number) => {
    await vaticAxiosPost("/publish_real_time_audience", {
      aud_id: estimateId,
      db: countryDb,
      method: currMethod,
      keywords: estimate.customKeywords,
      custom_urls: estimate.urls,
    });
    dispatch(unset());
    navigate("/dashboard/estimate");
  };

  const [countries, setCountries] = useState<Array<{label: string, value: string}>>([]);
  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/country-list");
      if (res && res.data) {
        const temp = res.data.map((i: CountryList) => {return {label: i.name.charAt(0).toUpperCase() + i.name.slice(1).toLowerCase(), value: i.db.toLowerCase()}});
        setCountries(temp);
      } else {
        setCountries([]);
      }
    };
    load();
  }, []);

  const handleCountryChange = (val: string) => {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === val) {
        dispatch(
            homeSet({
              countryDb:val.toLowerCase(),
              country: countries[i].label,
            })
        );
        dispatch(
            audienceSet({
              t_region: countries[i].label.toLocaleLowerCase(),
            })
        );
        dispatch(
            estimateSet({
              t_region: countries[i].label.toLocaleLowerCase(),
            })
        );
      }
    }
  };

  return (
    <StyledDefine>
      <Header text={"Go back to Method"} action={() => {navigate("/dashboard/estimate/create/method");}}/>
      <div className="ctn">
        <div className="header">
          <div className="heading">
            Create Keywords
          </div>
          <div className="heading-desc">
            Let’s find your perfect audience! 🔍 <br/>
            Add the keywords that match your campaign goals, and we’ll help connect you with the right people at the right time.
          </div>
        </div>
        <div className="content-box">
          <div className="row">
            <div className="dropdown-ctn">
              <div className="label">Select country’s audience you want to target to?</div>
              <DropDown options={countries} action={handleCountryChange} defaultValue={countryDb.toLowerCase()}/>
            </div>

            <div className="dropdown-ctn">
              <div className="label">Select language for your keywords</div>
              <DropDown options={languages.map(item => { return {value: item.code, label: item.name.charAt(0).toUpperCase() + item.name.slice(1)} }) }
                        defaultValue={estimate.language || (languages?.filter(item => item.default_language)[0]?.code || '')}
                        action={(v) => dispatch(set({ language: v }))}
              />
            </div>
          </div>

          <div className="dropdown-ctn row1">
            <div>
              {currMethod === 1 ? "What product or service are you advertising?": currMethod === 2 ? "List out handpicked keywords": "List out handpicked URLs"}
            </div>
            <textarea className="text-area" placeholder="loans for entrepreneurs, major banks..."
                      value={
                         currMethod ===1 ? estimate.promoting:
                        currMethod === 2 ? estimate.customKeywords:
                        estimate.urls}
                      onChange={(e) =>
                      {
                        currMethod === 1 ? dispatch(set({ promoting: e.target.value })):
                            currMethod === 2?  dispatch(set({ customKeywords: e.target.value })):
                                dispatch(set({ urls: e.target.value }));
                      }}
            />
            {errors.promoting && <div className="label error">Please enter some keywords to search.</div>
            }          </div>

          <div>
            <Button text={currMethod === 1 ? "Search Keywords": "Save Estimate"} action={handleNext} />
          </div>
        </div>

      </div>
      <Footer />
    </StyledDefine>
  );
};

export default Define;
