const theme = {
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '120%',
    backgroundImage: 'radial-gradient(at bottom right, #29D0B1, #699CE5)'
}

export const dimension = {
    desktopLarge: {
        width: '1440px',
        height: '1080px',
    },
    desktopSmall: {
        width: '1280px',
        height: '720px',
    },
    tablet: {
        width: '720px',
        height: '1280px',
    },
    mobile: {
        width: '720px',
        height: '1280px',
    }
}

export const fontSize = {
    xSmall: '11px',
    small: '12px',
    base: '14px',
    large: '16px',
    h6: '18px',
    h5: '20px',
    h4: '22px',
    h3: '25px',
    h2: '28px',
    h1: '32px',
}

export const boxShadow = "0px 3px 10px 0px rgba(0, 0, 0, 0.25)"

export default theme;