import Landing from "components/Landing";
import Home from "components/Home";
import "./App.css";
import { Route, Routes } from "react-router";
import Auth from "components/Auth";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import { store } from "services/store";
import { Provider } from "react-redux";
import Authenticate from "./components/Authenticate";
import Privacy from "./components/Privacy";
import Verify from "./components/Verify";
import ReactGA from "react-ga4";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import Users from "components/Users";
import Organization from "components/Organization";
import { BrowserRouter } from "react-router-dom";


function App() {
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  useEffect(() => {
    const trackPageView = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    };
    trackPageView();
  }, [window.location.pathname]);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Landing />}></Route>
            <Route
              path="/dashboard/*"
              element={<Authenticate component={<Home />} />}
            />
            <Route path="/auth/*" element={<Auth />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/verify-email" element={<Verify />}></Route>
            <Route path="/admin/*" element={<Users />}></Route>
            <Route
              path="/organization/*"
              element={<Authenticate component={<Organization />} />}
            />
          </Routes>
        </Provider>
      <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
