import React, {useEffect, useState} from 'react';
import StyledAudiencePopup from "./styled";
import {vaticAxiosGet} from "../../utils/axios_helper";
import {TestimateData} from "../../interface/types";
import closeIcon from "assets/calibrate/Close_round.png";
import Button from "../../shared/components/Button";
import colors from "../../APP/colors";
import {IAudienceCalibrateType} from "../../interface/components";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {home} from "../../services/selector";

const PrivateAudiencePopup: React.FunctionComponent<IAudienceCalibrateType> = ({setAudienceType}) => {
    const navigate = useNavigate();
    const {country, countryDb} = useSelector(home);

    const [estimateData, setEstimateData] = useState<
        Array<TestimateData>
    >([]);

    useEffect(() => {
        (async function() {
            const res = await vaticAxiosGet("/api/v1/estimates/complete", {
                'db': countryDb
            });
            if (res && res.data) {
                setEstimateData(res.data);
            }
        })();
    }, []);

    return (
        <StyledAudiencePopup>
            <div className="heading-ctn">
                <div className="heading">Select Private Audience</div>
                <img src={closeIcon} alt="X" className="icon" onClick={() => setAudienceType(0)}/>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Creation Date</th>
                        <th>Country</th>
                        <th>Status</th>
                        <th>Predicted Size</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    estimateData.map((val, ind) => (
                        <tr key={ind}>
                            <td>{val.name}</td>
                            <td>{val.created_at}</td>
                            <td>{country.substring(0, 1).toUpperCase() + country.substring(1).toLowerCase()}</td>
                            <td> <span className="ready">Ready</span></td>
                            <td>{val.predicted_size.toLocaleString()}</td>
                            <td><Button text="Calibrate" action={() => {navigate('/dashboard/explore/calibrate', {
                                state: {id: val.id, isVatic: false, name: val.name}
                                })}} background={`${colors.green[500] || "#08814A"}`}/></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>

            <div className="ctn">
                {estimateData.map((val, ind) => (
                    <div key={ind} className="ctn-item">
                        <div className="col1">
                            <span className="ready">Ready</span>
                            <div>
                                <div className="label-1">Name</div>
                                <div className="label-2">{val.name}</div>
                            </div>
                            <div>
                                <div className="label-1">Creation Date</div>
                                <div className="label-2">{val.created_at}</div>
                            </div>
                        </div>

                        <div className="col1">
                            <div></div>
                            <div>
                                <div className="label-1">Country</div>
                                <div className="label-2">{val.db}</div>
                            </div>

                            <div>
                                <div className="label-1">Predicted Size</div>
                                <div className="label-2">{val.predicted_size.toLocaleString()}</div>
                            </div>
                        </div>

                        <div className="col2">
                            <Button text="Calibrate" action={() => {navigate('/dashboard/explore/calibrate', {
                                state: {id: val.id, isVatic: false, name: val.name}
                            })}} background={`${colors.green[500] || "#08814A"}`}/>
                        </div>
                    </div>
                ))}
            </div>
        </StyledAudiencePopup>
    );
}

export default PrivateAudiencePopup;