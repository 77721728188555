import styled from "styled-components";
import theme, {dimension, fontSize} from "../../../APP/theme";
import colors from "../../../APP/colors";

const StyledFooter = styled.div`
    width: 100%;
    display: flex;
    height: 64px;
    // padding: 23px 150px;
    justify-content: center;
    align-items: center;
    
    .ctn {
        display: flex;
        flex-direction: row;
        width: 1140px;
        justify-content: space-between;
        align-items: center;
    }

    .desc {
        color: ${colors.black[500]};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    @media (max-width: ${dimension.desktopSmall.width}) {
        padding: 23px 80px;
    }

    @media (max-width: ${dimension.mobile.width}) {
        .ctn {
            width: 672px;
        }

        .header {
            margin-top: 48px;
        }

        .heading {
            font-size: 40px;
        }

        .content-box {
            width: 672px;
            gap: 40px;
        }

        .row {
            display: flex;
            flex-direction: column;
            gap: 40px;
            justify-content: flex-start;
        }
    }

`;

export default StyledFooter;