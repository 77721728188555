import getService from "api/getService";
import {REACT_APP_BASE_URL ,REACT_APP_GOOGLE_SIGNUP_CLIENT_ID, REACT_APP_GOOGLE_SIGNUP_SCOPE} from "constants/environment";

export const getGoogleUserInfo = async (access_token: string) => {
    const url = `https://www.googleapis.com/oauth2/v3/userinfo`;
    const params = {
        access_token: access_token,
    };
    const data = {
        email: '',
        firstName: '',
        lastName: '',
        imgUrl: '',
        access_token: '',
    }
    try {
        const rawData = await getService.get(url, params);
        return {error: '', ...data, email: rawData.data.email, firstName: rawData.data.given_name, lastName: rawData.data.family_name, imgUrl: rawData.data.picture};
    } catch (error) {
        return {error: `${error}`, ...data};
    }
};

export const googleSite = (path: string) => {
    const redirectUrl = (REACT_APP_BASE_URL || "") + path ;
    const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${REACT_APP_GOOGLE_SIGNUP_SCOPE}&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=${redirectUrl}&client_id=${REACT_APP_GOOGLE_SIGNUP_CLIENT_ID}`;
    window.open(url, "_self");
};
