import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";

export const toastPopup = {
    success: (msg: string) => toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    }),
    error: (msg: string) => toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    })
}

export const getLocalStorageItem = (key: string, defaultValue: any) => {
    return JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));
}

export const setLocalStorageItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const removeLocalStorageItem = (key: string) => {
    localStorage.removeItem(key);
}
