import React from "react";

interface MultiSegmentProgressProp {
  data: { [key: string]: number };
}

const MultiSegmentProgressWithLabels: React.FunctionComponent<MultiSegmentProgressProp> = ({
  data,
}) => {
  const totalValue = Object.values(data).reduce((sum, val) => sum + val, 0);
  const radius = 60; // Radius of the circle
  const center = 75; // Center of the circle
  const circumference = 2 * Math.PI * radius; // Total circumference of the circle

  // Generate a unique color palette
  const dynamicColors = Object.keys(data).map(
    (_, index) => `hsl(${(index * 360) / Object.keys(data).length}, 70%, 50%)`
  );

  // Track cumulative percentage
  let cumulativeOffset = 0;

  return (
    <div style={{ position: "relative",  display:'flex',}}>
      {/* Chart Container */}
      <svg width="200" height="150" style={{ display: "block", margin: "0 auto" }}>
        {Object.entries(data).map(([key, value], index) => {
          const segmentLength = (value / totalValue) * circumference; // Length of this segment
          const gapLength = circumference - segmentLength; // Remaining part (not drawn)

          const strokeDasharray = `${segmentLength} ${gapLength}`;
          const strokeDashoffset = -cumulativeOffset;

          // Update cumulative offset for the next segment
          cumulativeOffset += segmentLength;

          return (
            <circle
              key={index}
              r={radius}
              cx={center}
              cy={center}
              fill="transparent"
              stroke={dynamicColors[index]}
              strokeWidth="10"
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              style={{
                transform: "rotate(-90deg)",
                transformOrigin: "75px 75px",
              }}
            />
          );
        })}
      </svg>

      {/* External Labels */}
      <div style={{ marginTop: "20px", textAlign: "left" }}>
        {Object.entries(data).map(([key, value], index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: dynamicColors[index],
                borderRadius: "50%",
                marginRight: "8px",
              }}
            ></div>
            <span style={{ fontSize: "12px" }}>
              {key}: {value} ({((value / totalValue) * 100).toFixed(1)}%)
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSegmentProgressWithLabels;
