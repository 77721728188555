import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { IReportChartProps } from "interface/components";

const ReportChart: React.FC<IReportChartProps> = ({
  labels,
  datasets,
  options,
  width = 400,
  height = 400,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      // Destroy the previous chart instance if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create the Line Chart instance
      chartInstance.current = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels,
          datasets: datasets.map((dataset) => ({
          ...dataset,
          pointStyle: 'rect', // Use squares for data points
          pointRadius: 6, // Size of the points
          pointHoverRadius: 8, // Hover size
          backgroundColor: dataset.borderColor, // Fill the square points with the same color as the line
          borderWidth: 2, // Border width around squares (optional)
        }))
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          ...options,
        },
      });
    }

    // Cleanup on unmount
    return () => {
      chartInstance.current?.destroy();
    };
  }, [labels, datasets, options]);

  return (
    <canvas ref={chartRef} style={{ width: `100%`, height: `${height}px` }} />
  );
};

export default ReportChart;
