import styled from "styled-components";
import CommonStyles from "../../../../../APP/common";
import {fontSize} from "../../../../../APP/theme";
import colors from "../../../../../APP/colors";

const StyledPlatform = styled(CommonStyles)`
  .mt-16 {
      margin: 16px 0;
  }
    
    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 24px 0;
    }
    
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 4fr 4fr;
        padding: 24px;
        align-items: center;
        border: 1px solid ${colors.black[100] || "#B7B7B7"};
        border-radius: 6px;
    }
    
    .checkbox {
        width: 24px;
        height: 24px;
    }
    
    .img {
        width: 64px;
        height: 64px;
    }
    
    .x {
        background: #000000;
        width: 60px;
        height: 60px;
        padding: 4px;
        border-radius: 11px;
    }
    
    .platform-name {
        font-size: ${fontSize.h6};
        font-weight: 700;
    }
    
    .d-flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .select {
        font-size: ${fontSize.large};
        padding: 10px;
        border-radius: 4px;
        width: 100%;
    }
    
    .btn-ctn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .error-msg {
        color: red;
        font-size: 14px;
        margin-bottom: 16px;
    }
`;

export default StyledPlatform;
