import React, { useEffect, useState, useRef } from "react";
import StyledKeyword from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/estimate";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import "react-circular-progressbar/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {estimate as estimateSelector, home} from "services/selector";
import Header from "../../../../../shared/components/Header";
import filterIcon from "assets/estimate/Filter.png";
import Button from "../../../../../shared/components/Button";
import colors from "../../../../../APP/colors";
import BarLoader from "react-spinners/BarLoader";

interface Props {
  setHeading: (val: String[]) => void;
}

const Keyword: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const estimate = useSelector(estimateSelector);
  const {countryDb, country} = useSelector(home);

  const [newKeywordName, setNewKeywordName] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [addedKeywordId, setAddedKeywordId] = useState<number>(-1);
  const [error, setError] = useState("");

  const addNewKeyword = async () => {
    setError("");
    if (newKeywordName) {
      const res = await vaticAxiosGet("/keyword_score", {
        source_keyword: estimate.promoting,
        candidate_keyword: newKeywordName,
        db: countryDb,
      });

      if (res && res.data) {
        const id = estimate.keywords.length;
        dispatch(
          set({
            keywords: [
              ...estimate.keywords,
              {
                candi_keyword: newKeywordName,
                score: res.data.score,
                selected: false,
                id: id
              },
            ],
          })
        );
        setNewKeywordName("");
        // scrollToBottom();
        setAddedKeywordId(id);
      }
    }
  };

  const handleSelectKeyword = (id: number) => {
    setError("");
    let temp = estimate.keywords.map(item => item.id === id ? {...item, selected: !item.selected} : {...item});
    dispatch(set({ keywords: temp }));
  };

  const selectAllKeyword = () => {
    setError("");
    let temp = {...estimate.keywords};
    if (keywordType === 1) {
      const n = estimate.keywords.reduce((acc, red) => red.selected ? acc + 1: acc, 0);
      if(n === estimate.keywords.length) {
        setAllSelected(false);
        temp = estimate.keywords.map((i) => {
            return { ...i, selected: false };
        });
      }
      else {
        setAllSelected(true);
        temp = estimate.keywords.map((i) => {
          return { ...i, selected: true };
        });
      }
    } else if (keywordType === 2) {
      const n = estimate.keywords.reduce((acc, red) => red.selected && red.score < 0.35 ? acc + 1: acc, 0);
      if(n === estimate.keywords.filter(item => item.score < 0.35).length) {
        setAllSelected(false);
        temp = estimate.keywords.map((i) => {
          return { ...i, selected: i.score < 0.35 ? false : i.selected };
        });
      }
      else {
        setAllSelected(true);
        temp = estimate.keywords.map((i) => {
          return { ...i, selected: i.score < 0.35 ? true : i.selected };
        });
      }
    } else {
      const n = estimate.keywords.reduce((acc, red) => red.selected && red.score >= 0.35 ? acc + 1: acc, 0);
      if(n === estimate.keywords.filter(item => item.score >= 0.35).length) {
        setAllSelected(false);
        temp = estimate.keywords.map((i) => {
          return { ...i, selected: i.score >= 0.35 ? false : i.selected };
        });
      }
      else {
        setAllSelected(true);
        temp = estimate.keywords.map((i) => {
          return { ...i, selected: i.score >= 0.35 ? true : i.selected };
        });
      }
    }
    dispatch(set({ keywords: temp }));
  };

  const divRef = useRef<HTMLTableSectionElement>(null);
  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  useEffect(() => {
    if (addedKeywordId != -1) {
      handleSelectKeyword(addedKeywordId);
      scrollToBottom();
    }
  }, [addedKeywordId]);

  const handleClose = () => {
    const keywords = estimate.keywords.map((i) => i.candi_keyword);
    const t = keywords.join(" && ");
    dispatch(unset());
    navigate("/dashboard/estimate");
  };

  const [showOption, setShowOption] = useState<boolean>(false);
  const [keywordType, setKeywordType] = useState<number>(1);
  const [sortType, setSortType] = useState<number>(0);

  useEffect(() => {
    setShowOption(false);
    let t = [...estimate.keywords];
    if(t.length == 0) {
      return;
    }
    if(sortType === 1) {
      t = t.sort((a, b) => a.score - b.score);
    } else if (sortType === 2) {
      t = t.sort((a, b) => b.score - a.score);
    } else if (sortType === 3) {
      t = t.sort((a, b) => a.candi_keyword.toLowerCase().localeCompare(b.candi_keyword.toLowerCase()));
    } else if (sortType === 4) {
      t = t.sort((a, b) => b.candi_keyword.toLowerCase().localeCompare(a.candi_keyword.toLowerCase()));
    }
    dispatch(set({ keywords: t }));
  }, [sortType]);

  return (
      <StyledKeyword>
        <Header text={"Go back to Create Keywords"} action={() => {
          navigate("/dashboard/estimate/create/define");
        }}/>
        <div className="ctn">
          <div className="header">
            <div className="heading">
              Create Keywords
            </div>
            {estimate.keywordsFetched ? (<div className="heading-desc">
              Your keywords are ready! 🎯<br/>
              Review the list below, add or delete any you like, and when you’re all set, hit ‘Save Estimate’ to lock it
              in!
            </div>): (
                <BarLoader
                    color={colors.primary[600]}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    width={"100%"}
                />
            )}
          </div>
        </div>

        <div className="content-box">
          <div className="ctn1">
            <div className="row1">
              <div className="keyword-length">{estimate.keywords.filter(item => item.selected).length} Keywords</div>
              <div className="sort-ctn">
                <div className="sort-text" onClick={() => setShowOption(prev => !prev)}>
                  <img src={filterIcon} alt=""/>
                  <div>Sort By</div>
                </div>
                {showOption && <div className="drop-ctn">
                  <div className="drop">
                    <div className="opt" onClick={() => setSortType(1)}>Score Low to High</div>
                    <div className="opt" onClick={() => setSortType(2)}>Score High to Low</div>
                    <div className="opt" onClick={() => setSortType(3)}>Alphabetical A - Z</div>
                    <div className="opt" onClick={() => setSortType(4)}>Alphabetical Z - A</div>
                  </div>
                </div>}
              </div>
            </div>

            <div className="row2">
              <div className="k-label">Add your keywords</div>
              <div className="k-input-ctn">
                <input className="k-input" type="text" placeholder="Please type here..." value={newKeywordName}
                       onChange={(e) => {
                         setNewKeywordName(e.target.value);
                       }}
                />
                <div className="k-btn">
                  <Button text={"Add Keyword"} action={addNewKeyword} border={`1px solid ${colors.primary[600]}`}
                          background={'${colors.white[50]}'}
                          color={colors.primary[600]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ctn2">
            <div className="keyword-nav">
              <div className={`keyword-nav-item ${keywordType == 1 ? "keyword-nav-item-active" : ""}`}
                   onClick={() => setKeywordType(1)}>All Keywords
              </div>
              <div className={`keyword-nav-item ${keywordType == 2? "keyword-nav-item-active": ""}`} onClick={() => setKeywordType(2)}>Less Related</div>
              <div className={`keyword-nav-item ${keywordType == 3? "keyword-nav-item-active": ""}`} onClick={() => setKeywordType(3)}>Highly Related</div>
            </div>
            <div className="keyword-table-ctn">
              <table>
                <thead>
                <tr>
                  <th className="col-checkbox"><input type="checkbox" className="k-checkbox" onClick={selectAllKeyword} checked={allSelected}/></th>
                  <th className="col-keywords">Keywords</th>
                  <th className="col-score">Score</th>
                </tr>
                </thead>
                <tbody ref={divRef}>
                {
                 keywordType === 1? estimate.keywords.map((item: any, ind) => (
                      <tr key={ind}>
                        <td className="col-checkbox"><input type="checkbox" className="k-checkbox" checked={item.selected} onClick={() => handleSelectKeyword(item.id)}/></td>
                        <td className="col-keywords">{item.candi_keyword}</td>
                        <td className="col-score">{item.score.toFixed(2)}</td>
                      </tr>
                  )): keywordType === 2 ? estimate.keywords.filter(item => item.score < 0.35).map((item: any, ind) => (
                     <tr key={ind}>
                       <td className="col-checkbox"><input type="checkbox" className="k-checkbox" checked={item.selected} onClick={() => handleSelectKeyword(item.id)}/></td>
                       <td className="col-keywords">{item.candi_keyword}</td>
                       <td className="col-score">{item.score.toFixed(2)}</td>
                     </tr>
                 )) : estimate.keywords.filter(item => item.score >= 0.35).map((item: any, ind) => (
                     <tr key={ind}>
                       <td className="col-checkbox"><input type="checkbox" className="k-checkbox" checked={item.selected} onClick={() => handleSelectKeyword(item.id)}/></td>
                       <td className="col-keywords">{item.candi_keyword}</td>
                       <td className="col-score">{item.score.toFixed(2)}</td>
                     </tr>
                 ))
                }
                </tbody>
              </table>
            </div>

            <div className="save-ctn">
              <Button text={"Save Estimate"} action={() => {
                if (estimate.keywords.filter((i) => i.selected).length == 0) {
                  setError("You must select atleast one keyword");
                  return;
                }
                handleClose();
              }}/>
            </div>
          </div>
        </div>
      </StyledKeyword>
  );
};

export default Keyword;
