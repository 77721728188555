import Button from "../../shared/components/Button";
import Modal from "react-modal";
import React from "react";
import './app.css';
import {useNavigate} from "react-router";


const ConnectPopup: React.FunctionComponent<{ currPath: string, isOpen: boolean, setIsOpen: any}> = ({currPath, isOpen, setIsOpen}) => {
    const navigate = useNavigate();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            className="modal-home"
            overlayClassName="modal-overlay-home"
            style={{
                content: {
                    top: `50%`,
                    left: `50%`,
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                },
            }}
        >
            <div className="modal-content-home">
                <div className="h1">Connect to your Ad Account?</div>
                <div className="desc">
                    Ready to supercharge your ads? 🚀<br/>
                    Connect your social media accounts to unlock AI magic and watch your campaigns work smarter, not harder!
                </div>
                <div className="btn-ctn">
                    <Button text="Let’s connect Social Media" action={() => navigate("/dashboard/platform")} />
                    <Button text="I will do this later" action={() => setIsOpen(false)}         
                            color={"#256CE8"}
                            border="1px solid #256CE8"
                            background= "#FFF"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConnectPopup;