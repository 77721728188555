import React, { useEffect, useState } from "react";
import StyledSummary from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/audience";
import { vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import ReactGA from "react-ga4";
import {audience, home } from "services/selector";
import Button from "../../../../../shared/components/Button";

interface Props {
  setHeading: (val: String[]) => void;
}

const Summary: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  const {
    aud_name: audienceName,
    awareness ,
    promoting: promotions,
    t_audience: targetAud,
    t_region: advertise_location,
    method,
    custom_keywords,
    curr_audience_id: audience_id,
    keywords,
    urls,
    campaigns,
    size: audienceSize,
} = useSelector(audience);
  const {countryDb, country} = useSelector(home);
  const [goal, setGoal] = useState("Awareness");

  useEffect(() => {
    setGoal(awareness ? "Awareness" : "conversion");
  }, [awareness]);

  const handleClose = async () => {
    const selected_campaigns = campaigns.filter(
      (item) => item.checked
    );

    const temp: any[] = selected_campaigns.map((item) => {
      return {
        campaign_id: item.id.toString(),
        platform: item.platforms[0],
        name: item.name,
        acc_id: item.acc_id.toString(),
      };
    });

    const res = await vaticAxiosPost("/api/v1/ads/populate/audience", {
      db: countryDb,
      audience_id: audience_id,
      campaigns: temp,
      audience_name: audienceName
    });

    if (res && res.statusCode == 200) {
      toast.success("Successfully Created Audience");
      dispatch(unset());
      navigate("/dashboard/audience");
    } else {
      toast.error(res.message);
    }

    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Audience Created",
    });
  };

  useEffect(() => {
    dispatch(set({ activeTab: 2 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Summary"]);
  }, []);

  return (
    <StyledSummary>
      <div className="heading">Summary</div>
      <div className="label-16">Please have a look at the summary of your campaigns</div>
      <div className="row width">
        <div className='row-item'>
          <div className="label-16 f-18 w-700">Audience Name</div>
          <div className="label-16 f-18">{audienceName}</div>
        </div>
        <div className="row-item">
          <div className="label-16 f-18 w-700">Audience Size</div>
          <div className="label-16 f-18">{audienceSize.toLocaleString()}</div>
        </div>
      </div>

      <div className="horizontal-line"/>

      <div className="campaign-ctn width">
        <div className="row">
            <div className="label-16 f-18 w-700">Campaign</div>
            <div className="label-16 f-18 w-700">Platform</div>
        </div>
        {campaigns.map(
            (campaign: any, ind: number) =>
                campaign.checked && (
                    <div className="row" key={ind}>
                      <div className="label-16 f-18">{campaign.name}</div>
                      <div className="label-16 f-18">{campaign.platforms}</div>
                    </div>
                )
        )}
      </div>

      <div className="btn-ctn">
        <Button text="Finish" action={handleClose} />
      </div>
    </StyledSummary>
  );
};

export default Summary;
