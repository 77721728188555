import React, {useEffect, useState} from 'react';
import StyledVerify from "./styled";
import queryString from "query-string";
import {axiosGet, REACT_APP_BACKEND_BASE_URL} from "../../utils/axios_helper";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router";


const Verify = () => {
    const navigate = useNavigate();

    const [verified, setVerified] = useState<number>(1);

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];
        const email = Array.isArray(parsedHash["email"])
            ? parsedHash["email"][0]
            : parsedHash["email"];
        if (token && email) {
            const verifyToken = async (token: string) => {
                // axiosGet('/api/auth/v1/verify-auth.ts', {token: token, auth.ts: auth.ts});
                const res = await axios.get(REACT_APP_BACKEND_BASE_URL + '/api/auth/v1/verify-email', {
                    params: {token: token, email: email}
                });
                if (res.data.data) {
                    toast.success('Email Successfully verified! Please login to your account', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                    });
                    navigate('/auth/login');
                } else {
                    toast.error(`Unable to verify email , Either this link has been expired or Invalid`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    })
                }
                setVerified(res.data.data ? 2: 3);
            }
            verifyToken(token);
        }
    }, []);

    return (
        <StyledVerify>
            {
                verified == 1 ? <div>We are verifying your email please wait</div>:
                    <div>
                        {verified == 2 ?(<div>Successfully Your email Verified Please Login</div>): (<div>Unable to verify email , Either this link has been expired or Invalid</div>)
                        }
                    </div>
            }
        </StyledVerify>
    )
}

export default Verify;