import styled from "styled-components";
import colors from "../../APP/colors";
import theme, {dimension, fontSize} from "../../APP/theme";

const StyledAudiencePopup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
    
    .heading-ctn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .heading {
        color: ${colors.primary[600] || "#256CE8"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h2};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }
    
    .icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    table {
        border-collapse: collapse;
    }
    
    tr {
        height: 44px;
    }
    
    thead > tr {
        background: ${colors.primary[50] || "#EAF1FF"};
    }
    
    th, td {
        text-align: left;
        padding: 10px 16px;
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    tbody > tr {
        border-top: 1px solid #F9F9F9;
    }
    
    th {
        font-weight: 700;
    }
    
    .ready {
        padding: 8px 20px;
        border-radius: 4px;
        color: ${colors.success[700] || "#065C35"};
        background: ${colors.success[100] || "#CBF2C4"};
        text-align: center;
    }
    
    .ctn {
        display: none;
        flex-direction: column;
        gap: 16px;
    }
    
    .ctn-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px;
        border-radius: 4px;
        background: ${colors.white[200] || "#FAFAFA"};
    }
    
    .col1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;
    }
    
    .label-1 {
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.small};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }

    .label-2 {
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .col2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ready-1 {
        padding: 8px;
        border-radius: 4px;
        color: ${colors.success[700] || "#065C35"};
        background: ${colors.success[100] || "#CBF2C4"};
        text-align: center;
        font-size: ${fontSize.large};
        font-weight: 400;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        
    }

    @media (max-width: ${dimension.mobile.width}){
        gap: 39px;
        table {
            display: none;
        }
        
        .ctn {
            display: flex;
        }
    }
`;

export default StyledAudiencePopup;
