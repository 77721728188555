import colors from "APP/colors";
import CommonStyles from "APP/common";
import styled from "styled-components";

const StyledReportTable = styled(CommonStyles)`
  .summary-text {
    color: ${colors.black[500]};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 33.6px */
  }
  .mt-20 {
    margin-top: 20px;
  }

  .mt-40 {
    margin-top: 40px;
  }
  .t-header {
    height: 64px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: rgba(68, 133, 244, 0.15);
    border-radius: 5px;
  }
`;

export default StyledReportTable;
