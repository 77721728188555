import axios from "axios";
import {REACT_APP_BACKEND_BASE_URL} from "../constants/environment";

const axiosInstance = axios.create({
    baseURL: REACT_APP_BACKEND_BASE_URL,
    // timeout: 1000,
    headers: {"Content-Type": "application/json"},
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Token expired or unauthorized, handle accordingly
            console.error('Unauthorized, logging out...');
            // Redirect to login page or refresh token logic here
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;