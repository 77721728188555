import React, {useEffect, useRef, useState} from "react";
import plusImg from "assets/campaign/plus.svg";
import StyledEstimateDetails from "./styled";
import emptyImg from "assets/campaign/empty.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { unset } from "services/reducer/estimate";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set as audienceSet } from "services/reducer/audience";
import ReactGA from "react-ga4";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import ProgressBar from "@ramonak/react-progress-bar";
import infoIcon from "assets/info alert.svg";
import { set as callibrateSet } from "services/reducer/callibrate";
import { home } from "services/selector";
import editIcon from "assets/estimate/Edit_fill.png";
import deleteIcon from "assets/estimate/delete.png";
import Button from "shared/components/Button";
import colors from "APP/colors";

interface estimateDataInterface {
  id: number;
  estimate_name: String;
  user_id: number;
  created_at: string;
  count: number;
  refresh_cal: number;
  refresh_explore: number;
  predicted_size: number;
  countryName: string;
  db: string;
}

interface EstimateDetailProps {
  setShowEstimateDetailsPage: (val: boolean) => void;
}

interface EstimateDetailInterface {
  estimate_id: number;
  estimate_name: string;
  user_id: number;
  creation_method: string;
  defined_audience: string;
  industry: string;
  devices: number;
  credit_qualification: string;
  audience_size: number;
  completed_processing: boolean;
  object_of_interest: string;
  target_audience: string;
  language: string;
  keyword: string;
  db: string;
}

interface CountryList {
  name: string;
  short_name: string;
  db: string;
}

const EstimateDetail: React.FunctionComponent<EstimateDetailProps> = ({
  setShowEstimateDetailsPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  const { countryDb, country } = useSelector(home);

  const [estimateData, setEstimateData] = useState<
    Array<estimateDataInterface>
  >([]);
  const [countIntervalId, setCountIntervalId] = useState<any>();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [estimateDetail, setEstimateDetail] =
    useState<EstimateDetailInterface>();
  const [progressPercent, setProgressPercent] = useState(0);
  const [refresh, setRefresh] = useState<Boolean>(false);

  const handleRefresh = () => { 
    setRefresh((prev) => !prev);
  }

  const handleCreateNew = () => {
    setShowEstimateDetailsPage(false);
    dispatch(unset());
    navigate("/dashboard/estimate/create");
  };

  const fetchEstimateInfo = async (estimateId: number, db: string) => {
    const res = await vaticAxiosGet("/api/v1/ads/estimate-detail", {
      estimate_id: estimateId,
    });
    if (res.statusCode === 200) {
      let data = res.data;
      data = { ...data, db: db };
      setEstimateDetail(data);
      setIsModelOpen(true);

      const check_estimate_audience_count_interval_method = async () => {
        const response = await vaticAxiosGet(
          "/api/v1/check_estimate_audience_count_pubsub/" + estimateId,
          {
            db: db,
          }
        );
        const resData = response.data;
        if (response.statusCode === 200) {
          const newAudienceSize = parseInt(resData.estimate_count);
          const completed_flag = resData.completed;
          if (
            newAudienceSize !== -1 &&
            newAudienceSize !== data.audience_size
          ) {
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setEstimateDetail(data);
          }
          if (newAudienceSize !== -1 && completed_flag) {
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setEstimateDetail(data);
            clearInterval(countInterval);
          }
        }

        const progressRes = await vaticAxiosGet(
          "/api/v1/audience-populate-progress",
          { audience_id: estimateId, db: db }
        );
        const res = progressRes.data;

        if (!res) {
          return;
        }

        const t2 = res.processed_url_count;
        const t4 = res.total_url_count;

        // not started yet
        if (t2 === -1 && t4 === -1) {
          setProgressPercent(0);
          return;
        }

        // getting total url rows in process
        if (t2 === 0 && t4 === 0) {
          setProgressPercent(15);
          return;
        }

        // total url count is fetched make it and all the other values now will come as 0 not -1
        if (t2 === 0 && t4 !== 0) {
          setProgressPercent(35);
          return;
        }

        // having total_url count as well as processed url count
        if (t2 !== 0 && t4 !== 0) {
          setProgressPercent(35 + (t2 / t4) * 65);
          return;
        }
      };

      check_estimate_audience_count_interval_method();
      const countInterval = setInterval(
        check_estimate_audience_count_interval_method,
        5000
      );
      setCountIntervalId(countInterval);
    }
  };

  let intervalIds: any[] = [];

  const loadStatus = () => {
    const temp = estimateData;
    temp.forEach((estimate, index) => {
      const tf = async () => {
        const response = await vaticAxiosGet(
          `/api/v1/check_estimate_audience_count_pubsub/${estimate.id}`,
          {
            db: estimate.db,
          }
        );

        if (response && response.statusCode === 200) {
          const resData = response.data;
          temp[index].count = resData.estimate_count;
          temp[index].refresh_cal = resData.refresh_cal;
          temp[index].refresh_explore = resData.refresh_explore;
          temp[index].predicted_size = resData.predicted_size;
          setEstimateData([...temp]);
        }
      };
      tf();
    });

    temp.forEach((estimate, index) => {
      const tmpInterval = setInterval(async () => {
        const response = await vaticAxiosGet(
          `/api/v1/check_estimate_audience_count_pubsub/${estimate.id}`,
          {
            db: estimate.db,
          }
        );

        if (response && response.statusCode === 200) {
          const resData = response.data;
          temp[index].count = resData.estimate_count;
          temp[index].refresh_cal = resData.refresh_cal;
          temp[index].refresh_explore = resData.refresh_explore;
          temp[index].predicted_size = resData.predicted_size;
          setEstimateData([...temp]);

          // Clear interval if the status is complete
          if (resData.completed) {
            clearInterval(tmpInterval);
          }
        }
      }, 5000);
      intervalIds.push(tmpInterval);
    });
  };

  const loadEstimates = async (db: string, countryName: string) => {
    const res = await vaticAxiosGet("/api/v1/ads/estimates", {
      country: db,
    });

    if (res && res.data) {
      const temp: estimateDataInterface[] = res.data.map((i: any) => {
        return {
          ...i,
          count: -1,
          status: false,
          predicted_size: -1,
          countryName: countryName,
          db: db,
        };
      });
      setEstimateData(temp);
    }
  };

  useEffect(() => {
    loadEstimates(countryDb, country);
    return () => {
      intervalIds.forEach((ids) => {
        try {
          clearInterval(ids);
        } catch {}
      });
    };
  }, [refresh]);

  useEffect(() => {
    intervalIds.forEach((ids) => {
      try {
        clearInterval(ids);
      } catch {}
    });
    intervalIds = [];
    loadStatus();

    return () => {
      intervalIds.forEach((ids) => {
        try {
          clearInterval(ids);
        } catch {}
      });
    };
  }, [estimateData.length]);

  const handleCreateAudience = async () => {
    if (!estimateDetail?.completed_processing) {
      toast.error("Processing Estimate size please wait", {
        autoClose: 1000,
      });
      return;
    }

    dispatch(
      audienceSet({
        aud_name: estimateDetail?.estimate_name,
        curr_audience_id: estimateDetail?.estimate_id,
        method: parseInt(estimateDetail.creation_method),
        size: estimateDetail.audience_size,
        promoting: estimateDetail.object_of_interest,
        language: estimateDetail.language,
        t_audience: estimateDetail.target_audience,
        urls: estimateDetail.keyword,
        custom_keywords: estimateDetail.keyword,
      })
    );
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Create Audience",
    });

    const res = await vaticAxiosPost("/api/v1/estimate/audience", {
      db: estimateDetail.db,
      estimate_id: estimateDetail?.estimate_id,
      audience_name: estimateDetail?.estimate_name,
      campaigns: [],
    });

    if (res && res.statusCode == 200) {
      toast.success("Successfully Created Audience");
      dispatch(unset());
      navigate("/dashboard/audience");
    } else {
      navigate("/dashboard/audience");
    }
  };

  const handleDiscardAudience = async (countryDb: string, estimateId: number) => {
    const res = await vaticAxiosGet("/discard_estimate", {
      country: countryDb,
      estimate_id: estimateId,
    });
    if (res && res.statusCode === 200) {
      toast.success("Successfully Discarded", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }

    handleRefresh();
  };

  const handleDiscardAudienceAndSaveKeywords = async () => {
    const res = await vaticAxiosGet("/discard_estimate_keep_keywords", {
      estimate_id: estimateDetail?.estimate_id,
      country: estimateDetail?.db,
    });
    if (res && res.statusCode === 200) {
      toast.success("Successfully Discarded and Saved Keywords", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }
  };

  const handleNotifyEstimate = async (estimateId: number, countryDb: string) => {
    const res = await vaticAxiosGet("/estimate/notify", {
      estimate_id: estimateId,
      country: countryDb,
    });
    if (res && res.statusCode === 200) {
      toast.success("You will be notified on your registered email", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }
  };

  const handleCallibrateClick = (item: any) => {
    dispatch(
      callibrateSet({
        estimate_id: item.id,
        is_vatic: false,
        qscore: 0,
        recency: 0,
        frequency: 0,
        similarity: 0,
        intent: 0,
        estimate_name: item.estimate_name,
      })
    );
    navigate("/dashboard/explore/calibrate", {
      state: {id: item.id, isVatic: false, name: item.estimate_name},
    });
  };

  return (
    <StyledEstimateDetails>
      <div className="header">
        <div className="heading-row">
          <div className="heading">Your Private Audience(s)</div>
          <div className="heading-desc">
            Here’s the list of all audience you’ve created. You can easily
            edit, delete, or calibrate them to fine-tune your campaigns.
          </div>
        </div>
        <Button text="Create New Estimate" action={handleCreateNew} color={`${colors.primary[500]}`} background={`${colors.white[50]}`} border={`solid 1px ${colors.primary[500]}`}></Button>
      </div>
      <div className="hor-line"></div>
      <div className="t-container">
        <div className="et-head">
          {/* <div className="t-head-font">Action</div> */}
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Creation date</div>
          <div className="t-head-font">Country</div>
          <div className="t-head-font">Status</div>
          <div className="t-head-font">Predicted Size</div>
          <div className="t-head-font">Action</div>
        </div>
        <div className="hor-line"></div>
        {estimateData.length > 0 && (
          <div className="t-body">
            {estimateData.map((val, ind) => (
              <div className={"et-row"} key={ind}>
                <div
                  className="t-data1"
                  onClick={() => fetchEstimateInfo(val.id, val.db)}
                >
                  {val.estimate_name}
                </div>
                <div className="t-data3">{val.created_at}</div>
                <div className="et-row-country">{ country }</div>
                <div className="progress-ctn">
                  {val.refresh_cal == 1
                    ? (<div className="calculating-ctn">Calculating...</div>)
                    : val.refresh_explore == 2
                    ? (<div className="ready-ctn">Ready</div>)
                    : (<div className="calculating-ctn">Callibrating...</div>)}
                </div>
                <div className="predicted-size">
                  {val.predicted_size ? (
                    val.predicted_size == -1 ? (
                      <div>
                        Loading...
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="none"
                            stroke="lightblue"
                            stroke-width="10"
                            stroke-linecap="round"
                            stroke-dasharray="188 62"
                          >
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 50 50"
                              to="360 50 50"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                          </circle>
                        </svg>
                      </div>
                    ) : (
                      val.predicted_size.toLocaleString()
                    )
                  ) : (
                    <div>
                      Loading...
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          fill="none"
                          stroke="lightblue"
                          stroke-width="10"
                          stroke-linecap="round"
                          stroke-dasharray="188 62"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 50 50"
                            to="360 50 50"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                        </circle>
                      </svg>
                    </div>
                  )}
                </div>
                <div className="et-row-action">
                  <div className="et-row-action-icons">
                    <div className="et-row-edit">
                      <img src={editIcon}></img>
                    </div>
                    <div className="et-row-delete" onClick={() => { handleDiscardAudience(val.db, val.id) }}>
                      <img src={deleteIcon}></img>
                    </div>
                  </div>
         
                    <Button
                      text={
                        val.refresh_explore == 2
                          ? "Callibrate"
                          : "Notify when done"
                      }
                      action={() => {
                        if (val.refresh_explore == 2) {
                          handleCallibrateClick(val);
                        } else { 
                          handleNotifyEstimate(val.id, val.db);
                        }
                      }}
                      background={
                        val.refresh_explore == 2
                          ? colors.green[500]
                          : colors.white[50]
                      }
                      color={
                        val.refresh_explore == 2
                          ? colors.white[50]
                          : colors.primary[600]
                      }
                      border={ 
                        val.refresh_explore == 2 ?
                          `solid 1px ${colors.green[500]}` :
                          `solid 1px ${colors.primary[600]}`
                      }
                      textAlign="center"
                    ></Button>
      
                </div>
              </div>
            ))}
          </div>
        )}

        {estimateData.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">
              No Estimate for{" "}
              {country.charAt(0) + country.slice(1).toLocaleLowerCase()} yet
            </div>
            <div className="empty-text">
              Create your first audience by clicking the button above.
            </div>
          </div>
        )}
      </div>
      {isModelOpen && (
        <div className="custom-modal-overlay">
          <div
            className="custom-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div className="loaded-summary-box">
                <div className="f-spbt">
                  <div>
                    <div className="t4">Estimate Name</div>
                    <div className="t5">
                      {estimateData
                        .filter(
                          (item) => item.id === estimateDetail?.estimate_id
                        )
                        .map((item) => item.estimate_name)}
                    </div>
                  </div>
                </div>
                <div className="hor-line"></div>
                <div className="f-spbt">
                  <div className="div-w">
                    <div className="t4">Estimate Size</div>
                    {!estimateDetail?.completed_processing ? (
                      <div className="t5">
                        <div>
                          In Progress, Current Size :{" "}
                          {estimateDetail?.audience_size == -1
                            ? "Loading"
                            : estimateDetail?.audience_size?.toLocaleString()}
                        </div>
                        <div className="progress-bar-ctn-detail">
                          <ProgressBar
                            height="15px"
                            bgColor="#007bff"
                            labelSize="12px"
                            className="progress-bar-detail"
                            completed={progressPercent.toFixed(0)}
                            maxCompleted={100}
                          />
                        </div>
                        <div
                          className="notify-msg-ctn"
                          style={{ fontSize: "12px" }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <div>
                              <img
                                style={{ width: "25px" }}
                                src={infoIcon}
                              ></img>
                            </div>
                            <div>Processing Estimate size. Please Wait</div>
                          </div>
                          <div className="notify-red-line"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="t5">
                        {estimateDetail.audience_size.toLocaleString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="create-audience-btn-ctn">
              <div
                className="close-button"
                onClick={() => {
                  setIsModelOpen(false);
                  clearInterval(countIntervalId);
                }}
              >
                Close
              </div>
              <div
                // onClick={() => {
                //   handleDiscardAudience();
                // }}
                className="create-btn-ctn"
              >
                Discard Audience
              </div>

              <div
                onClick={() => {
                  handleDiscardAudienceAndSaveKeywords();
                }}
                className="create-btn-ctn"
              >
                Discard Audience & <br />
                Save Keywords
              </div>
              <div
                onClick={() => {
                  handleCreateAudience();
                }}
                className="create-btn-ctn"
              >
                Create Audience
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledEstimateDetails>
  );
};

export default EstimateDetail;
