import styled from "styled-components";
import CommonStyles from "../../../APP/common";
import colors from "../../../APP/colors";

const StyledBullsEye = styled(CommonStyles)`
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .item {
        border-radius: 6px;
        padding: 8px;
        background:${colors.primary[50] || '#EAF1FF'};
        position: absolute;
        text-align: center;
        cursor: pointer;
    }

    .div-item1 {
        bottom: 80%; /* Position from the top */
        right: 5%; /* Position from the left */
        width: 76px;
    }

    .div-item2 {
        bottom: 30%; /* Position from the top */
        right: 0%; /* Position from the left */
        width: 53px;
    }

    .div-item3 {
        bottom: 0%; /* Position from the top */
        right: 40%; /* Position from the left */
        width: 65px;
    }

    .div-item4 {
        bottom: 30%; /* Position from the top */
        left: 0%; /* Position from the left */
        width: 52px;
    }

    .div-item5 {
        bottom: 80%; /* Position from the top */
        left: 10%; /* Position from the left */
        width: 80px;
    }
`;

export default StyledBullsEye;