import React from 'react';
import StyledVaticAudiencePopup from "./styled";
import { IAudienceCalibrateType } from "../../interface/components";
import SignalCategory from "../SignalCategory";
import { useNavigate } from "react-router";
import closeIcon from "assets/calibrate/Close_round.png";

const VaticAudiencePopup: React.FunctionComponent<IAudienceCalibrateType> = ({ setAudienceType }) => {
    const navigate = useNavigate();

    const handleSegmentSelect = (id: number, name: string) => {
        navigate('/dashboard/explore/calibrate', {
            state: { id: id, isVatic: true, name: name }
        });
    };

    return (
        <StyledVaticAudiencePopup>
            <div className="heading-ctn">
                <div className="heading">Select Vatic Audience</div>
                <img src={closeIcon} alt="X" className="icon" onClick={() => setAudienceType(0)} />
            </div>
            <div className="horizontal-line" />
            <SignalCategory parentId={0} handleSegmentSelect={handleSegmentSelect} level={1} />
        </StyledVaticAudiencePopup>
    );
};

export default VaticAudiencePopup;