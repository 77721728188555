import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { THomeState} from "interface/types";


const temp = localStorage.getItem("home") || "{}";
let obj = JSON.parse(temp);

const initialState: THomeState = {
  campaigns: obj.campaigns || [],
  campaignsClick: obj.campaignsClick || [],
  adSetsClick: obj.adSetsClick || [],
  countryDb: obj.countryDb || "algorithm",
  country: obj.country || "India",
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<THomeState>>) => {
      const t0 = JSON.parse(localStorage.getItem("home") || "{}");

      localStorage.setItem(
        "home",
        JSON.stringify({
          ...t0,
          ...action.payload,
        })
      );

      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      return { ...initialState };
    },
  },
});

export const { set, unset } = homeSlice.actions;
export default homeSlice.reducer;
