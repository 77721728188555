import React, {useEffect, useState} from "react";

import StyledBilling from "./styled";
import "react-toastify/dist/ReactToastify.css";
import useBilling from "../../hooks/useBilling";
import Button from "../../shared/components/Button";
import colors from "../../APP/colors";
import pricingIcon from "assets/billing/price-tag 1.png";
import startedIcon from "assets/billing/100-dollar-bill 1.png";
import scalabilityIcon from "assets/billing/maximize 1.png";
import {vaticAxiosGet} from "../../utils/axios_helper";
import {home} from "services/selector"
import {useSelector} from "react-redux";

const Billing: React.FunctionComponent<{setHeading: (val: string[]) => void}> = ({setHeading}) => {
    const {selectedPlan, setSelectedPlan, handleCheckout} = useBilling();
    const [btnType, setBtnType] = useState(1);
    const [paymentHistoryType, setPaymentHistoryType] = useState(1);
    const [maidsRemaining, setMaidsRemaining] = useState(0);
    const [paymentHistory, setPaymentHistory] = useState<Array<{
        'maids': number,
        'request_time': string,
        'status': string,
        'amount': string,
        'receipt_url': string,
    }>>([]);

    const [mediaExportHistory, setMediaExportHistory] = useState<Array<{
        'id': number,
        'maid_count': number,
        'status': string,
        'platform': string,
        'export_date': string,
    }>>([]);

    const {countryDb} = useSelector(home);

    const subscription = [
        {
            type: 'Trial Pack',
            description: 'Try out the platform and experience the basics.',
            maid: 'Free',
            price: '',
            btnText: 'Get Started for Free'
        },
        {
            type: 'Essentials Pack',
            description: 'Get started with essential audience reach.',
            maid: '1,000 - 500,000 MAIDs',
            price: '$250 per 100,000',
            btnText: 'Choose this pack'
        },
        {
            type: 'Growth Pack',
            description: 'Boost your reach with expanded targeting.',
            maid: '500,001 - 1,000,000 MAIDs',
            price: '$225 per 100,000',
            btnText: 'Choose this pack'
        },
        {
            type: 'Performance Pack',
            description: 'Achieve broader audience impact.',
            maid: '1,000,001 - 1,500,000 MAIDs',
            price: '$200 per 100,000',
            btnText: 'Choose this pack'
        },
        {
            type: 'Power Pack',
            description: 'Maximum reach for high-impact campaigns.',
            maid: '1,500,001 - 2,000,000 MAIDs',
            price: '$175 per 100,000',
            btnText: 'Choose this pack'
        },
        {
            type: 'Custom Solutions',
            description: 'Contact us for bespoke audience solutions',
            maid: 'Contact us for more details',
            price: '',
            btnText: 'Contact Us'
        },
    ];

    useEffect(() => {
        setHeading(['Billing']);

        (async function() {
            const res = await vaticAxiosGet('/api/v1/billing/maids-remaining');
            if (res && res.statusCode === 200) {
                setMaidsRemaining(res.data);
            }

            const res1 = await vaticAxiosGet('/api/v1/billing/payment-history');
            if (res1 && res1.statusCode === 200) {
                setPaymentHistory(res1.data);
            }

            const res2 = await vaticAxiosGet('/api/v1/media-export-maids', {db: countryDb});
            if (res2 && res2.statusCode === 200) {
                setMediaExportHistory(res2.data);
            }
        })();
    }, []);

    return (
        <StyledBilling>
            <div className="ctn">
                <div className="header">
                    <div className="header-row">
                        <div className="heading">Billing</div>
                    </div>

                    <div className="label-16">
                        Ready to access your audience? Complete payment below to export your tailored audience data!
                    </div>

                    <div className="btn-header">
                        <div className="maid-count-ctn mobile">
                            <span className="label-16">MAIDs Remaining</span>
                            <span className="label-16 maid-count">{maidsRemaining.toLocaleString()}</span>
                        </div>

                        <div className="btn-ctn">
                            <Button text={"Fixed Price Plan"} action={() => setBtnType(1)}
                                    color={btnType === 1 ? colors.white[50] : colors.primary[600]}
                                    background={btnType === 1 ? colors.primary[600] : colors.white[50]}
                                    padding={"16px 10px"}
                                    boxShadow={"none"}
                            />

                            <Button text={"Media Spend Based Plan"} action={() => setBtnType(2)}
                                    color={btnType === 2 ? colors.white[50] : colors.primary[600]}
                                    background={btnType === 2 ? colors.primary[600] : colors.white[50]}
                                    padding={"16px 10px"}
                                    boxShadow={"none"}
                            />

                            <Button text={"Billing History"} action={() => setBtnType(3)}
                                    color={btnType === 3 ? colors.white[50] : colors.primary[600]}
                                    background={btnType === 3 ? colors.primary[600] : colors.white[50]}
                                    padding={"16px 10px"}
                                    boxShadow={"none"}
                            />
                        </div>

                        <div className="maid-count-ctn desktop">
                            <span className="label-16">MAIDs Remaining</span>
                            <span className="label-16 maid-count">{maidsRemaining.toLocaleString()}</span>
                        </div>
                    </div>
                </div>

                {btnType === 1 && <div className="ctn-body">
                    {subscription.map((item: any, index: any) => (
                        <div className="item">
                            <div className="item-content">
                                <div className="item-h">{item.type}</div>
                                <div className="label-16">{item.description}</div>
                                <div className="label-16 s-22 size-22">
                                    <div>{item.maid}</div>
                                    <div>{item.price}</div>
                                </div>
                            </div>
                            <div>
                                <Button text={item.btnText} action={() => {
                                    if (index != 5) { handleCheckout(index) }
                                }} />
                            </div>
                        </div>
                    ))
                    }
                </div> }

                { btnType === 2 && <div className="ctn-body-media">
                    <div className="label-16 s-22">
                        Pay just 5% of your media spend on the audience you export.
                    </div>
                    <div className="horizontal-line"/>
                    <div className="media-h">How it works:</div>
                    <div className="media-step">
                        <div className="media-step-item">
                            <div className="step-btn">Step 1</div>
                            <div className="label-16 media-step-text">Connect your Ad Account</div>
                        </div>

                        <div className="media-step-item">
                            <div className="step-btn">Step 2</div>
                            <div className="label-16 media-step-text">Launch the Campaign with your VectorMath
                                Audience
                            </div>
                        </div>

                        <div className="media-step-item">
                            <div className="step-btn">Step 3</div>
                            <div className="label-16 media-step-text">At the end of the month, we will invoice you based
                                on 5% of the Media spend
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className="label-16 bg-y">Disclaimer: This plan covers Meta, TikTok, and X (Twitter). For Google Ads, please select a Fixed-based plan.</div>
                    </div>
                    <div className="horizontal-line"/>
                    <div className="media-h">Benefits:</div>

                    <div className="benefit-ctn">
                        <div className="benefit-item">
                            <img src={startedIcon} alt="" className="img-80"/>
                            <div className="benefit-text">
                                <div className="item-h w-700">Flexible Pricing</div>
                                <div className="label-16">Pay only for what you use</div>
                            </div>
                        </div>

                        <div className="benefit-item">
                            <img src={scalabilityIcon} alt="" className="img-80"/>
                            <div className="benefit-text">
                                <div className="item-h w-700">Get Started with $100</div>
                                <div className="label-16">5% is charged based on your media spend.</div>
                            </div>
                        </div>

                        <div className="benefit-item">
                            <img src={pricingIcon} alt="" className="img-80"/>
                            <div className="benefit-text">
                                <div className="item-h w-700">Scalability</div>
                                <div className="label-16">Easily adjust your spending as your campaigns grow.</div>
                            </div>
                        </div>
                    </div>
                </div> }

                {btnType === 3 && <div className="ctn-body-billing">
                    <div className="horizontal-line m-0"/>
                    <div className="radio-ctn">
                        <div className="radio-row">
                            <input type="radio" name="billing" checked = {paymentHistoryType === 1} onClick={() => setPaymentHistoryType(1)}/> <label className="label-16">Payment History</label>
                        </div>

                        <div className="radio-row">
                            <input type="radio" name="billing"  onClick={() => setPaymentHistoryType(2)} /> <label className="label-16">Media Export History</label>
                        </div>
                    </div>

                    {
                        paymentHistoryType === 1 ? (
                            <table>
                                <thead>
                                <tr>
                                    <th>MAIDs</th>
                                    <th>Billing Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    paymentHistory.map((item, index) => (
                                        <tr key={index}>
                                            <td className= "success-700">+{item.maids.toLocaleString()}</td>
                                            <td>{item.request_time}</td>
                                            <td><span className={item.status === "completed" ? "ready": "failed"}>
                                                {item.status === "completed" ? "Successful":
                                                    item.status.charAt(0).toUpperCase() + item.status.substring(1)}
                                                </span></td>
                                            <td>USD {item.amount}</td>
                                            <td>{item.receipt_url && <a href={item.receipt_url} target="_blank">Download Invoice</a>} </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        ): (
                            <table>
                                <thead>
                                <tr>
                                    <th>MAIDs</th>
                                    <th>Export Date</th>
                                    <th>Ads Account</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    mediaExportHistory.map((item, index) => (
                                        <tr key={index}>
                                            <td className="danger-700">+{item.maid_count.toLocaleString()}</td>
                                            <td>{item.export_date}</td>
                                            <td>USD {item.platform}</td>
                                            <td><span className={item.status === "completed" ? "ready" : "failed"}>
                                                {item.status === "completed" ? "Successful" :
                                                    item.status.charAt(0).toUpperCase() + item.status.substring(1)}
                                                </span></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        )
                    }

                    {/*Mobile Version*/}
                    {
                        paymentHistoryType === 1 ? (
                            <div className="table-mobile">
                                {
                                    paymentHistory.map((item, index) => (
                                        <div className="table-mobile-row">
                                            <div className="table-mobile-col-1">
                                                <span className={item.status === "completed" ? "ready p-8" : "failed p-8"}>
                                                        {item.status === "completed" ? "Successful" :
                                                            item.status.charAt(0).toUpperCase() + item.status.substring(1)}
                                                </span>
                                                <div className="table-mobile-item">
                                                    <span className="label-14 size-12">MAIDs</span>
                                                    <span className="label-14">+{item.maids.toLocaleString()}</span>
                                                </div>
                                                <div className="table-mobile-item-ctn">
                                                    <div className="table-mobile-item">
                                                        <span className="label-14 size-12">Billing Date</span>
                                                        <span className="label-14">{item.request_time}</span>
                                                    </div>
                                                    <div className="table-mobile-item">
                                                        <span className="label-14 size-12">Amount</span>
                                                        <span className="label-14">USD {item.amount}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                {item.receipt_url && <a href={item.receipt_url} target="_blank" className="label-14">Download Invoice</a> }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="table-mobile">
                                {
                                    mediaExportHistory.map((item, index) => (
                                        <div className="table-mobile-col-1">
                                            <span
                                                className={item.status === "completed" ? "ready p-8" : "failed p-8"}>
                                                    {item.status === "completed" ? "Successful" :
                                                        item.status.charAt(0).toUpperCase() + item.status.substring(1)}
                                            </span>
                                            <div className="table-mobile-item-ctn">
                                                <div className="table-mobile-item">
                                                    <span className="label-14 size-12">MAIDs</span>
                                                    <span className="label-14">{item.maid_count}</span>
                                                </div>
                                                <div className="table-mobile-item">
                                                    <span className="label-14 size-12">Export Date</span>
                                                    <span className="label-14">{item.export_date}</span>
                                                </div>
                                                <div className="table-mobile-item">
                                                    <span className="label-14 size-12">Ads Account</span>
                                                    <span className="label-14">{item.platform}</span>
                                                </div>
                                            </div>

                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>}
            </div>
        </StyledBilling>
    )
}

export default Billing;