import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StyledSummaryCard from "./styled";
import { TReportSummaryCardProps } from "interface/types";

const ReportSummaryCard: React.FunctionComponent<TReportSummaryCardProps> = ({
  title,
  type,
  value,
  percentageChange,
  progressPercent,
}) => {
  const renderValue = () => {
    switch (type) {
      case "percent":
        return `${value}%`;
      case "count":
        return value.toLocaleString();
      case "cost":
        return `$${value.toFixed(2)}`;
      default:
        return null;
    }
  };

  return (
    <StyledSummaryCard>
      <div className="summary-card">
        <div className="stat-ctn">
          <div className="label-16">{title}</div>
          <div className="value">{renderValue()}</div>
          <div className={`percentage-change ${percentageChange > 0?'green' : 'red'}`}>{`${percentageChange}%`}</div>
        </div>

        <div className="progress-ctn">
          {progressPercent && (
            <CircularProgressbar
              value={progressPercent}
              text={`${progressPercent}%`}
            />
          )}
        </div>
      </div>
    </StyledSummaryCard>
  );
};

export default ReportSummaryCard;
