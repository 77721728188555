import styled from "styled-components";

const StyledProjection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    gap: 20px;
    
    .label {
        padding: 10px;
        color: white;
        margin: 10px;
        border-radius: 8px;
    }
    
    .label-ctn {
        display: flex;
        flex-wrap: wrap;
    }
`;

export default StyledProjection;