import color from "APP/colors";
import styled from "styled-components";

const StyledPaymentFail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  .caution-img {
    height: 300px;
  }

  .failed-text {
    font-size: 40px;
  }

  .home-btn-ctn {
    display: flex;
    justify-content: center;
  }

  .home-btn {
    height: 40px;
    background-color: ${color.primary[500]};
    color: ${color.white[50]};
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .btn-ctn {
    display: flex;
    padding-top: 2rem;
  }
`;

export default StyledPaymentFail;
