import React from "react";
import StyledBody from "./styled";
import HomeReports from "../HomeReports";
import Audience from "../Audience";
import AudiencePlatforms from "../Platform";
import { Route, Routes } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID } from "../../../utils/axios_helper";
import Estimate from "../Estimate";
import HomeCards from "../HomeCards";
import Billing from "components/Billing";
import PaymentSuccess from "components/PaymentSuccess";
import PaymentFail from "components/PaymentFail";
import Calibrate from "../../Calibrate";
import Calibrating from "../../Calibrating";

interface BodyInterface {
  heading: String[];
  setHeading: (val: String[]) => void;
  activeTab: number;
}

const Body: React.FunctionComponent<BodyInterface> = ({
  heading,
  setHeading,
  activeTab,
}) => {
  const temp = [
    <HomeReports setHeading={setHeading} />,
    <Audience heading={heading} setHeading={setHeading} />,
    // <CampaignDetail setHeading={setHeading} />,
    <AudiencePlatforms setHeading={setHeading} />,
    <Estimate setHeading={setHeading} />,
    // <Explore setHeading={setHeading} heading={heading}></Explore>,
    <Calibrate setHeading={setHeading}/>,
    <HomeCards setHeading={setHeading} />,
    <Billing setHeading={setHeading} />,
    <Calibrating setHeading={setHeading}/>,
  ];

  return (
    <StyledBody>
      {/* {temp[activeTab - 1]} */}
      <Routes>
        <Route path="report/*" element={temp[0]} />
        <Route path="audience/*" element={temp[1]} />
        {/* <Route path="campaign" element={temp[2]} /> */}
        <Route path="platform" element={temp[2]} />
        <Route path="estimate/*" element={temp[3]}></Route>
        <Route path="explore" element={temp[4]} />
        <Route path="explore/calibrate" element={temp[7]} />
        <Route path="billing/*" element={temp[6]} />
        <Route path="billing/success" element={<PaymentSuccess setHeading={setHeading} /> } />
        <Route path="billing/fail" element={<PaymentFail setHeading={setHeading} /> } />
        <Route path="*" element={temp[5]} />
      </Routes>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledBody>
  );
};

export default Body;
