import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import authService from "api/authService";
import queryString from "query-string";
import {toastPopup} from "utils/util";
import {calculatePasswordStrength, validatePassword} from "utils/auth";

const useResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState<{pass: string, confirmPass: string, email: string, token: string}>({pass: '', confirmPass: '', email: '', token: ''});
    const [errors, setErrors] = useState<{pass: string, confirmPass: string, msg: string}>({pass: '', confirmPass: '', msg: ''});
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleReset = async () => {
        if (!validatePassword(password.pass)) {
            setErrors((prev) => { return {...prev, msg:
                    "Password must be at least 8 Characters and contain1 Number, 1 Uppercase, 1 Lowercase and 1 Symbol."}});
        } else if (password.pass != password.confirmPass) {
            setErrors((prev) => {return {...prev, confirmPass: "Both password must match", pass: 'Both password must match'}});
        } else {
            const rawRes = await authService.passwordReset({
                email: password.email,
                token: password.token,
                password: password.pass
            });
            const res = rawRes.data;
            if (res.statusCode === 200) {
                toastPopup.success(res.message);
                navigate("/auth/login");
            } else {
                toastPopup.error(res.message);
            }
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword((prev) => {
            return {...prev, pass: e.target.value}
        });
        setErrors({pass: '', confirmPass: '', msg: ''});
        setPasswordStrength(calculatePasswordStrength(e.target.value));
    }

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword((prev) => {
            return {...prev, confirmPass: e.target.value}
        });
        setErrors({pass: '', confirmPass: '', msg: ''});
    }

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];
        const email = Array.isArray(parsedHash["email"])
            ? parsedHash["email"][0]
            : parsedHash["email"];
        if (!token || !email) {
            toastPopup.success('Invalid Request');
        } else {
            (async function() {
                setLoading(true);
                try {
                    const rawRes = await authService.validPasswordRequest({
                        token: token,
                        email: email
                    });
                    const res = rawRes.data;
                    if (res.is_valid) {
                        setPassword((prev) => {return {...prev, email: email, token: token}});
                    } else if(res.is_valid === false) {
                        toastPopup.error(`Unable to Reset your password , Either this link has been expired or Invalid`);
                        navigate('/auth/login');
                    } else {
                        toastPopup.error(res.message)
                    }
                } catch (e) {
                    toastPopup.error(`${e}`);
                } finally {
                    setLoading(false);
                }

            })();
        }

    }, []);

    return { password, errors, passwordStrength, loading, handlePasswordChange, handleConfirmPasswordChange, handleReset}
}

export default useResetPassword;