import React from "react";
import StyledAudienceDetail from "./styled";
import emptyImg from "assets/campaign/empty.svg";
import {FULLSTORY_ORG_ID, REACT_APP_BACKEND_BASE_URL} from "utils/axios_helper";
import FullStory from "react-fullstory";
import BullsEye from "components/Chart/BullsEye";
import { IAudienceDetails } from "interface/components";
import useAudienceDetail from "hooks/useAudienceDetail";
import { useSelector } from "react-redux";
import { home } from "services/selector";
import Button from "shared/components/Button";
import editIcon from "assets/audience/Edit.png";
import deleteIcon from "assets/audience/Trash.png";
import axios from "axios";
import {toastPopup} from "../../../../utils/util";
import downloadIcon from "assets/audience/align-bottom-svgrepo-com.svg";
import uploadIcon from "assets/audience/align-top-svgrepo-com.svg";
import colors from "APP/colors";

const AudienceDetail: React.FunctionComponent<IAudienceDetails> = ({
  setShowAudienceDetailsPage,
}) => {
  const formatDate = (dateString: string): string => {
    const dateParts = dateString.split(".");
    const [year, month, day] = dateParts.map(Number);
    const date = new Date(year, month - 1, day); // Month is zero-based

    const daySuffix = (day: number): string => {
      if (day > 3 && day < 21) return "th"; // Handle 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${day}${daySuffix(day)} ${monthNames[month - 1]}, ${year}`;
  };

  const { country, countryDb } = useSelector(home);
  const {
    audienceData,
    progessText,
    progressPercent,
    platformIcon,
    platformAudienceStatus,
    handleAudienceClick,
    handleUploadAudience,
  } = useAudienceDetail({ setShowAudienceDetailsPage });

  const handleDownload = async (audience_id: number, audience_name: any) => {
    try {
      const jwtToken = localStorage.getItem("access_token") || "";
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
      };

      const response = await axios.get(
          `${REACT_APP_BACKEND_BASE_URL}/api/v1/audience/download-maids`,
          {
            params: {
              audience_id,
              db: countryDb,
            },
            headers: headers,
            responseType: "blob", // Ensure the response is treated as a binary blob
          }
      );

      // Create a blob URL for the response data
      const blob = new Blob([response.data], { type: "application/zip" });
      const blobUrl = window.URL.createObjectURL(blob);

      // Create and click an anchor link to trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${audience_name}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toastPopup.error("Error downloading the file:" + error);
    }
  };

  return (
    <StyledAudienceDetail>
      <div className="header">
        <div className="heading">Your Calibrated Audience</div>
        <div className="heading-desc">
          Here’s the list of all audiences you’ve created. You can easily edit,
          delete, or calibrate them to fine-tune your campaigns.
        </div>
      </div>
      <div className="t-container">
        <div className="hor-line"></div>
        <div className="t-head">
          {/* <div className="t-head-font">Action</div> */}
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Creation date</div>
          <div className="t-head-font">Country</div>
          <div className="t-head-font">Status</div>
          <div className="t-head-font">Platforms</div>
          <div className="t-head-font">Platform Status</div>
          <div className="t-head-font">Actions</div>
        </div>
        <div className="hor-line"></div>
        {audienceData.length > 0 && (
          <div className="t-body">
            {audienceData.map((val, ind) => (
              <div className={"t-row"} key={ind}>
                <div
                  className="t-data1"
                  onClick={() => {
                    handleAudienceClick(val.id);
                  }}
                >
                  <div>{val.audience_name} </div>
                  <BullsEye
                    calibratorsData={[
                      val.qscore,
                      val.recency,
                      val.frequency,
                      val.intent,
                      val.similarity,
                    ]}
                    height={60}
                    width={60}
                  />
                </div>
                <div className="label-16">{formatDate(val.created_at)}</div>
                <div className="t-data2">{country}</div>
                <div className="progress-ctn">
                  <div
                    className={
                      progressPercent[ind] === -1
                        ? "progress-text-error"
                        : "progress-text"
                    }
                  >
                    {`${parseInt(progessText[ind])}%`}
                  </div>
                  <div className="progress-bar-ctn">
                    <div className="progress-base"></div>
                    {progressPercent[ind] === -1 ? (
                      <div className="progress-error"></div>
                    ) : (
                      <div
                        className="progress"
                        style={{ width: `${progressPercent[ind]}%` }}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="t-data2">
                  {val.platforms.map((p, ind) => (
                    <img
                      src={platformIcon(p)}
                      key={ind}
                      className="platform-icon"
                    />
                  ))}
                </div>
                <div className="t-data2">
                  {platformAudienceStatus &&
                    (platformAudienceStatus as { [key: number]: any })[val.id]}
                </div>
                <div className="t-data4">
                  <div className="et-row-edit">
                    <img src={editIcon} alt="edit-icon"></img>
                  </div>
                  <div className="et-row-delete">
                    <img src={deleteIcon} alt="delete-icon"></img>
                  </div>
                  <div>
                    <Button background={val.zip_uploaded ? '' : colors.black[100]} text="Download" icon={downloadIcon} action={() => {
                      if (val.zip_uploaded)
                        handleDownload(val.id, val.audience_name)
                      else
                        toastPopup.error('Please wait calculating audiences')
                    }}></Button>
                  </div>
                  <div>
                    <Button background={val.audience_size ? '' : colors.black[100]} text="Upload" icon={uploadIcon} action={() => {
                        if (val.audience_size) {
                          handleUploadAudience(
                              val.id,
                              val.audience_name.toString(),
                              val.audience_size
                          );
                        } else {
                          toastPopup.error('Please wait calculating audiences')
                        }
                      }}></Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {audienceData.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">No Audience yet</div>
            <div className="empty-text">
              Create your first audience by clicking the button above.
            </div>
          </div>
        )}
      </div>

      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudienceDetail>
  );
};

export default AudienceDetail;
