import React, { useEffect } from "react";
import StyledPaymentSuccess from "./styled";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {home} from "services/selector";
import successImg from "assets/payment/successful_payment_388054.png";
import Button from "../../shared/components/Button";
import {useNavigate} from "react-router";

interface IPaymentSuccess {
    setHeading: (val: string[]) => void;
}

const PaymentSuccess: React.FunctionComponent<IPaymentSuccess> = ({setHeading}) => {
    const {country} = useSelector(home);
    const navigate = useNavigate();

    const updateInfo = async () => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
        ? parsedHash["token"][0]
        : parsedHash["token"];

        const sessionId = Array.isArray(parsedHash["session_id"])
        ? parsedHash["session_id"][0]
        : parsedHash["session_id"];

        if(!token || !sessionId) {
            return;
        }
        
        const response = await vaticAxiosPost('/api/v1/payment/status', {
            token: token,
            status: "completed",
            checkout_session_id: sessionId
        });

        if(response.statusCode === 200) {
            toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                });
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    }

    useEffect(() => {
        setHeading(["Billing", country.charAt(0) + country.substring(1).toLowerCase()]);
        updateInfo();
    }, []);
    return (
        <StyledPaymentSuccess>
            <img src={successImg} className="img" />
            <div className="heading">PAYMENT SUCCESSFUL</div>

            <div className="btn-ctn">
                <Button text={"Go to Home Page"} action={() => navigate("/dashboard/")} />
                <Button text={"Go to Billing Page"} action={() => navigate("/dashboard/billing")} />
            </div>
        </StyledPaymentSuccess>
    )
}

export default PaymentSuccess;