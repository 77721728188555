import styled from 'styled-components';
import colors from "./colors";
import theme, {dimension, fontSize} from "./theme";

const CommonStyles = styled.div`
    .heading {
        color: ${colors.primary[600] || "#256CE8"}  ;
        font-family: ${theme.fontFamily};
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: ${theme.lineHeight};
    }
    
    .label-16 {
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.large};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .label-14 {
        color: ${colors.black[500] || "#171717"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.base};
        font-style: normal;
        font-weight: 400;
        line-height: ${theme.lineHeight};
    }
    
    .icon-img {
        width: 24px;
        height: 24px;
    }

    @media (max-width: ${dimension.desktopSmall.width}){
        .heading {
            font-size: 40px;
        }
    }

    @media (max-width: ${dimension.mobile.width}){
        
    }
`;

export default CommonStyles;