import styled from "styled-components";
import CommonStyles from "../../../../../APP/common";

const StyledSummary = styled(CommonStyles)`
  display: flex;
  flex-direction: column;
  gap: 24px;
    position: relative;
    
    .width {
        width: 753px;
    }
    
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .row-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .f-18 {
        font-size: 18px;
    }

    .w-700 {
        font-weight: 700;
    }
    
    .horizontal-line {
        height: 1px;
        background: #F5F5F5;
    }
    
    .campaign-ctn {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    
    .btn-ctn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export default StyledSummary;
