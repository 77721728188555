import styled from "styled-components";
import CommonStyles from "../../APP/common";

const StyledPaymentSuccess = styled(CommonStyles)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    
    .img {
        width: 200px;
        height: 200px;
    }
    
    .btn-ctn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
    }
`;

export default StyledPaymentSuccess;