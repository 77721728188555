import styled from "styled-components";
import colors from "../../APP/colors";
import theme, {fontSize} from "../../APP/theme";

const StyledVaticAudiencePopup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;

    .heading-ctn {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .heading {
        color: ${colors.primary[600] || "#256CE8"};
        font-family: ${theme.fontFamily};
        font-size: ${fontSize.h2};
        font-style: normal;
        font-weight: 700;
        line-height: ${theme.lineHeight};
    }

    .icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    .horizontal-line {
        height: 1px;
        width: 100%;
        background: #F7F7F7;
    }
`;

export default StyledVaticAudiencePopup;