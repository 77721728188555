import styled from "styled-components";
import theme, { dimension } from "APP/theme";
import colors from "APP/colors";
import { boxShadow, fontSize } from "APP/theme";

const StyledLogin = styled.div`
  width: 100%;
  height: 100%;
  font-family: ${theme.fontFamily};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ctn {
    border-radius: 10px;
    background: ${colors.white[50]};
    box-shadow: ${boxShadow};

    display: flex;
    flex-direction: column;
    width: 560px;
    height: 640px;
    justify-content: center;
    align-items: center;

    margin: 36px 0;
  }

  .inner-ctn {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 80%;
    gap: 10px;
    justify-content: center;
  }

  .input-ctn {
    display: flex;
    height: 48px;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    border: 1px solid ${colors.black[700]};
    background: #fff;
  }

  .heading {
    color: ${colors.primary[500]};
    font-size: ${fontSize.h2};
    line-height: ${theme.lineHeight};
    font-weight: 700;
    text-align: center;
  }

  .desc {
    color: ${colors.black[700]};
    font-size: ${fontSize.base};
    line-height: ${theme.lineHeight};
    font-weight: 400;
    text-align: center;
  }

  .align-center {
    text-align: center;
  }

  .label {
    color: #000;
    font-size: ${fontSize.base};
    line-height: ${theme.lineHeight};
    font-weight: 400;
    text-align: left;
  }

  .email-input {
    overflow: hidden;
    color: ${colors.black[700]};
    text-overflow: ellipsis;

    font-family: ${theme.fontFamily};
    font-size: ${fontSize.large};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
    border: none;
    outline: none;
    width: 100%;
  }

  .error {
    color: ${colors.danger[600]};
    font-family: ${theme.fontFamily};
    font-size: ${fontSize.base};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
  }

  .pass-input {
    overflow: hidden;
    color: ${colors.black[600]};
    text-overflow: ellipsis;

    font-family: ${theme.fontFamily};
    font-size: ${fontSize.large};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
    border: none;
    outline: none;
    width: 100%;
  }

  .hide-icon {
    cursor: pointer;
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .checkbox-ctn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: ${colors.black[700]};
    font-weight: 400;
    font-size: ${fontSize.base};
  }

  .checkbox {
    width: 17px;
    height: 17px;
    background: ${colors.primary[600]};
    padding: 0;
    margin: 0;
  }

  .forget {
    color: ${colors.primary[500]};
    font-family: ${theme.fontFamily};
    font-size: ${fontSize.base};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
    text-decoration-line: underline;
    cursor: pointer;
  }

  .login-btn {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 6px;
    background: ${colors.primary[600]};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    font-family: ${theme.fontFamily};
    font-size: ${fontSize.large};
    font-weight: 400;
    line-height: ${theme.lineHeight};
    color: ${colors.white[50]};
  }

  .google-signin {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid ${colors.primary[600]};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    cursor:pointer;
  }

  .google-icon {
    width: 24px;
    height: 24px;
  }

  .google-login-text {
    color: ${colors.primary[600]};
    font-family: ${theme.fontFamily};
    font-size: ${fontSize.large};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
    cursor: pointer;
  }

  .signup-text {
    color: ${colors.black[700]};
    text-align: center;
    font-family: ${theme.fontFamily};
    font-size: ${fontSize.base};
    font-style: normal;
    font-weight: 400;
    line-height: ${theme.lineHeight};
  }

  .m-top {
    margin-top: 10px;
  }

  .header {
    display: flex;
    justify-content: center;
    top: 0px;
    height: 72px;
    width: 100%;
  }

  .header-inner-ctn {
    display: flex;
    width: 70%;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .header-logo {
    height: 30px;
  }

  .header-login-btn {
    background-color: ${colors.primary["600"]};
    color: ${colors.white["50"]};
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    padding: 8px 24px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
  }

  .header-signup-btn {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background-color: ${colors.white["50"]};
    border: 1px solid ${colors.primary["600"]};
    box-sizing: border-box;
    height: 40px;
    padding: 8px 24px;
    font-size: 16px;
    color: ${colors.primary["600"]};
    cursor:pointer;
  }

  @media (max-width: ${dimension.desktopSmall.width}) {
    .ctn {
      width: 552px;
      height: 609px;
    }

    .header-inner-ctn {
      width: 90%;
    }
  }

  @media (max-width: ${dimension.mobile.width}) {
    .ctn {
      width: 542px;
      height: 640px;
    }
  }
`;

export default StyledLogin;
